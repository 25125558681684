export const CONTACT_FORM_DATA = 'CONTACT_FORM_DATA';
export const DUPLICATE_CONTACT_FORM_DATA = 'DUPLICATE_CONTACT_FORM_DATA';
export const CONTACT_FORM_DATA_PAYEE = 'CONTACT_FORM_DATA_PAYEE';
export const CONTACT_DROP_DATA = 'CONTACT_DROP_DATA';
export const CONTACT_LIST_DATA = 'CONTACT_LIST_DATA';
export const BANK_ACCOUNTS_LIST = 'BANK_ACCOUNTS_LIST';
export const CONTRIBUTOR_TOTAL = 'CONTRIBUTOR_TOTAL';
export const SET_FILTERS_OPTIONS = 'SET_FILTERS_OPTIONS';
export const CLEAR_CONTACT_FILTER = 'CLEAR_CONTACT_FILTER';
export const EXPORT_CANTACT = 'EXPORT_CANTACT';
export const UNIMPORTED_CONTACT_LIST_DATA = 'UNIMPORTED_CONTACT_LIST_DATA';
export const UNIMPORTED_CONTACT_FORM_DATA = 'UNIMPORTED_CONTACT_FORM_DATA';
export const SET_FILTERS_OPTIONS_IMPORT = 'SET_FILTERS_OPTIONS_IMPORT';
export const CHECK_UNIMPORTED_CONTACTS = 'CHECK_UNIMPORTED_CONTACTS';
