import React, { useState } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Dashboard from '../../../../../pages/Dashboard';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} heading="New Contact" backLink="/contacts">
      <Card className="white-card w-100 pcard-0">
        <Row justify="center">
          <Col flex="0 0 462px">
            <Row className="text-center" gutter={[8, 8]}>
              <Col span={24}>
                <Title level={3}>Contact Type</Title>
              </Col>
              <Col span={24}>
                <Link className="receipt-card" to="/new-contact/individual">
                  <Card>
                    <Text>Individual</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-contact/other-organization">
                  <Card>
                    <Text>Other Organization</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-contact/political-committee">
                  <Card>
                    <Text>Political Committee</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-contact/financial-institution">
                  <Card>
                    <Text>Financial Institution</Text>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Dashboard>
  );
};
