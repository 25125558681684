import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';
import { downloadFile, saveFile } from '../../../../../services/crudApis';
import {
  DOWNLOAD_RECEIPT_CSV,
  DOWNLOAD_REPORT_CSV_BY_ID,
  DOWNLOAD_REPORT_PDF_BY_ID,
} from '../../../../../configs/endpoints';
import { message } from 'antd';

export const getReceiptListingData = (fromDate = 'null', toDate = 'null') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReceiptList/${fromDate}/${toDate}`);
    dispatch({
      type: action_types.RECEIPT_LIST_DATA,
      data: data,
    });
  };
};

export const getUnimportedReceiptList = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getUnimportedReceiptandExpenditureList`);
    dispatch({
      type: action_types.RECEIPT_UNIMPORTED_LIST_DATA,
      data: data,
    });
  };
};

export const get48HourReceiptListingData = (reportId) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/get48HourReceipts/${reportId}`);
    dispatch({
      type: action_types.RECEIPT_LIST_DATA,
      data: data,
    });
  };
};

export const getReceiptFormData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReceiptFormData/${type}/${id}`);
    dispatch({
      type: action_types.RECEIPT_FORM_DATA,
      data: data,
    });
  };
};

export const getUnimportedReceiptFormData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getUnimportedReceiptAndExpenditureFormData/${type}/${id}`);
    dispatch({
      type: action_types.UNIMPORT_RECEIPT_FORM_DATA,
      data: data,
    });
  };
};

export const getReceiptDropdownData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReceiptFormData/${type}/${id}`);
    dispatch({
      type: action_types.RECEIPT_DROP_DATA,
      data: data,
    });
  };
};

export const getExemptPlan = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExemptPlan`);
    dispatch({
      type: action_types.GET_EXEMPT_PLAN,
      data: data,
    });
  };
};

export const editExemptPlan = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/exempt-plans/${id}`);
    dispatch({
      type: action_types.EDIT_EXEMPT_PLAN,
      data: data,
    });
  };
};

export const setFiltersOptions = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filter: { [type]: value } },
    });
  };
};

export const clearReceiptFilters = () => ({
  type: action_types.CLEAR_RECEIPT_FILTER,
});

export const setFiltersImport = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_IMPORT,
      payload: { filterImport: { [type]: value } },
    });
  };
};
