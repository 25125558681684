import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Popover, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField, InputPassword } from '../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../pages/Login';
import { forgotPassword } from '../../../services/services';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const userEmail = values.accountEmail.toLowerCase();
    forgotPassword(userEmail)
      .then((response) => {
        //console.log(response.data);
        history.push('/instructions-sent');
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error);
        message.error(error?.response?.data?.error?.message);
      });
  };
  return (
    <Login load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            Password Reset
          </Title>
          <Paragraph className="text-center mt-1">Let us send you instructions to your account email</Paragraph>
        </Col>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <InputField
                  fieldname="accountEmail"
                  label="Account Email"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Please input email',
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'Check if the email is valid',
                    },
                  }}
                  validate={errors.accountEmail && 'error'}
                  validMessage={errors.accountEmail && errors.accountEmail.message}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" className="w-100" size="large">
                  Send instructions
                </Button>
              </Col>

              <Col span={24} className="text-center mb-0">
                <Link to="/" className="text-uppercase">
                  Back to login
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Login>
  );
};
