import React from 'react';
import Icon from '@ant-design/icons';

const Download = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.25C12.4142 3.25 12.75 3.58579 12.75 4V14.1893L16.4697 10.4697C16.7626 10.1768 17.2374 10.1768 17.5303 10.4697C17.8232 10.7626 17.8232 11.2374 17.5303 11.5303L12.8839 16.1768C12.3957 16.6649 11.6043 16.6649 11.1161 16.1768L6.46967 11.5303C6.17678 11.2374 6.17678 10.7626 6.46967 10.4697C6.76256 10.1768 7.23744 10.1768 7.53033 10.4697L11.25 14.1893V4C11.25 3.58579 11.5858 3.25 12 3.25ZM7 20.25C6.58579 20.25 6.25 20.5858 6.25 21C6.25 21.4142 6.58579 21.75 7 21.75H17C17.4142 21.75 17.75 21.4142 17.75 21C17.75 20.5858 17.4142 20.25 17 20.25H7Z"
      fill="#063284"
    />
  </svg>
);

const DownloadIcon = (props) => <Icon component={Download} {...props} />;
export default DownloadIcon;
