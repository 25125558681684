import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { InputField, InputCheckbox, SelectField, DateField, TextAreaField } from '../../../../../atoms/FormElement';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';
import { obligationBy } from '../../../Debts/ducks/services';
import Incured from '../Incured';
import { getReceiptDropdownData } from '../../../Receipts/ducks/actions';
import { getContactFormData } from '../../../Contacts/ducks/actions';
import ReportAmendment from '../../../../../molecules/ReportAmendment';
import { addObject } from '../../../../../../services/crudApis';

const { Paragraph } = Typography;

export default (props) => {
  const {
    setLoading,
    urlID,
    formData,
    setTotalIncurred,
    setTotalPaid,
    totalPaid,
    totalIncurred,
    setRemainingBalance,
    remainingBalance,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, watch, setValue, errors, reset, getValues, clearErrors } = useForm({
    defaultValues: {
      incurred_debts: [{ name: 'useFieldArray' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'incurred_debts',
  });

  const watchFieldArray = watch('incurred_debts');
  const watchBeginningBalance = watch('beginning_balance');
  const [deletedIndexesOblig, setDeletedIndexesOblig] = useState([]);
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  const [isOld, setIsOld] = useState(false);
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [reportVisible, setReportVisible] = useState(false);
  const [dateEffective, setdateEffective] = useState(null);
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  let contributerData = useSelector((state) => state.contact.contactFormData);

  useEffect(() => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  }, []);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const updateDropdownValues = () => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  };

  useEffect(() => {
    if (watchFieldArray && watchFieldArray?.length > 0) {
      const sum = watchFieldArray.reduce((accumulator, currentValue) => accumulator + +currentValue.amount, 0);
      setTotalIncurred(sum);
    }
  }, [watchFieldArray]);

  useEffect(() => {
    if (watchBeginningBalance) {
      const sum = watchFieldArray.reduce((accumulator, currentValue) => accumulator + +currentValue.amount, 0);
      const totalSum = sum + +watchBeginningBalance - (formData?.obligation?.amount_paid ?? 0);
      setRemainingBalance(totalSum);
    } else {
      const sum = watchFieldArray.reduce((accumulator, currentValue) => accumulator + +currentValue.amount, 0);
      const totalSum = sum - (formData?.obligation?.amount_paid ?? 0);
      setRemainingBalance(totalSum);
    }
  }, [watchBeginningBalance, watchFieldArray, formData?.obligation]);

  useEffect(() => {
    if (formData?.obligation) {
      setRemainingBalance(formData?.obligation?.remaining_balance);
    }
  }, [formData?.obligation]);

  const submitData = (val) => {
    let incArray = [];
    let incurredArray = [];
    let pc = '';
    val?.incurred_debts?.map((x) => {
      if (x?.purpose_code) {
        pc = '';
        for (const code of x.purpose_code) {
          pc += code.value;
        }
      }
      incArray = incurredArray.push({
        date: x?.date ? moment(x?.date).format('MM-DD-YYYY') : '',
        amount: x?.amount,
        description: x?.description,
        contact_id: x?.contact_id?.value ?? val?.debtor?.value,
        id: x?.incurred_id,
        purpose_code: pc,
        required_remarks: x?.required_remarks,
      });
    });

    const payload = {
      deleted_obligations: deletedIndexesOblig,
      deleted_incurreds: deletedIndexes,
      obligation: {
        id: urlID ?? undefined,
        contact_id: val?.debtor?.value,
        description: val?.comments,
        beginning_balance: isOld ? +val?.beginning_balance : 0,
        start_date: val?.start_date ? moment(val?.start_date).format('MM-DD-YYYY') : '',
        amount_paid: totalPaid ?? 0,
        amount_incurred: totalIncurred,
        remaining_balance: remainingBalance,
        incurred: incurredArray,
        type: 'TO',
      },
    };

    if (remainingBalance < 0) {
      setLoading(false);
      message.error("Debt remaining balance can't be negative.");
    } else {
      obligationBy(payload)
        .then(() => {
          message.success('Debt added.');
          setLoading(false);
          history.push('/debts/owed-to-the-comittee');
        })
        .catch(() => {
          setLoading(false);
          message.error('Something went wrong');
        });
    }
  };

  const onFinish = (val) => {
    setLoading(true);

    if (formData && formData?.obligation?.start_date) {
      setdateEffective(moment(formData?.obligation?.start_date).format('MM-DD-YYYY'));
      const amendmentPayload = {
        dateEffective: moment(formData?.obligation?.start_date).format('YYYY-MM-DD'),
        reportIds: false,
      };

      addObject('getAmendedReports', amendmentPayload)
        .then((response) => {
          if (response?.data?.length > 0 && !val?.effectiveDate) {
            if (
              (val?.debtor && val?.debtor?.value !== formData?.obligation?.contact_id) ||
              (val?.start_date &&
                moment(formData.obligation?.start_date, 'YYYY-MM-DD') &&
                moment(val?.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY') !==
                  moment(formData.obligation?.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY')) ||
              (val?.beginning_balance && val?.beginning_balance !== formData?.obligation?.beginning_balance) ||
              (val?.comments && val?.comments !== formData?.obligation?.description)
            ) {
              setReportVisible(true);
              setLoading(false);
              return;
            }

            const arr1 = getValues()?.incurred_debts;
            const arr2 = formData?.obligation?.incurreds;
            if (arr1.length !== arr2.length) {
              setReportVisible(true);
              setLoading(false);
              return true;
            }

            // Iterate through the keys
            for (let i = 0; i < arr1.length; i++) {
              if (arr1[i]?.purpose_code?.length > 0) {
                var purposeUnique = arr1[i]?.purpose_code.filter(function (obj) {
                  return !arr2[i]?.purpose_code.some(function (obj2) {
                    return obj.value == obj2;
                  });
                });
              }

              if (
                (arr1[i]?.amount && arr1[i]?.amount !== arr2[i]?.amount) ||
                (arr1[i]?.date && arr1[i]?.date !== arr2[i]?.date) ||
                (arr1[i]?.contact_id?.value && arr1[i]?.contact_id?.value !== arr2[i]?.contact_id) ||
                (arr1[i]?.required_remarks && arr1[i]?.required_remarks !== arr2[i]?.required_remarks) ||
                (arr1[i]?.purpose_code && purposeUnique?.length > 0)
              ) {
                setReportVisible(true);
                setLoading(false);
                return true;
              }
            }

            setReportVisible(false);
            setLoading(false);
            submitData(val);
            return false;
          } else {
            submitData(val);
          }
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    } else {
      submitData(val);
    }
  };

  useEffect(() => {
    if (formData?.obligation && Object.keys(receiptFormData)?.length > 0) {
      const selectedcontributorList = receiptFormData?.contributors?.find(
        (e) => e?.value == (contactToSelect ? contactToSelect : formData?.obligation?.contact_id),
      );

      setTimeout(() => {
        setValue('debtor', selectedcontributorList);
      }, 200);
      setTimeout(() => {
        setContactBoxEditCase(selectedcontributorList);
      }, 1200);
    }

    if (formData?.obligation?.beginning_balance) {
      setIsOld(true);
      setValue('old_debt', true);
      setTimeout(() => {
        setValue('start_date', formData?.obligation?.start_date ? moment(formData?.obligation?.start_date) : null);
        setValue('beginning_balance', formData?.obligation?.beginning_balance);
        setValue('comments', formData?.obligation?.description);
      }, 1000);
    } else {
      setIsOld(false);
      setValue('old_debt', false);
      setTimeout(() => {
        setValue('start_date', null);
        setValue('beginning_balance', 0);
        setValue('comments', '');
      }, 1000);
    }

    if (formData?.obligation) {
      setTotalPaid(formData?.obligation?.amount_paid);
    }

    if (formData?.obligation?.incurreds && formData?.obligation?.incurreds?.length > 0) {
      setValue('incurred_debts', formData?.obligation?.incurreds);
    }
  }, [formData, receiptFormData]);

  useEffect(() => {
    if (!isOld) {
      clearErrors('start_date');
      clearErrors('beginning_balance');
    }
  }, [isOld]);

  const submitFileExternal = () => {
    setReportVisible(false);
    const value = getValues();
    value.effectiveDate = 'no Data';
    onFinish(value);
  };

  return (
    <>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[48, 20]}>
          <Col span={12}>
            <h3 className="debt_h3">Details</h3>
            <Row gutter={[30, 20]}>
              <ContactBox
                control={control}
                errors={errors}
                selectData={receiptFormData?.contributors}
                changeValueByDropDown={changeValueByDropDown}
                updateDropdownValues={updateDropdownValues}
                contributerData={contributerData}
                contactBoxEditCase={contactBoxEditCase}
                contactToSelect={contactToSelect}
                setContactToSelect={setContactToSelect}
                setValue={setValue}
                fieldname="debtor"
                label="Debtor"
                placeholder="Select Debtor"
              />

              <Col span={24}>
                <InputCheckbox
                  fieldname="old_debt"
                  label="Old debt incurred pre-electronic reporting"
                  hintMessage="Put a check here if the debt was incurred before the committee started filing electronic reports."
                  class="checkbox_hint"
                  control={control}
                  onChange={(e) => setIsOld(e)}
                />
              </Col>

              {isOld && (
                <>
                  <Col span={24}>
                    <DateField
                      fieldname="start_date"
                      label="Start Date"
                      control={control}
                      class="mb-0"
                      iProps={{ size: 'large' }}
                      initValue=""
                      isRequired={isOld ? true : false}
                      rules={{
                        required: isOld ? 'Start Date is required.' : null,
                      }}
                      validate={errors.start_date && isOld && 'error'}
                      validMessage={errors.start_date && isOld && errors.start_date.message}
                    />
                  </Col>

                  <Col span={24}>
                    <InputField
                      fieldname="beginning_balance"
                      label="Beginning Balance"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        prefix: '$',
                        type: 'number',
                      }}
                      isRequired={isOld ? true : false}
                      rules={{
                        required: isOld ? 'Beginning Balance is required.' : null,
                      }}
                      validate={errors.beginning_balance && isOld && 'error'}
                      validMessage={errors.beginning_balance && isOld && errors.beginning_balance.message}
                    />
                  </Col>

                  <Col span={24}>
                    <TextAreaField
                      fieldname="comments"
                      label="Description"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col span={12}>
            <h3 className="debt_h3">Incurred Items</h3>
            <Row gutter={[30, 20]}>
              <Incured
                formData={formData}
                fields={fields}
                watch={watch}
                {...{ control }}
                errors={errors}
                setValue={setValue}
                append={append}
                remove={remove}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
                updateDropdownValues={updateDropdownValues}
                isOld={isOld}
                payeeOption={receiptFormData?.contributors}
              />
            </Row>
          </Col>
        </Row>
        <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
          <Col>
            <Button
              type="primary"
              onClick={() => history.push('/debts/owed-to-the-comittee')}
              htmlType="button"
              className="font-gilroy white-btn"
              size="large"
            >
              Cancel
            </Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={dateEffective}
        receiptId={false}
        type="debts"
      />
    </>
  );
};
