import axiosInterceptor from './axiosInterceptor';
import { baseUrl } from '../configs/constants';

export const addExpenditure = (data) => {
  return axiosInterceptor.post(baseUrl + '/expenditures', data);
};

export const editExpenditure = (data, id) => {
  return axiosInterceptor.patch(baseUrl + `/expenditures/${id}`, data);
};

export const updateUnimportedExpenditure = (data, type, id) => {
  return axiosInterceptor.patch(baseUrl + '/updateUnimportedReceiptAndExpenditure' + '/' + type + '/' + id, data);
};

export const deleteExpenditure = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteExpenditures`, ids);
};

export const deleteExemptPlan = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteExemptPlan`, ids);
};

export const deleteUnimportedExpenditure = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteUnimportedReceiptAndExpenditure`, ids);
};
