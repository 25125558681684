import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Form, message } from 'antd';
import { useForm } from 'react-hook-form';
import Dashboard from '../../../../pages/Dashboard';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { DateField, CustomCheckboxGroup } from '../../../../atoms/FormElement';
import quarter1 from '../../../../../assets/svg/quarter-1.svg';
import quarter2 from '../../../../../assets/svg/quarter-2.svg';
import quarter3 from '../../../../../assets/svg/quarter-3.svg';
import quarter4 from '../../../../../assets/svg/quarter-4.svg';
import organizational from '../../../../../assets/svg/organizational-report.svg';
import yearend from '../../../../../assets/svg/year-end.svg';
import midyear from '../../../../../assets/svg/mid-year.svg';
import { addObject } from '../../../../../services/crudApis';
import { FILED_REPORTS } from '../../../../../configs/endpoints';
import { getAvailableReportsData } from '../../ducks/actions';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [orgDate, setOrgDate] = useState(false);
  const [reportYear, setReportYear] = useState([]);
  const { control, handleSubmit, errors } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const availableReports = useSelector((state) => state.dashboard.availableReportsData);

  useEffect(() => {
    dispatch(getAvailableReportsData());
  }, []);

  const onFinish = (values) => {
    //console.log('values', values);
    setLoading(true);

    let payload = [];
    values?.reportName &&
      values?.reportName?.length > 0 &&
      values?.reportName?.map((e) => {
        const reportName = e.split('_')[0];
        const reportYear = e.split('_')[1];
        const reportSelectedYear = availableReports?.find((x) => x?.value == reportName && x?.year == reportYear);
        payload.push({
          reportName: reportName,
          date: reportName?.includes('ORG')
            ? moment(values?.organizationalEndDate).format('YYYY-MM-DD HH:mm:ss') + 'Z'
            : false,
          year: parseInt(reportSelectedYear?.year),
        });
      });

    addObject(FILED_REPORTS, payload)
      .then((response) => {
        //console.log('response', response);
        setLoading(false);
        message.success('Information Updated.');
        history.push('/dashboard');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    // setTimeout(() => {
    //   history.push('/dashboard');
    // }, 1000);
  };

  const checkboxImages = [
    { image: yearend, value: 'YESA' },
    { image: organizational, value: 'ORG' },
    { image: midyear, value: 'MYSA' },
    { image: quarter1, value: 'QTR1' },
    { image: quarter2, value: 'QTR2' },
    { image: quarter3, value: 'QTR3' },
    { image: quarter4, value: 'QTR4' },
  ];

  let checkboxReport = [];
  availableReports &&
    availableReports?.length > 0 &&
    availableReports?.map((e) => {
      checkboxReport.push({
        label: e?.label,
        value: e?.value + '_' + e?.year,
        image: checkboxImages?.find((resp) => e?.value == resp?.value),
        year: e?.year,
      });
    });

  const onChange = (e) => {
    const array = [];
    e?.map((resp) => {
      array.push(resp.split('_')[0]);
    });
    if (array?.includes('ORG')) {
      setOrgDate(true);
    } else {
      setOrgDate(false);
    }
  };

  return (
    <Dashboard load={loading} noLink={true}>
      <Row align="middle" justify="center">
        <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
          <Card className="white-card text-center">
            <Row justify="center" gutter={[20, 25]}>
              <Col span={24}>
                <Title level={2}>Specify Reports You Have Already Filed</Title>
                <Text>We'll remember this and won't bother you with the reminders.</Text>
              </Col>
              <Col span={24}>
                <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
                  <Row gutter={[20, 30]} justify="center">
                    <Col span={24}>
                      <CustomCheckboxGroup
                        fieldname="reportName"
                        label=""
                        class="fullWidth-checbox"
                        control={control}
                        initValue=""
                        option={checkboxReport && checkboxReport}
                        onChange={(e) => onChange(e)}
                      />
                    </Col>

                    {orgDate && (
                      <Col span={24}>
                        <DateField
                          fieldname="organizationalEndDate"
                          label="Organizational Report End Date"
                          control={control}
                          class="mb-0"
                          iProps={{ size: 'large' }}
                          initValue=""
                          isRequired={true}
                          rules={{
                            required: 'Organizational Report End Date is required.',
                          }}
                          validate={errors.organizationalEndDate && 'error'}
                          validMessage={errors.organizationalEndDate && errors.organizationalEndDate.message}
                        />
                      </Col>
                    )}

                    <Col span={16}>
                      <Button type="primary" htmlType="submit" className=" " size="large">
                        Confirm and get started
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col span={24}>
                <Button
                  type="text"
                  htmlType="button"
                  className="font-gilroy c-grey"
                  onClick={() => history.push('/previous-transactions')}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Dashboard>
  );
};
