import React, { useEffect } from 'react';
import { Layout, Row, Typography, Spin, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LoginSidebar from '../../molecules/Login/Sidebar';
import LoginHeader from '../../molecules/Login/Header';

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout className="login-layout">
        <LoginHeader />
        <Row align="middle" justify="center">
          <Col span={16}>
            <Row align="middle" justify="center">
              <Col flex="0 0 420px" style={{ overflow: 'hidden', padding: '0 10px' }}>
                {props.children}
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <LoginSidebar />
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};
