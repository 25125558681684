import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Card, Descriptions } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import plusIcon from '../../../../assets/svg/plus.svg';
import penIcon from '../../../../assets/svg/pen-icon.svg';
import { SelectField } from '../../../atoms/FormElement';
import { specificField } from '../../../../configs/constants';
import { Popup } from '../../../atoms/Popup';
import NewContactType from '../../../molecules/Dashboard/NewContactPopup/NewContactType';
import NewContactIndividual from '../../../molecules/Dashboard/NewContactPopup//NewContactIndividual';

const { Paragraph } = Typography;

export default (props) => {
  const {
    control,
    errors,
    selectData,
    changeValueByDropDown,
    updateDropdownValues,
    contributerData,
    contactBoxEditCase,
    fieldname,
    label,
    setValue,
    contactToSelect,
    setContactToSelect,
    arraySelectedItem,
    fixedContactType,
    column,
    errorField,
    clearErrors,
    placeholder,
    noValidation,
  } = props;
  const [contactID, setContctID] = useState('');
  const [openContactCard, setOpenContactCard] = useState(false);
  const [openContactTypePopup, setOpenContactTypePopup] = useState(false);
  const [savePopupType, setSavePopupType] = useState('');

  const [popupType, setPopupType] = useState('');
  const [contctValue, setContctValue] = useState('');
  const [typeIndividual, setTypeIndividual] = useState(false);
  const [editPopupForm, setEditPopupForm] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(null);

  const specific_field = contributerData?.specific_field
    ? specificField?.find((e) => e?.value == contributerData?.specific_field)
    : '';

  useEffect(() => {
    if (setValue && selectData?.length > 0 && contactToSelect) {
      const selectedPayeeList = selectData?.find((e) => e?.value == contactToSelect);

      if (fieldname == arrayIndex) {
        setValue(fieldname, selectedPayeeList);
        setArrayIndex(null);
      }
      contributorData(selectedPayeeList);
      setContactToSelect(null);
    }
  }, [selectData]);

  useEffect(() => {
    if (setValue && arraySelectedItem) {
      setValue(fieldname, arraySelectedItem);
      setContactToSelect(null);
    }
  }, [arraySelectedItem]);

  useEffect(() => {
    if (contactBoxEditCase) {
      contributorData(contactBoxEditCase);
    }
  }, [contactBoxEditCase]);

  const contributorData = (e) => {
    changeValueByDropDown(e?.value);
    setContctValue(e?.value);
    setOpenContactCard(true);
  };

  const openContactType = () => {
    setOpenContactTypePopup(fixedContactType ? false : true);
    setTypeIndividual(fixedContactType ? true : false);
    setArrayIndex(fieldname);
    setContctValue('');
    setEditPopupForm(false);
  };

  const typeSelected = () => {
    setOpenContactTypePopup(false);
    setTypeIndividual(true);
  };

  const updateApi = (contactIds) => {
    setContactToSelect(contactIds);
    setTypeIndividual(false);
    if (errorField) {
      clearErrors(fieldname);
    }
    setPopupType('');
    if (!editPopupForm) {
      updateDropdownValues();
    }
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
  };

  const editContributer = (e) => {
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
    setPopupType(e);
    typeSelected();
    setEditPopupForm(true);
  };

  const selectContactType = {
    closable: false,
    visibility: openContactTypePopup,
    class: 'black-modal',
    content: (
      <NewContactType
        title="New Contact"
        onClose={() => setOpenContactTypePopup(false)}
        typeSelected={typeSelected}
        setPopupType={setPopupType}
      />
    ),
    width: 600,
    onCancel: () => setOpenContactTypePopup(false),
  };

  const individualPopup = {
    closable: false,
    visibility: typeIndividual,
    class: 'black-modal',
    content: (
      <NewContactIndividual
        title="New Contact"
        onClose={() => setTypeIndividual(false)}
        setTypeIndividual={setTypeIndividual}
        popupType={fixedContactType ? fixedContactType : popupType}
        updateApi={(contctValue) => updateApi(contctValue)}
        editPopupForm={editPopupForm}
        data={editPopupForm ? contributerData : {}}
        contctValue={contctValue}
      />
    ),
    width: 600,
    onCancel: () => setTypeIndividual(false),
  };

  const handleKeyDownFunction = (event) => {
    if (event.keyCode === 8) {
      // Backspace key
      setValue(fieldname, null);
      setOpenContactCard(false);
    }
  };

  const keysToInclude = ['mailing_address', 'city', 'state', 'post_code'];
  const result =
    (contributerData?.mailing_address || contributerData?.city || contributerData?.state) &&
    keysToInclude
      ?.filter((key) => contributerData[key] !== undefined && contributerData[key] !== '')
      ?.map((key) => contributerData[key])
      ?.join(', ');
  return (
    <>
      <Col span={column ? column : 24}>
        <SelectField
          isRequired={noValidation ? false : true}
          fieldname={fieldname}
          label={label}
          control={control}
          initValue=""
          setValue={setValue}
          handleKeyDown={(e) => handleKeyDownFunction(e)}
          iProps={{
            size: 'large',
            placeholder: placeholder ?? 'Please select',
          }}
          rules={{
            required: noValidation ? null : `${label} is required.`,
          }}
          onChange={(e) => contributorData(e)}
          selectOption={selectData && selectData?.length > 0 ? selectData : []}
          validate={errorField ? errorField && 'error' : errors[fieldname] && 'error'}
          validMessage={errorField ? errorField.message : errors[fieldname] && errors[fieldname].message}
        />

        <div className="add_new_contat">
          <Button
            type="text"
            htmlType="button"
            className="font-gilroy c-grey d-flex align-center"
            onClick={() => openContactType()}
          >
            <Image src={plusIcon} preview={false} size={24} />
            Add New
          </Button>
        </div>
      </Col>

      {openContactCard && contributerData && Object.keys(contributerData)?.length > 0 && (
        <Col span={24}>
          <div className="detail_label_ins">
            <Button onClick={() => editContributer(contributerData?.type)} type="text" className="right_pen">
              <Image src={penIcon} preview={false} width={18} />
            </Button>
            <Descriptions
              layout="vertical"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              style={{ marginBottom: '10px' }}
            >
              <Descriptions.Item label="Address">
                {contributerData?.mailing_address || contributerData?.city || contributerData?.state ? result : '-'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Descriptions.Item label="Phone">
                {contributerData?.phone ? contributerData?.phone : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {contributerData?.email ? contributerData?.email : '-'}
              </Descriptions.Item>
              {contributerData?.type == 'individual' ||
              contributerData?.type == 'treasurer' ||
              contributerData?.type == 'assistant_treasurer' ||
              contributerData?.type == 'custodian' ? (
                <>
                  {contributerData?.profession && contributerData?.profession?.length > 0 ? (
                    <Descriptions.Item label="Profession">
                      {contributerData?.profession ? contributerData?.profession : '-'}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label="Job Title">
                      {contributerData?.job_title ? contributerData?.job_title : '-'}
                    </Descriptions.Item>
                  )}

                  {contributerData?.specific_field && contributerData?.specific_field?.length > 0 ? (
                    <Descriptions.Item label="Specific Field">
                      {contributerData?.specific_field ? specific_field?.label : '-'}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label="Employer">
                      {contributerData?.employer_name ? contributerData?.employer_name : '-'}
                    </Descriptions.Item>
                  )}
                </>
              ) : null}
            </Descriptions>
          </div>
        </Col>
      )}
      <Popup {...selectContactType} />
      <Popup {...individualPopup} />
    </>
  );
};
