import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, Radio, message } from 'antd';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { SelectField, InputField, InputRadio } from '../../../../../atoms/FormElement';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { countryList, specificField } from '../../../../../../configs/constants';
import { getCandidateFormData } from '../../ducks/actions';
import moment from 'moment';
import { addObject } from '../../../../../../services/crudApis';
import { SAVE_CANDIDATE } from '../../../../../../configs/endpoints';
import ReportAmendment from '../../../../../molecules/ReportAmendment';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const { control, handleSubmit, setValue, getValues, errors } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [isReportId, setIsReportId] = useState(false);
  const [jobTitle, setJobTitle] = useState('JobTitle');
  const [employeeName, setEmployeeName] = useState('employersName');
  const formData = useSelector((state) => state.setting.candidateFormData);

  const partyAffiliation = [
    { label: 'Democratic', value: 'Democratic' },
    { label: 'Republican', value: 'Republican' },
    { label: 'Natural Law', value: 'Natural Law' },
    { label: 'Libertarian', value: 'Libertarian' },
    { label: 'Reform', value: 'Reform' },
    { label: 'Unaffiliated', value: 'Unaffiliated' },
    { label: 'Southern', value: 'Southern' },
    { label: 'Green', value: 'Green' },
    { label: 'Non-Partisan', value: 'Non-Partisan' },
    { label: 'Other', value: 'Other' },
  ];

  useEffect(() => {
    const committeeId = JSON.parse(localStorage.getItem('user')).id;
    dispatch(getCandidateFormData(committeeId));
    //setLoading(true);
  }, []);

  useEffect(() => {
    setValue('mailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == formData?.state);
      const specificData = specificField?.find((e) => e?.value == formData?.candidate_specific_field);
      const selectedpartyAffiliation = partyAffiliation?.find((e) => e?.value == formData?.candidate_party_affiliation);

      const suffixDrop = suffixData?.find((e) => e?.value == formData?.suffix);
      setValue('middle_name', formData?.middle_name);
      setValue('suffix', suffixDrop);
      setValue('candidateFirstName', formData?.candidate_first_name);
      setValue('candidateLastName', formData?.candidate_last_name);

      setValue('partyAffiliation', selectedpartyAffiliation);
      setValue('candidateID', formData?.candidate_id);
      setValue('nextElectionYear', formData?.candidate_next_election_year);
      setValue('officeSought', formData?.candidate_office_sought);
      setValue('jurisdiction', formData?.candidate_jurisdiction);
      setValue('dateOrganized', moment(formData?.date_organized, 'YYYY-MM-DD HH:mm'));
      setValue('mailingStreet', formData?.mailing_address);
      setValue('mailingCity', formData?.city);
      setValue('mailingZip', formData?.post_code);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      setPhoneNumber(formData?.phone);
      setValue('email', formData?.email);

      if (formData?.candidate_profession && formData?.candidate_profession?.length > 0) {
        setJobTitle('Profession');
      } else {
        setJobTitle('JobTitle');
      }
      if (formData?.candidate_specific_field && formData?.candidate_specific_field?.length > 0) {
        setEmployeeName('specificField');
      } else {
        setEmployeeName('employersName');
      }

      setTimeout(() => {
        setValue('jobTitle', formData?.candidate_job_title);
        setValue('profession', formData?.candidate_profession);
        setValue('employerName', formData?.candidate_employer_name);
        setValue('specificField', specificData);
        setValue(
          'jobRadio',
          formData?.candidate_profession && formData?.candidate_profession?.length > 0 ? 'Profession' : 'JobTitle',
        );
        setValue(
          'employeeRadio',
          formData?.candidate_specific_field && formData?.candidate_specific_field?.length > 0
            ? 'specificField'
            : 'employersName',
        );
        setLoading(false);
      }, 1200);
    }
  }, [formData]);

  const onFinish = (values) => {
    setLoading(true);

    if (formData && Object.keys(formData)?.length > 0 && !values?.effectiveDate && formData?.report_ids?.length > 0) {
      if (
        values?.candidateFirstName !== formData?.candidate_first_name ||
        values?.candidateLastName !== formData?.candidate_last_name ||
        values?.mailingStreet !== formData?.mailing_address ||
        values?.mailingCity !== formData?.city ||
        values?.mailingZip !== formData?.post_code ||
        phoneNumber !== formData?.phone ||
        (values.jobRadio == 'JobTitle' && values?.jobTitle !== formData?.candidate_job_title) ||
        (values.jobRadio == 'Profession' && values?.profession !== formData?.candidate_profession) ||
        (values.employeeRadio == 'employersName' && values?.employerName !== formData?.candidate_employer_name) ||
        (values.employeeRadio == 'specificField' && values?.specificField?.value !== formData?.candidate_specific_field)
      ) {
        setIsReportId(true);
        setReportVisible(true);
        setLoading(false);
        return;
      } else {
        setReportVisible(false);
        setIsReportId(false);
      }
    }

    const requestBody = {
      candidateFirstName: values?.candidateFirstName,
      candidateLastName: values?.candidateLastName,
      middle_name: values.middle_name,
      suffix: values.suffix?.value,
      partyAffiliation: values?.partyAffiliation && values?.partyAffiliation?.value,
      candidateID: values?.candidateID,
      email: values?.email,
      officeSought: values?.officeSought,
      jurisdiction: values?.jurisdiction,
      mailingStreet: values?.mailingStreet,
      mailingcountryList: values?.mailingcountryList?.value,
      mailingCity: values?.mailingCity,
      mailingZip: values?.mailingZip,
      phoneNumber: phoneNumber,
      nextElectionYear: values?.nextElectionYear,
      candidate_job_title: jobTitle == 'JobTitle' ? values?.jobTitle : '',
      candidate_profession: jobTitle == 'Profession' ? values?.profession : '',
      candidate_employer_name: employeeName == 'employersName' ? values?.employerName : '',
      candidate_specific_field: employeeName == 'specificField' ? values?.specificField?.value : '',
    };

    addObject(SAVE_CANDIDATE, requestBody)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
          message.success('Information Updated.');
        }, 800);
        setTimeout(() => {
          const committeeId = JSON.parse(localStorage.getItem('user')).id;
          dispatch(getCandidateFormData(committeeId));
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const submitFileExternal = (date) => {
    const value = getValues();
    setReportVisible(false);
    value.effectiveDate = date;
    onFinish(value);
  };

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData)?.length > 0) {
      setValue('mailingCity', formData?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  const suffixData = [
    { label: 'JR', value: 'JR' },
    { label: 'SR', value: 'SR' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
  ];

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={loading}>
        <Row>
          <Col flex="0 0 558px">
            <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
              <Title level={3}>Candidate</Title>
              <Row gutter={[20, 20]}>
                <Col span={24} style={{ marginBottom: '-15px' }}>
                  <Text style={{ fontSize: '12px', color: '#596780' }}>Name</Text>
                </Col>
                <Col span={6}>
                  <InputField
                    isRequired={true}
                    fieldname="candidateFirstName"
                    label=""
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: 'First',
                    }}
                    rules={{
                      required: 'Name is required.',
                    }}
                    validate={errors.candidateFirstName && 'error'}
                    validMessage={errors.candidateFirstName && errors.candidateFirstName.message}
                  />
                </Col>

                <Col span={6}>
                  <InputField
                    fieldname="middle_name"
                    label=""
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: 'Middle',
                    }}
                  />
                </Col>

                <Col span={6}>
                  <InputField
                    fieldname="candidateLastName"
                    label=""
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: 'Last',
                    }}
                  />
                </Col>

                <Col span={6}>
                  <SelectField
                    fieldname="suffix"
                    label=""
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Suffix', isClearable: true }}
                    initValue=""
                    selectOption={suffixData}
                  />
                </Col>

                <Col span={24}>
                  <SelectField
                    isRequired={true}
                    fieldname="partyAffiliation"
                    label="Party Affiliation"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Please select' }}
                    rules={{ required: 'Party Affiliation is required.' }}
                    initValue=""
                    selectOption={partyAffiliation}
                    validate={errors.partyAffiliation && 'error'}
                    validMessage={errors.partyAffiliation && errors.partyAffiliation.message}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname="candidateID"
                    label="Candidate ID"
                    control={control}
                    initValue=""
                    tooltip={true}
                    tooltipTitle={
                      "Come up with a 6-character combination of numbers and letters. The candidate ID field on forms is not currently part of the organizational mechanism in the campaign finance program that registers committees. It remains on the forms as a 'placeholder' for future use."
                    }
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'Candidate ID is required.',
                    }}
                    validate={errors.candidateID && 'error'}
                    validMessage={errors.candidateID && errors.candidateID.message}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    fieldname="nextElectionYear"
                    label="Next Election Year"
                    control={control}
                    optionalLabel="(Optional)"
                    initValue=""
                    iProps={{
                      size: 'large',
                      type: 'number',
                    }}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname="officeSought"
                    label="Office Sought"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'Office Sought is required.',
                    }}
                    validate={errors.officeSought && 'error'}
                    validMessage={errors.officeSought && errors.officeSought.message}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    fieldname="jurisdiction"
                    label="Jurisdiction"
                    control={control}
                    optionalLabel="(Optional)"
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                  />
                </Col>

                <Col span={24} className="mailingField">
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <InputField
                        isRequired={true}
                        fieldname="mailingStreet"
                        label="Mailing Address"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Street Address',
                        }}
                        rules={{
                          required: 'Mailing Address is required.',
                        }}
                        validate={errors.mailingStreet && 'error'}
                        validMessage={errors.mailingStreet && errors.mailingStreet.message}
                      />
                    </Col>

                    <Col span={9}>
                      <SelectField
                        fieldname="mailingcountryList"
                        control={control}
                        class="mb-0"
                        iProps={{ placeholder: 'Please select' }}
                        initValue={{}}
                        selectOption={countryList}
                        onChange={(e) => selectedCountry(e)}
                      />
                    </Col>

                    <Col span={9}>
                      <InputField
                        isRequired={true}
                        fieldname="mailingCity"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'City',
                        }}
                        rules={{
                          required: 'Mailing Address is required.',
                        }}
                        validate={errors.mailingCity && 'error'}
                        validMessage={errors.mailingCity && errors.mailingCity.message}
                      />
                    </Col>

                    <Col span={6}>
                      <InputField
                        isRequired={true}
                        fieldname="mailingZip"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Zip',
                        }}
                        rules={{
                          required: 'Mailing Address is required.',
                        }}
                        validate={errors.mailingZip && 'error'}
                        validMessage={errors.mailingZip && errors.mailingZip.message}
                      />
                    </Col>
                    {(errors.mailingZip || errors.mailingCity || errors.mailingStreet) && (
                      <Col span={24} className="mailingError">
                        is required.
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <InputRadio
                        fieldname="jobRadio"
                        control={control}
                        initValue={'JobTitle'}
                        classField="small-radio"
                        onChange={(e) => setJobTitle(e.target.value)}
                        options={
                          <>
                            <Radio value="JobTitle">Job Title</Radio>
                            <Radio value="Profession">Profession</Radio>
                          </>
                        }
                      />
                    </Col>
                    <Col span={24}>
                      {jobTitle == 'JobTitle' && (
                        <InputField
                          isRequired={true}
                          fieldname="jobTitle"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Job Title',
                          }}
                          rules={{
                            required: 'Job Title is required.',
                          }}
                          validate={errors.jobTitle && 'error'}
                          validMessage={errors.jobTitle && errors.jobTitle.message}
                        />
                      )}

                      {jobTitle == 'Profession' && (
                        <InputField
                          isRequired={true}
                          fieldname="profession"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Profession',
                          }}
                          rules={{
                            required: 'Profession is required.',
                          }}
                          validate={errors.profession && 'error'}
                          validMessage={errors.profession && errors.profession.message}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <InputRadio
                        fieldname="employeeRadio"
                        control={control}
                        initValue={'employersName'}
                        onChange={(e) => setEmployeeName(e.target.value)}
                        classField="small-radio"
                        options={
                          <>
                            <Radio value="employersName">Employer's Name</Radio>
                            <Radio value="specificField">Specific Field</Radio>
                          </>
                        }
                      />
                    </Col>
                    <Col span={24}>
                      {employeeName == 'employersName' && (
                        <InputField
                          isRequired={true}
                          fieldname="employerName"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: "Employer's Name",
                          }}
                          rules={{
                            required: 'Employers Name is required.',
                          }}
                          validate={errors.employerName && 'error'}
                          validMessage={errors.employerName && errors.employerName.message}
                        />
                      )}

                      {employeeName == 'specificField' && (
                        <SelectField
                          isRequired={true}
                          fieldname="specificField"
                          control={control}
                          class="mb-0"
                          iProps={{ placeholder: 'Specific Field' }}
                          rules={{ required: 'Specific Field is required.' }}
                          initValue=""
                          selectOption={specificField}
                          validate={errors.specificField && 'error'}
                          validMessage={errors.specificField && errors.specificField.message}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label">
                      <label className="" title="Phone Number">
                        Phone Number
                      </label>
                      <div className="optional-label" style={{ top: '4px' }}>
                        (Optional)
                      </div>
                    </div>
                  </div>
                  <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                    {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                  </InputMask>
                </Col>

                <Col span={12}>
                  <InputField
                    fieldname="email"
                    label="Email"
                    optionalLabel="(Optional)"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: 'Check if the email is valid',
                      },
                    }}
                    validate={errors.email && 'error'}
                    validMessage={errors.email && errors.email.message}
                  />
                </Col>

                <Col span={24}>
                  <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
    </>
  );
};
