import React from 'react';
import Icon from '@ant-design/icons';

const CircleArrowRed = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" />
    <path
      d="M33 25L39.2929 31.2929C39.6834 31.6834 39.6834 32.3166 39.2929 32.7071L33 39"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M39.5 32H23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CircleArrowRedIcon = (props) => <Icon component={CircleArrowRed} {...props} />;
export default CircleArrowRedIcon;
