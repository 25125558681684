import React from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import crossIcon from '../../../../assets/svg/cross-icon.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const { onClose, title, onContact } = props;
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title level={4} className="mb-0">
          {title}
        </Title>
      </Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>

      <Col span={24}>
        <Paragraph className="w-100 c-l-blue mb-1">
          The following functionalities are <b>not yet available:</b>
        </Paragraph>
        <ul className="w-100 c-l-blue mb-1" style={{ paddingLeft: '20px' }}>
          <li>Ability to file amendments</li>
          <li>Ability to import, add, and report Non-Monetary Gifts (CRO-1330)</li>
          <li>Ability to import, add, and report Loans (CRO-1410, CRO-1420, CRO-1430, CRO-1440)</li>
          <li>Ability to import, add, and report Exempt Purchase Price Sales (CRO-1265)</li>
          <li>Ability to import, add, and report Independent Expenditures (CRO-2520)</li>
          <li>Ability to import, add, and report Administrative support (CRO-1710)</li>
          <li>
            Ability to import, add, and report Debts and Obligations Owed to/by the Committee (CRO-1610, CRO-1620)
          </li>
          <li>Ability to import, add, and report Account Transfers Within the Committee (CRO-1720)</li>
          <li>Ability to import negative transactions. You can add them manually only.</li>
        </ul>
        <Paragraph className="w-100 c-l-blue mb-1">
          The remaining functionality along with user experience improvements will be delivered prior to the next filing
          period (10-22-2022).
        </Paragraph>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
          <Col>
            <Button type="primary" onClick={onContact} htmlType="button" className="font-gilroy white-btn" size="large">
              Contact Us
            </Button>
          </Col>

          <Col>
            <Button type="secondary" htmlType="submit" className="font-gilroy" size="large" onClick={onClose}>
              Got It
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
