import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Form, Button, message } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Dashboard from '../../../../pages/Dashboard';
import GeneralContribution from '../ReceiptsForms/GeneralContribution';
import Interest from '../ReceiptsForms/Interest';
import ReimbursementsCommittee from '../ReceiptsForms/ReimbursementsCommittee';
import ContributionReimbursed from '../ReceiptsForms/ContributionReimbursed';
import { getReceiptFormData } from '../ducks/actions';
import { editReceipts } from '../../../../../services/receipt';
import { Popup } from '../../../../atoms/Popup';
import ReportConfirmationPopup from '../../../../molecules/ReportConfirmationPopup';
import OutsideSourceofIncome from '../ReceiptsForms/OutsideSourceofIncome';
import ReportAmendment from '../../../../molecules/ReportAmendment';
import ExemptPurchasePrice from '../ReceiptsForms/ExemptPurchasePrice';
import LearnMore from '../../../../molecules/ExemptPurchase/LearnMore';
import ForgivenLoan from '../ReceiptsForms/ForgivenLoan';
import LoanProceed from '../ReceiptsForms/LoanProceed';
import LimitWarning from '../../../../molecules/LimitWarning';
import DebtRepayment from '../ReceiptsForms/DebtRepayment';
import Confirmation from '../../Expenditures/Popup/Confirmation';

const { Paragraph, Title } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch, getValues, reset, errors, setError, clearErrors } = useForm({
    defaultValues: {
      breakdown: [{ name: 'useFieldArray' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'breakdown',
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [reportVisible, setReportVisible] = useState(false);
  const [learnMorePopup, setLearnMorePopup] = useState(false);
  const [isReportId, setIsReportId] = useState(false);
  const [isAmendment, setIsAmendment] = useState(true);
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [visibleAddReceipt, setVisibleAddReceipt] = useState(false);
  const [confirmation, setIsConfirmation] = useState(false);
  const [highLimit, setHighlimit] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [oldAmount, setOldAmount] = useState(0);
  const [lowLimit, setLowlimit] = useState(false);
  const [getdataValue, setdataValue] = useState('');
  const [popdata, setPopData] = useState();
  const url = location.pathname;
  let receiptType = url.split('/')[2];
  const title = receiptType.replace(/-/g, ' ').toLowerCase();
  const receiptId = location?.state?.receiptId;
  const formData = useSelector((state) => state.receipt.receiptFormData);
  const apiType = receiptType.replace(/-/g, '_').toLowerCase();
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [formOfPayment, setFormOfPayment] = useState('');
  const [breakDown, setBreakDown] = useState('No');

  const limitWarningPopup = {
    // closable: true,
    visibility: visibleAddReceipt,
    class: 'black-modal',
    content: (
      <LimitWarning
        title="Contribution Limit Warning"
        errorHigh="Contributions received from individuals, candidate or political action commettees cannot exceed $6,400."
        errorLow="Committee may not accept cash contributions in excess of $50."
        btnTxt="Save receipt anyway"
        bottomText="If the current receipt amount doesn't exceed $6,400, please check all the receipts from this contributor."
        tableText="Receipts That Counted Toward the Limit"
        visibleAddReceipt={visibleAddReceipt}
        onClose={() => setVisibleAddReceipt(false)}
        highLimit={highLimit}
        lowLimit={lowLimit}
        onFinish={() => onFinish(getdataValue, true)}
        getdataValue={getdataValue}
        popdata={popdata}
      />
    ),
    width: 542,
    onCancel: () => setVisibleAddReceipt(false),
  };
  useEffect(() => {
    dispatch(getReceiptFormData(apiType, receiptId));
    // setLoading(true);
  }, []);

  const ConfirmationPopup = {
    closable: false,
    visibility: confirmation,
    class: 'black-modal confirmation_modal',
    content: (
      <Confirmation
        onClose={() => setIsConfirmation(false)}
        title="Confirmation Of Full Debt Repayment"
        onConfirm={() => onFinish(getdataValue, true, true)}
        paragraph={
          <>
            The entered amount fully covers the remaining debt outstanding balance. The debt will be marked as{' '}
            <b>Paid.</b>
            <br />
            <br />
            Please confirm if you would like to proceed.
          </>
        }
      />
    ),
    width: 542,
    onCancel: () => setIsConfirmation(false),
  };

  const onFinish = (values, force = false, confirmPopup = false) => {
    if (confirmPopup) {
      setIsConfirmation(false);
    }

    const mainAmount = oldAmount + remainingAmount;
    setdataValue(values);
    if (
      Math.round((+values?.amount + Number.EPSILON) * 100) / 100 > mainAmount &&
      (receiptType == 'debt_repayment' || receiptType == 'debt-repayment')
    ) {
      setError('amount', {
        type: 'required',
        message: 'Please enter an amount that does not exceed the remaining debt balance.',
      });
      return;
    }

    if (+values?.amount === 0 && (receiptType == 'debt_repayment' || receiptType == 'debt-repayment')) {
      setError('amount', {
        type: 'required',
        message: 'The Amount Repaid cannot be 0.',
      });
      return;
    }
    if (
      Math.round((+values?.amount + Number.EPSILON) * 100) / 100 === mainAmount &&
      !confirmPopup &&
      (receiptType == 'debt_repayment' || receiptType == 'debt-repayment') &&
      mainAmount !== formData.receiptData?.amount
    ) {
      setIsConfirmation(true);
      return;
    }
    setLoading(true);
    setReportVisible(false);
    setIsReportId(false);
    if (formData && !values?.effectiveDate && formData?.receiptData && formData?.receiptData?.report_id) {
      //console.log('length 0', values?.outside_source_explaination);
      if (values?.purpose_code) {
        var purposeUnique = values?.purpose_code.filter(function (obj) {
          return !formData?.receiptData?.purpose_code.some(function (obj2) {
            return obj.value == obj2;
          });
        });
      }

      if (
        // (values?.date &&
        //   moment(values?.date, 'YYYY-MM-DD') !== formData.receiptData?.date &&
        //   moment(formData.receiptData?.date, 'YYYY-MM-DD')) ||
        (values?.amount && values?.amount !== formData.receiptData?.amount) ||
        (values?.comments && values?.comments !== formData.receiptData?.comments) ||
        (values?.formOfPayment && values?.formOfPayment !== formData.receiptData?.payment_type) ||
        (values?.contributor && values?.contributor?.value !== formData?.receiptData?.contributor_id) ||
        (values?.in_kind_description && values?.in_kind_description !== formData.receiptData?.in_kind_description) ||
        (values?.account && values?.account?.value !== formData?.receiptData?.account_id) ||
        (values?.credit_used && values?.credit_used !== formData.receiptData?.gift_description) ||
        (values?.reimbursee && values?.reimbursee?.value !== formDaformData?.receiptData?.reimbursee_id) ||
        (values?.financial_institution_id &&
          values?.financial_institution_id?.value !== formData?.receiptData?.financial_institution_id) ||
        (values?.outside_source_explaination &&
          values?.outside_source_explaination !== formData.receiptData?.outside_source_explaination) ||
        (values?.payee && values?.payee?.value !== formData.receiptData?.contributor) ||
        (values?.reimbursed_expenditure_id &&
          values?.reimbursed_expenditure_id?.value !== formData?.receiptData?.reimbursed_expenditure_id) ||
        (values?.remimbursement_purpose &&
          values?.remimbursement_purpose !== formData.receiptData?.remimbursement_purpose)
      ) {
        setIsReportId(true);
        setReportVisible(true);
        setLoading(false);
        setIsAmendment(false);
        return;
      } else {
        //console.log('else 0');
        setReportVisible(false);
        setIsReportId(false);
      }
    }
    let endorsers = [];
    values?.endorser?.map((en) => {
      endorsers.push({
        endorser_id: en?.endorser_id,
        is_amount: en?.is_amount == 'amount' ? true : false,
        amount: en?.amount ?? '',
        contact_id: en?.contact_id?.value ?? '',
      });
    });

    const data = {
      type: receiptType.replace(/-/g, '_'),
      payment_type: values?.formOfPayment ? values?.formOfPayment : '',
      check_number: values?.check_number ?? '',
      date: values?.date ? moment(values?.date).format('YYYY-MM-DD HH:mm:ss') + 'Z' : moment(),
      amount: values?.amount ? parseFloat(values?.amount) : 0,
      in_kind_description: values?.in_kind_description ? values?.in_kind_description : '',
      comments: values?.comments ? values?.comments : '',
      contributor_id: values?.contributor ? values?.contributor.value : '',
      account_id: values.account ? values.account.value : '',
      remimbursement_purpose: values?.remimbursement_purpose ? values?.remimbursement_purpose : '',
      modfied_date: new Date(),
      credit_used: values?.credit_used && values?.credit_used == 'Yes' ? true : false,
      reimbursed_expenditure_id: values?.reimbursed_expenditure_id ? values?.reimbursed_expenditure_id?.value : '',
      financial_institution_id: values?.financial_institution_id ? values?.financial_institution_id?.value : '',
      created_date: '2022-05-27T19:41:56.729Z',
      contributor_id: values?.contributor ? values?.contributor?.value : '',
      payee_id: values?.payee ? values?.payee.value : '',
      reimbursee_id: values?.reimbursee ? values?.reimbursee.value : '',
      account_id: values?.account ? values?.account?.value : '',
      outside_source_explaination: values?.outside_source_explaination ?? '',
      exempt_plan_id: values?.exempt_plan_id ? values?.exempt_plan_id?.value : '',
      total_exceded_comment: values?.total_exceded_comment ? values?.total_exceded_comment : '',
      force: force === true,
      obligation_id: values?.obligation_id?.value ?? undefined,
    };
    if (values?.item_sold) {
      data.item_sold = parseInt(values?.item_sold);
    }

    if (values?.sale_per_sbe) {
      data.sale_per_sbe = values?.sale_per_sbe && values?.sale_per_sbe == 'Yes' ? true : false;
    }

    if (values?.total_purchase_exceeded) {
      data.total_purchase_exceeded =
        values?.total_purchase_exceeded && values?.total_purchase_exceeded == 'Yes' ? true : false;
    }

    let url;
    if (receiptType === 'loan-proceed') {
      data.id = receiptId;
      values?.end_date ? (data.end_date = values.end_date) : null;
      data.rate = values?.rate ?? '';
      data.security_pledged = values?.security_pledged ?? '';
      data.loan_number = values?.loan_number ?? '';
      data.contributor_id = values?.contributor_id?.value ?? '';
      data.lending_institution_id = values?.lending_institution?.value ?? '';
      data.endorsers = endorsers;
      data.deleted_endorsers = deletedIndexes;

      url = '/updateReceiptEndorsement';
    } else if (receiptType === 'forgiven-loan') {
      data.amount = values?.amount ?? '';
      data.loan_id = values?.loan_forgiven?.value ?? '';
    } else if (receiptType === 'exempt-purchase-price-sale' || receiptType === 'exempt-purchase-price-sales') {
      let itemsArray = [];
      values?.item_sold?.map((en) => {
        itemsArray.push({
          item_id: en?.item_id?.value ?? '',
          qty: en?.qty ?? '',
        });
      });

      data.exempt_plan_id = values?.exempt_plan_id ? values?.exempt_plan_id?.value : '';
      data.item_sold = itemsArray;
    }

    let newData = [];
    if (values?.breakdown?.length > 0 && values?.delaration_is_supporting === 'Yes') {
      let dataArray = [];
      values?.breakdown?.map((e) => {
        const newObject = { ...data };
        newObject.check_number = e?.check_number ?? '';
        newObject.amount = e?.amount ? parseFloat(e?.amount) : '';

        dataArray.push(newObject);
        return dataArray;
      });
      newData = dataArray;
    } else if (receiptType === 'loan_proceed' || receiptType === 'loan-proceed') {
      newData = data;
    } else {
      newData = [data];
    }

    editReceipts(newData, receiptId, url)
      .then((response) => {
        setIsAmendment(false);
        message.success('Receipt saved.');
        setTimeout(() => {
          setLoading(false);
          history.push('/receipts');
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '412') {
          const message = JSON.parse(e?.response?.data?.error?.message);

          setHighlimit(false);
          setPopData();
          setLowlimit(false);

          if (message?.listOfEntities?.length > 0) {
            setHighlimit(true);
            setPopData(message?.listOfEntities);
          }

          if (message?.errors?.CONTRIBUTION_LIMIT_REACHED) {
            setHighlimit(true);
          }

          if (message?.errors?.CASH_FIFTY) {
            setLowlimit(true);
          }

          setVisibleAddReceipt(true);
        } else {
          const errorMessage = JSON.parse(e?.response?.data?.error?.message);
          message.error(errorMessage?.message ?? 'Something went wrong');
        }
      });
  };

  const confirmReport = () => {
    setReportVisible(true);
  };

  useEffect(() => {
    //console.log('if-------', formData);
    if (formData && formData?.receiptData?.report_id) {
      setReceiptReportID(formData?.receiptData?.report_id);
      setIsReportId(true);
    } else {
      //console.log('else');
      setIsReportId(false);
    }
  }, [formData]);

  const submitFileExternal = (values) => {
    //console.log('values------', values);
    setReportVisible(false);
    const value = getValues();
    value.effectiveDate = 'no Data';
    onFinish(value);
  };

  const confirmReportPopup = {
    closable: false,
    visibility: reportVisible,
    class: 'black-modal',
    content: (
      <ReportConfirmationPopup
        title="Confirm Report Amendments"
        onClose={() => (setReportVisible(false), setIsAmendment(true))}
        submitFileExternal={submitFileExternal}
        receiptReportID={receiptReportID}
        onDelete={() => onDelete()}
      />
    ),
    width: 542,
    onCancel: () => setReportVisible(false),
  };

  const learnMore = {
    closable: false,
    visibility: learnMorePopup,
    class: 'black-modal',
    content: <LearnMore onClose={() => setLearnMorePopup(false)} />,
    width: 450,
    onCancel: () => setLearnMorePopup(false),
  };

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <>
      <Dashboard load={loading} heading="Receipt" backLink="/receipts">
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Title level={2} className="text-capitalize">
                {title}
              </Title>
            </Col>

            {receiptType == 'contribution-to-be-reimbursed' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report in-kind contributions of $1,000 or less where there was an agreement between
                  the committee and the contributor that they would be reimbursed for the money spent on the committee’s
                  behalf. These in-kind contributions may not exceed $1,000, the committee must be notified with 45 days
                  that money was spent on the committee’s behalf, and the refund/reimbursement to the contributor must
                  be made within 7 days (of the notification).
                </Paragraph>
                <Paragraph className="c-blue">
                  The reimbursement must also be disclosed on the Refunds/Reimbursements from the Committee form.
                </Paragraph>
              </Col>
            )}

            {receiptType == 'general_contribution' && (
              <Col span={24}>
                <Paragraph>
                  <b>Contribution limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>
                    Contributions received from individuals, candidate or political action commettees cannot exceed
                    $6,400.{' '}
                    <a href="https://www.ncsbe.gov/campaign-finance/candidate-committees" target="_blank">
                      Learn More
                    </a>
                  </li>
                  <li>Committee may not accept cash contributions in excess of $50.</li>
                </ol>
              </Col>
            )}

            {receiptType == 'refund-reimbursement-to-the-committee' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report a refund or reimbursement for a previous expenditure.
                </Paragraph>
              </Col>
            )}

            {receiptType == 'exempt-purchase-price-sale' || receiptType == 'exempt-purchase-price-sales' ? (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Political Party Executive Committees use this form to report purchases from an approved Exempt Sales
                  Plan.{' '}
                  <Button
                    type="link"
                    className="c-doger-blue p-0 text-capitalize"
                    onClick={() => setLearnMorePopup(true)}
                  >
                    Learn more.
                  </Button>
                </Paragraph>
              </Col>
            ) : null}
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {receiptType == 'general-contribution' && (
              <GeneralContribution
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                remainingBalance={remainingBalance}
                setRemainingBalance={setRemainingBalance}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
                breakDown={breakDown}
                setBreakDown={setBreakDown}
              />
            )}

            {receiptType == 'contribution-to-be-reimbursed' && (
              <ContributionReimbursed
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {receiptType == 'refund-reimbursement-to-the-committee' && (
              <ReimbursementsCommittee
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                remainingBalance={remainingBalance}
                setRemainingBalance={setRemainingBalance}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
                breakDown={breakDown}
                setBreakDown={setBreakDown}
              />
            )}

            {(receiptType == 'debt_repayment' || receiptType == 'debt-repayment') && (
              <DebtRepayment
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                formData={formData}
                setRemainingAmount={setRemainingAmount}
                setOldAmount={setOldAmount}
                oldAmount={oldAmount}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
                remainingAmount={remainingAmount}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'interest' && (
              <Interest
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'outside-source-of-income' && (
              <OutsideSourceofIncome
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'exempt-purchase-price-sale' || receiptType == 'exempt-purchase-price-sales' ? (
              <ExemptPurchasePrice
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
              />
            ) : null}

            {receiptType == 'forgiven-loan' && (
              <ForgivenLoan
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {receiptType == 'loan-proceed' && (
              <LoanProceed
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                formData={formData}
                setLoading={setLoading}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  onClick={() => history.push('/receipts')}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="font-gilroy"
                  size="large"
                  disabled={
                    formOfPayment === 'check' && breakDown === 'Yes' && (remainingBalance < 0 || remainingBalance > 0)
                  }
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={false}
        receiptId={formData?.receiptData?.report_id}
      />
      <Popup {...learnMore} />
      <Popup {...limitWarningPopup} />
      <Popup {...ConfirmationPopup} />
    </>
  );
};
