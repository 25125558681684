import React from 'react';
import Icon from '@ant-design/icons';

const Expenditures = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#fff" d="M8 12L16 12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle stroke="#fff" cx="12" cy="12" r="8.5" strokeWidth="1.5" />
  </svg>
);

const ExpendituresIcon = (props) => <Icon component={Expenditures} {...props} />;
export default ExpendituresIcon;
