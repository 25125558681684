import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Tooltip } from 'antd';
import { InputField, SelectField } from '../../../../../atoms/FormElement';
import plusIcon from '../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../assets/svg/trash-icon.svg';
import { InfoCircleFilled } from '@ant-design/icons';

const { Paragraph, Title } = Typography;

const initQ = {
  item_id: '',
  name: '',
  price: '',
  charged_price: '',
  qty: '',
  anticipated_income: '',
};

export default (props) => {
  const { formData, fields, control, errors, setValue, append, remove, deletedIndexes, setDeletedIndexes } = props;

  const onRemove = (e, index) => {
    remove(index);
    //console.log('e?.end_id', e);
    if (e?.item_id) {
      setDeletedIndexes([...deletedIndexes, e?.item_id]);
    }
  };
  const onAdd = () => {
    //console.log('asas', append, initQ);
    append(initQ);
  };

  return (
    <>
      <Col span={24}>
        <Title level={4} className="text-center mb-0 mt-2">
          Items For Sale
        </Title>
      </Col>

      <Col span={24}>
        {fields?.map((item, index) => {
          return (
            <Fragment key={item.id}>
              <Row
                gutter={[20, 20]}
                align="bottom"
                style={{
                  marginBottom: '25px',
                  paddingTop: '15px',
                  position: 'relative',
                  borderTop: index == 0 ? '0' : '1px solid #D2D6E2',
                }}
              >
                <InputField
                  fieldname={`items_sale[${index}].id`}
                  label=""
                  control={control}
                  class="d-none mb-0"
                  valueGot={item?.id}
                  setValue={setValue}
                  iProps={{ size: 'large' }}
                />

                <InputField
                  fieldname={`items_sale[${index}].item_id`}
                  label=""
                  control={control}
                  class="d-none mb-0"
                  initValue={item?.item_id}
                  iProps={{ size: 'large' }}
                />

                <Col span={24}>
                  <InputField
                    isRequired={true}
                    fieldname={`items_sale[${index}].item_name`}
                    label="Item Name"
                    control={control}
                    initValue={item?.item_name}
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'Item Name is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.item_name &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.item_name &&
                      errors?.items_sale[index]?.item_name?.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname={`items_sale[${index}].price`}
                    label="Fair Market Value"
                    control={control}
                    initValue={item?.price}
                    iProps={{
                      size: 'large',
                      prefix: '$',
                      type: 'number',
                    }}
                    rules={{
                      required: 'Fair Market Value is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.price &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.price &&
                      errors?.items_sale[index]?.price?.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname={`items_sale[${index}].charged_price`}
                    label="Price Party Will Charge"
                    control={control}
                    initValue={item?.charged_price}
                    iProps={{
                      size: 'large',
                      prefix: '$',
                      type: 'number',
                    }}
                    rules={{
                      required: 'Price Party Will Charge is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.charged_price &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.charged_price &&
                      errors?.items_sale[index]?.charged_price?.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname={`items_sale[${index}].qty`}
                    label="Quantity (Max)"
                    control={control}
                    initValue={item?.qty}
                    iProps={{
                      size: 'large',
                      type: 'number',
                    }}
                    rules={{
                      required: 'Quantity is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.qty &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.qty &&
                      errors?.items_sale[index]?.qty?.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname={`items_sale[${index}].anticipated_income`}
                    label="Anticipated Income (Max)"
                    control={control}
                    initValue={item?.anticipated_income}
                    iProps={{
                      size: 'large',
                      prefix: '$',
                      type: 'number',
                    }}
                    rules={{
                      required: 'Anticipated Income is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.anticipated_income &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.items_sale?.length > 0 &&
                      errors?.items_sale[index]?.anticipated_income &&
                      errors?.items_sale[index]?.anticipated_income?.message
                    }
                  />
                </Col>

                {index > 0 && (
                  <div className="accounts_remove" style={{ top: 50 }}>
                    <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                      <Image src={trashIcon} preview={false} size={24} />
                    </Button>
                  </div>
                )}
              </Row>
            </Fragment>
          );
        })}
      </Col>
      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Item
        </Button>
      </Col>
    </>
  );
};
