import axiosInterceptor from './axiosInterceptor';
import { baseUrl } from '../configs/constants';
const auth_url = baseUrl + '/login';
const signup_url = baseUrl + '/signup';
const verifyEmail_url = baseUrl + '/emailVerification';
const forgotPassword_url = baseUrl + '/forgotPassword';
const resetPassword_url = baseUrl + '/resetPassword';

export const authentications = (email, password) => {
  const data = {
    email: email,
    password: password,
  };
  return axiosInterceptor.post(auth_url, data);
};

export const signup = (committeeType, email, password) => {
  const data = {
    committee_type: committeeType,
    email: email,
    password: password,
  };
  return axiosInterceptor.post(signup_url, data);
};

export const verifyEmail = (authId, verificationToken) => {
  const data = {
    id: authId,
    token: verificationToken,
  };
  return axiosInterceptor.post(verifyEmail_url, data);
};

export const forgotPassword = (email) => {
  const data = {
    email: email,
  };
  return axiosInterceptor.post(forgotPassword_url, data);
};

export const resetPassword = (newPassword, token) => {
  const data = {
    newPassword: newPassword,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axiosInterceptor.post(resetPassword_url, data, config);
};

// export const refreshAuth = (refresh) => {
//     const postData = getQueryString(data)
//     return axiosInterceptor.post(auth, postData)
// }
