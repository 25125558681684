import React, { useState } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Dashboard from '../../../../../pages/Dashboard';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} heading="New Receipt" backLink="/receipts">
      <Card className="white-card w-100 pcard-0">
        <Row justify="center">
          <Col flex="0 0 462px">
            <Row className="text-center" gutter={[8, 8]}>
              <Col span={24}>
                <Title level={3}>Receipt Type</Title>
              </Col>
              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/general-contribution">
                  <Card>
                    <Text>General Contribution</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/contribution-to-be-reimbursed">
                  <Card>
                    <Text>Contribution to be Reimbursed</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/refund-reimbursement-to-the-committee">
                  <Card>
                    <Text>Refund / Reimbursement to the Committee</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/interest">
                  <Card>
                    <Text>Interest</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/outside_source_of_income">
                  <Card>
                    <Text>Outside Source of Income</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/loan_proceed">
                  <Card>
                    <Text>Loan Proceed</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/forgiven_loan">
                  <Card>
                    <Text>Forgiven Loan</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/debt_repayment">
                  <Card>
                    <Text>Debt Repayment</Text>
                  </Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link className="receipt-card" to="/new-receipts/exempt_purchase_price_sale">
                  <Card>
                    <Text>Exempt Purchase Price Sale</Text>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Dashboard>
  );
};
