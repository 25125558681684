import axiosInterceptor from './axiosInterceptor';
import { baseUrl } from '../configs/constants';

export const getObject = (endPoint, data) => {
  return axiosInterceptor.get(baseUrl + '/' + endPoint);
};

export const addObject = (endPoint, data) => {
  return axiosInterceptor.post(baseUrl + '/' + endPoint, data);
};

export const editObject = (endPoint, data) => {
  return axiosInterceptor.patch(baseUrl + `/${endPoint}`, data);
};

export const deleteObject = (endPoint, ids) => {
  return axiosInterceptor.post(baseUrl + `/${endPoint}`, ids);
};

export const downloadFile = (url) => {
  return axiosInterceptor({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  });
};
export const saveFile = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const resetAccount = () => {
  return axiosInterceptor.get(baseUrl + '/resetCommitteeData');
};

export const checkIfAccountIsReported = (accountId) => {
  return axiosInterceptor.get(baseUrl + `/checkIfAccountIsReported/${accountId}`);
};

export const getLogsByCommitteeId = () => {
  return axiosInterceptor.get(baseUrl + `/getLogsByCommitteeId`);
};

export const replaceBankAccount = (data) => {
  return axiosInterceptor.post(baseUrl + `/replaceBankAccount`, data);
};

export const deactivateTreasurer = (id) => {
  return axiosInterceptor.patch(baseUrl + `/deactivateTreasurer/${id}`);
};