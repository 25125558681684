import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs } from 'antd';
import { useLocation } from 'react-router';
import Dashboard from '../../../../pages/Dashboard';
import * as TabCards from './StepsReport';

const { TabPane } = Tabs;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const url = location.pathname.split('/')[2];
  const heading = url.replace(/-/g, ' ');
  let tabs = [];

  {
    url == 'organizational' ||
    url == 'organizational-amendment' ||
    url == 'mid-year-semiannual' ||
    url == 'mid-year-semiannual-amendment' ||
    url == 'year-end-semiannual' ||
    url == 'year-end-semiannual-amendment' ||
    url == 'first-quarter-plus' ||
    url == 'first-quarter-plus-amendment' ||
    url == 'second-quarter' ||
    url == 'second-quarter-amendment' ||
    url == 'secord-quarter' ||
    url == 'secord-quarter-amendment' ||
    url == 'third-quarter-plus' ||
    url == 'third-quarter-plus-amendment' ||
    url == 'fourth-quarter' ||
    url == 'fourth-quarter-amendment'
      ? (tabs = [
          {
            name: 'Details',
            Comp: 'Details',
          },
          {
            name: 'Receipts',
            Comp: 'Receipts',
          },
          {
            name: 'Expenditures',
            Comp: 'Expenditures',
          },
          {
            name: 'Other',
            Comp: 'Other',
          },
        ])
      : null;

    url == '48-hour-notice' || url == '48-hour-notice-amendment'
      ? (tabs = [
          {
            name: 'Details',
            Comp: 'Details',
          },
          {
            name: 'Receipts',
            Comp: 'Receipts',
          },
        ])
      : null;
  }

  return (
    <Dashboard load={loading} heading={heading} backLink="/reports">
      <Card className="white-card w-100 pcard-0 steps-reports">
        <Tabs defaultActiveKey="1">
          {tabs.map((item, index) => {
            const Cardi = TabCards[item.Comp];
            return (
              <TabPane tab={item.name} key={index + 1} forceRender={true}>
                <Cardi {...props} />
              </TabPane>
            );
          })}
        </Tabs>
      </Card>
    </Dashboard>
  );
};
