import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../redux/store';
import '../assets/styles/antd.less';
import '../assets/styles/styles.scss';

import Pages from './Pages';

const Root = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route component={Pages} />
      </Switch>
    </Router>
  </Provider>
);
export default Root;
