import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFieldArray } from 'react-hook-form';
import Dashboard from '../../../../pages/Dashboard';
import DebtOwedBYTheCommittee from '../DebtsForms/DebtOwedBYTheCommittee';
import DebtOwedToTheCommittee from '../DebtsForms/DebtOwedToTheCommittee';
import { CURRENCY_FORMATTER } from '../../../../../configs/constants';

const { Paragraph } = Typography;
const { Option } = Select;

const expenditureTypeList = [
  { label: 'Debt Owed BY The Committee', value: 'debt_owed_by_the_committee' },
  { label: 'Debt Owed TO The Committee', value: 'debt_owed_to_the_committee' },
];

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalIncurred, setTotalIncurred] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  let selectValue = url.split('/')[2].replace(/-/g, '_');
  const [expenditureType, setExpenditureType] = useState(selectValue);

  const handleChange = (value) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    setTimeout(() => {
      setExpenditureType(value);
      history.push(value);
    }, 1200);
  };

  return (
    <>
      <Dashboard
        load={loading}
        heading="New Debts"
        backLink={
          expenditureType == 'debt_owed_by_the_committee'
            ? '/debts/owed-by-the-comittee'
            : '/debts/owed-to-the-comittee'
        }
      >
        <Card className="white-card w-100">
          <Row justify="space-between" gutter={[20, 20]} className="mb-24">
            <Col>
              <Select defaultValue={expenditureType} onChange={handleChange} className="custom-ant-select">
                {expenditureTypeList &&
                  expenditureTypeList?.map((item, index) => (
                    <Fragment key={index}>
                      <Option value={item?.value}>{item?.label}</Option>
                    </Fragment>
                  ))}
              </Select>
            </Col>
            <Col>
              <Row gutter={42}>
                <Col span={8}>
                  <div className="amount_paid amount_debt">
                    <div className="top_card_debts">Total Amount Paid</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(totalPaid)}</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="amount_incurred amount_debt">
                    <div className="top_card_debts">Total Amount Incurred</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(totalIncurred)}</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="amount_balance amount_debt">
                    <div className="top_card_debts">Remaining Balance</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(remainingBalance)}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {expenditureType == 'debt_owed_by_the_committee' && (
            <DebtOwedBYTheCommittee
              setLoading={setLoading}
              setTotalIncurred={setTotalIncurred}
              setTotalPaid={setTotalPaid}
              totalIncurred={totalIncurred}
              remainingBalance={remainingBalance}
              setRemainingBalance={setRemainingBalance}
            />
          )}

          {expenditureType == 'debt_owed_to_the_committee' && (
            <DebtOwedToTheCommittee
              setLoading={setLoading}
              setTotalIncurred={setTotalIncurred}
              setTotalPaid={setTotalPaid}
              totalIncurred={totalIncurred}
              remainingBalance={remainingBalance}
              setRemainingBalance={setRemainingBalance}
            />
          )}
        </Card>
      </Dashboard>
    </>
  );
};
