import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField } from '../../../../../atoms/FormElement';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { editObject } from '../../../../../../services/crudApis';
import { UPDATE_COMMITTEE_EMAIL } from '../../../../../../configs/endpoints';
import { Popup } from '../../../../../atoms/Popup';
import ResetAccountPop from '../../../../../molecules/ResetAccountPop';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const history = useHistory();
  const { control, handleSubmit, errors, setValue } = useForm({
    shouldUnregister: false,
  });
  const formData = useSelector((state) => state.setting.committeeFormData);
  const [openResetPop, setOpenResetPop] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formData && formData?.committee_email) {
      setValue('email', formData?.committee_email);
    }
  }, [formData]);

  const onFinish = (values) => {
    setLoading(true);
    const userEmail = values.email.toLowerCase();
    editObject(UPDATE_COMMITTEE_EMAIL, { email: userEmail })
      .then((response) => {
        //console.log('response', response);
        setLoading(false);
        if (response.data) {
          message.success('Information Updated.');
        } else if (response.data == false) {
          message.error('Email is already in use.');
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const resetAccount = {
    closable: false,
    visibility: openResetPop,
    class: 'black-modal',
    content: <ResetAccountPop title="Your Data Will Be Lost" onClose={() => setOpenResetPop(false)} />,
    width: 400,
    onCancel: () => setOpenResetPop(false),
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[25, 50]}>
        <Col flex="0 0 558px">
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Title level={3}>Committee</Title>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="email"
                  label="Email"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Please input email',
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'Check if the email is valid',
                    },
                  }}
                  validate={errors.email && 'error'}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col span={24}>
          <Button
            type="text"
            htmlType="button"
            className="c-grey"
            style={{ fontSize: 12 }}
            onClick={() => setOpenResetPop(true)}
          >
            Reset Account
          </Button>
        </Col>
      </Row>

      <Popup {...resetAccount} />
    </Spin>
  );
};
