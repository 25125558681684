import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { countryList } from '../../../../../configs/constants';
import { countyList } from '../../../../../configs/county-list';
import { getCommitteeFormData } from '../../../Dashboard/Settings/ducks/actions';
import { SelectField, InputField, DateField } from '../../../../atoms/FormElement';
import { SAVE_COMMITTEE } from '../../../../../configs/endpoints';
import { addObject } from '../../../../../services/crudApis';
const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, errors } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [federalOrganization, setFederalOrganization] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const formData = useSelector((state) => state.setting.committeeFormData);
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  const currentYear = new Date().getFullYear();
  const electionYear = [];

  Array.apply(null, { length: 11 }).map((e, i) =>
    electionYear.push({ label: `${currentYear - i}`, value: currentYear - i }),
  );

  const committeeOrganisedpac = [
    { label: 'Federal', value: 'federal' },
    { label: 'State', value: 'State' },
    { label: 'County', value: 'County' },
  ];

  const committeeOrganised = [
    { label: 'State', value: 'State' },
    { label: 'County', value: 'County' },
  ];

  useEffect(() => {
    dispatch(getCommitteeFormData());
  }, []);

  const candidateTerm = [
    { label: '2 years', value: 2 },
    { label: '4 years', value: 4 },
    { label: '6 years', value: 6 },
    { label: '8 years', value: 8 },
  ];

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == formData?.state);
      const selectedLevel =
        committeeType === 'political-action'
          ? committeeOrganisedpac?.find((e) => e?.value == formData?.organization_level)
          : committeeOrganised?.find((e) => e?.value == formData?.organization_level);
      const selectedCounty = countyList?.find((e) => e?.value == formData?.county);
      const electionYearValue = electionYear?.find((e) => e?.value == formData?.election_start_year);
      const termOffice = candidateTerm?.find((e) => e?.value == formData?.term_in_office);
      if (formData?.organization_level && formData?.organization_level == 'federal') {
        localStorage.setItem('organization', 'federal');
      } else {
        localStorage.setItem('organization', '');
      }
      setValue('committeeName', formData?.name);
      setValue('boardElectionsID', formData?.boe_id);
      setValue('committeeOrganizationLevel', selectedLevel);
      setOrganization(formData?.organization_level && formData?.organization_level == 'County' ? true : false);
      setFederalOrganization(formData?.organization_level && formData?.organization_level == 'federal' ? true : false);
      //organizationChange(selectedLevel && selectedLevel?.length > 0 && selectedLevel[0].value == 'County' ? setOrganization(true) : setOrganization(false));
      setValue(
        'date_organized_federal',
        formData?.date_organized_federal ? moment(formData?.date_organized_federal, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue('dateOrganized', formData?.date_organized ? moment(formData?.date_organized, 'YYYY-MM-DD HH:mm') : '');
      setValue('fec_id_federal', formData?.fec_id_federal);
      setValue('mailingStreet', formData?.mailing_address);
      setValue('mailingCity', formData?.city);
      setValue('mailingZip', formData?.post_code);
      setValue('election_start_year', electionYearValue);
      setValue('term_in_office', termOffice);
      //setValue('phoneNumber', formData?.phone);
      setValue('email', formData?.email);
      setValue('website', formData?.website);
      setValue('mailingcountryList', selectedState ? selectedState : { label: 'North Carolina', value: 'NC' });
      setPhoneNumber(formData?.phone);
      setTimeout(() => {
        setValue('countryOrganization', selectedCounty);
        setLoading(false);
      }, 1200);
    }
  }, [formData]);

  const onFinish = (values) => {
    setLoading(true);
    const dateOrg = values?.dateOrganized && moment(values?.dateOrganized).format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('organisedReport', JSON.stringify(dateOrg));
    if (values?.committeeOrganizationLevel?.value === 'federal') {
      localStorage.setItem('organization', 'federal');
    } else {
      localStorage.setItem('organization', '');
    }
    let requestBody = {
      committeeName: values?.committeeName,
      boardElectionsID: values?.boardElectionsID,
      committeeOrganizationLevel: values?.committeeOrganizationLevel?.value,
      fec_id_federal: values?.fec_id_federal ?? undefined,
      date_organized_federal: values?.date_organized_federal
        ? values?.date_organized_federal.format('YYYY-MM-DD HH:mm:ss') + 'Z'
        : undefined,
      dateOrganized: values?.dateOrganized.format('YYYY-MM-DD HH:mm:ss') + 'Z',
      mailingStreet: values?.mailingStreet,
      mailingcountryList: values?.mailingcountryList?.value,
      mailingCity: values?.mailingCity,
      mailingZip: values?.mailingZip,
      phoneNumber: phoneNumber,
      website: values?.website,
      countryOrganization: organization ? values?.countryOrganization?.value : '',
    };

    if (committeeType == 'candidate') {
      requestBody.election_start_year = parseInt(values?.election_start_year?.value);
      requestBody.term_in_office = parseInt(values?.term_in_office?.value);
    } else {
      requestBody.election_start_year = 2023;
      requestBody.term_in_office = 2;
    }

    addObject(SAVE_COMMITTEE, requestBody)
      .then((response) => {
        setLoading(false);
        message.success('Information Updated.');
        setCurrentStep(currentStep + 1);
        dispatch(getCommitteeFormData());
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const organizationChange = (e) => {
    if (e?.value == 'County') {
      setOrganization(true);
    }
    if (e?.value == 'State') {
      setOrganization(false);
    }
    if (e?.value == 'federal') {
      setFederalOrganization(true);
    } else {
      setFederalOrganization(false);
    }
  };

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData)?.length > 0) {
      setValue('mailingCity', formData?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  useEffect(() => {
    setValue('mailingcountryList', {
      label: 'North Carolina',
      value: 'NC',
    });
  }, []);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="committeeName"
              label="Committee Name"
              control={control}
              initValue=""
              hintMessage="Registered name that will be shown in your reports"
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Committee Name is required.',
              }}
              validate={errors.committeeName && 'error'}
              validMessage={errors.committeeName && errors.committeeName.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              fieldname="boardElectionsID"
              label="North Carolina Board of Elections ID"
              hintMessage="Ex.: STA-123456-C-001. Leave empty if you haven't gotten it yet."
              control={control}
              initValue=""
              tooltip={true}
              tooltipTitle={
                <div className="tooltip_text">
                  Committee receives it after filing the Statement of Organization. You can{' '}
                  <a href="https://cf.ncsbe.gov/CFOrgLkup/" target="_blank">
                    search for your ID here.
                  </a>
                </div>
              }
              iProps={{
                size: 'large',
              }}
            />
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="committeeOrganizationLevel"
              label="Committee Organization Level"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Committee Organization Level is required.' }}
              initValue=""
              selectOption={committeeType === 'political-action' ? committeeOrganisedpac : committeeOrganised}
              onChange={(e) => organizationChange(e)}
              validate={errors.committeeOrganizationLevel && 'error'}
              validMessage={errors.committeeOrganizationLevel && errors.committeeOrganizationLevel.message}
            />
          </Col>

          {federalOrganization && (
            <>
              <Col span={12}>
                <InputField
                  fieldname="fec_id_federal"
                  label="FEC ID Number"
                  control={control}
                  initValue=""
                  isRequired={true}
                  rules={{
                    required: 'FEC ID Number is required.',
                    pattern: {
                      value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,9}$/,
                      message: 'FEC ID Number must be a 9-character combination of numbers and letters.',
                    },
                  }}
                  validate={errors.fec_id_federal && 'error'}
                  validMessage={errors.fec_id_federal && errors.fec_id_federal.message}
                />
              </Col>
              <Col span={12}>
                <DateField
                  fieldname="date_organized_federal"
                  label="Date organized"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large' }}
                  initValue=""
                  tooltip={true}
                  tooltipTitle={
                    <div className="tooltip_text">The date when your committee was registered with the FEC.</div>
                  }
                  isRequired={true}
                  rules={{
                    required: 'Date organized is required.',
                  }}
                  validate={errors.date_organized_federal && 'error'}
                  validMessage={errors.date_organized_federal && errors.date_organized_federal.message}
                />
              </Col>
            </>
          )}

          {organization && (
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="countryOrganization"
                label="County"
                control={control}
                selectOption={countyList}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'County is required.',
                }}
                validate={errors.countryOrganization && 'error'}
                validMessage={errors.countryOrganization && errors.countryOrganization.message}
              />
            </Col>
          )}

          <Col span={24}>
            <DateField
              fieldname="dateOrganized"
              label={federalOrganization ? 'Date Organized in North Carolina' : 'Date organized'}
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              tooltip={true}
              tooltipTitle={
                <div className="tooltip_text">
                  The date when your committee was registered with NC state board of elections.
                </div>
              }
              rules={{
                required: 'Date organized is required.',
              }}
              validate={errors.dateOrganized && 'error'}
              validMessage={errors.dateOrganized && errors.dateOrganized.message}
            />
          </Col>

          {committeeType == 'candidate' ? (
            <>
              <Col span={12}>
                <SelectField
                  fieldname="election_start_year"
                  label="Election Cycle Start Year"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue=""
                  tooltip={true}
                  tooltipTitle="When your current election cycle has started. Based on this and the term in the office, we will define the election year."
                  selectOption={electionYear}
                  isRequired={true}
                  rules={{
                    required: 'Election Cycle Start Year is required.',
                  }}
                  validate={errors.election_start_year && 'error'}
                  validMessage={errors.election_start_year && errors.election_start_year.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  fieldname="term_in_office"
                  label="Candidate's Term in the Office "
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue=""
                  tooltip={true}
                  tooltipTitle="When your current election cycle has started. Based on this and the term in the office, we will define the election year."
                  selectOption={candidateTerm}
                  isRequired={true}
                  rules={{
                    required: "Candidate's Term is required.",
                  }}
                  validate={errors.term_in_office && 'error'}
                  validMessage={errors.term_in_office && errors.term_in_office.message}
                />
              </Col>
            </>
          ) : null}

          <Col span={24} className="mailingField">
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="mailingStreet"
                  label="Mailing Address"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Street Address',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.mailingStreet && 'error'}
                  validMessage={errors.mailingStreet && errors.mailingStreet.message}
                />
              </Col>

              <Col span={9}>
                <SelectField
                  fieldname="mailingcountryList"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue={{}}
                  selectOption={countryList}
                  onChange={(e) => selectedCountry(e)}
                />
              </Col>

              <Col span={9}>
                <InputField
                  isRequired={true}
                  fieldname="mailingCity"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'City',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.mailingCity && 'error'}
                  validMessage={errors.mailingCity && errors.mailingCity.message}
                />
              </Col>

              <Col span={6}>
                <InputField
                  isRequired={true}
                  fieldname="mailingZip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Zip',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.mailingZip && 'error'}
                  validMessage={errors.mailingZip && errors.mailingZip.message}
                />
              </Col>
              {(errors.mailingZip || errors.mailingCity || errors.mailingStreet) && (
                <Col span={24} className="mailingError">
                  Mailing Address is required.
                </Col>
              )}
            </Row>
          </Col>

          <Col span={24}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Phone Number">
                  Phone Number
                </label>
                <div className="optional-label" style={{ top: '4px' }}>
                  (Optional)
                </div>
              </div>
            </div>
            <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
              {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
            </InputMask>
          </Col>

          <Col span={24}>
            <InputField
              label="Website"
              fieldname="website"
              optionalLabel="(Optional)"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
            />
          </Col>

          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
