import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';

export const getAdminData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getAdminData`);
    dispatch({
      type: action_types.ADMIN_DATA,
      data: data,
    });
  };
};