import React from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import crossIcon from '../../../../assets/svg/cross-icon.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const {onClose, title} = props;
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title level={4} className="mb-0">
          {title}
        </Title>
      </Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>
      <Col span={24}>
        <Paragraph className="w-100 c-l-blue mb-1">
          Please don't hesitate to contact us if you've faced any problems using our platform or just wanted to make a suggestion. <br /><br />We are open to hearing from you!
        </Paragraph>
      </Col>
      <Col span={24}>
        <Title level={3} className="w-100 c-l-blue text-center f-bold mb-0">support@electafile.com</Title>
      </Col>
    </Row>
  );
};
