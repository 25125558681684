import React from 'react';
import { Space, Button, Row, Col, Typography, Image } from 'antd';
import crossIcon from '../../../assets/svg/cross-icon.svg';

const { Title, Paragraph, Text } = Typography;

export default (props) => {
  const { onClose, title, updateApi, paragraph, PpopUpNotDeleted } = props;
  return (
    <Row justify="space-between" align="middle" gutter={[20, 20]}>
      <Col>
        <Title level={4} className="mb-0">
          {title}
        </Title>
      </Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>

      <Col span={24}>
        <Paragraph>{paragraph}</Paragraph>
      </Col>
      <Col span={24}>
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy white-btn" size="large" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={updateApi}>
              Discard Import
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
