import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../ducks/actions';
import { getContactFormData, getContactFormDataPayee } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, setLoading } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [contactBoxEditCaseReimbursee, setContactBoxEditCaseReimbursee] = useState('');
  const [contactToSelectReimbursee, setContactToSelectReimbursee] = useState(null);

  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  let contributerData = useSelector((state) => state.contact.contactFormData);
  let contributerDataReimbursee = useSelector((state) => state.contact.contactFormDataPayee);

  useEffect(() => {
    dispatch(getReceiptDropdownData('forgiven_loan', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const forgivenID = receiptFormData?.contributors?.find((e) => e?.value == formData?.receiptData?.loan_id);
      setValue('loan_forgiven', forgivenID);
      setValue('amount', formData.receiptData?.amount);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');
      setValue('comments', formData.receiptData?.comments);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [receiptFormData, formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const changeValueByDropDownReimbursee = (e) => {
    dispatch(getContactFormDataPayee(e));
  };

  const updateDropdownValues = () => {
    dispatch(getReceiptDropdownData('forgiven_loan', 'null'));
  };

  const forgivenLoan = [
    { label: "$1,000.00 from Franklyn's Fund", value: "$1,000.00 from Franklyn's Fund" },
    { label: '$25,000 from Company Abcz', value: '$25,000 from Company Abcz' },
  ];

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={24}>
          <Paragraph className="c-blue">
            Use this from to report a loan that was forgiven. Only loans that are not owed to a lending institution and
            do not cause <br /> the lender to exceed the legal contribution amount can be forgiven.
          </Paragraph>
        </Col>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="loan_forgiven"
                label="Loan That Was Forgiven"
                control={control}
                class="mb-0 rightSublevel"
                iProps={{ placeholder: 'Please select' }}
                rules={{ required: 'Loan That Was Forgiven is required.' }}
                initValue=""
                selectOption={receiptFormData?.contributors}
                validate={errors.loan_forgiven && 'error'}
                validMessage={errors.loan_forgiven && errors.loan_forgiven.message}
              />
            </Col>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount Forgiven"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Amount Forgiven is required',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={24}>
              <DateField
                fieldname="date"
                label="Date When Loan Was Forgiven"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Date When Loan Was Forgiven is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextAreaField
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
