import * as action_types from './constants';

const initialState = {
  expenditureFormData: {},
  expenditureDropData: {},
  expenditureListData: [],
  exemptListData: [],
  exemptSingleData: {},
  bankAccountsList: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.EXPENDITURE_FORM_DATA:
      return { ...state, expenditureFormData: data };

    case action_types.EXPENDITURE_DROP_DATA:
      return { ...state, expenditureDropData: data };

    case action_types.EXPENDITURE_LIST_DATA:
      return { ...state, expenditureListData: data };
      
    case action_types.EXEMPT_LIST_DATA:
      return { ...state, exemptListData: data };

    case action_types.BANK_ACCOUNTS_LIST:
      return { ...state, bankAccountsList: data };

    case action_types.EXEMPT_PLAN_SINGLE:
      return { ...state, exemptSingleData: data };

    default:
      return state;
  }
};
