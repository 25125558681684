import React from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import crossIcon from '../../../assets/svg/cross-icon.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const { onClose, title, data } = props;
  return (
    <Row justify="space-between" align="middle" gutter={[25, 25]}>
      <Col>
        <Title level={4} className="mb-0">
          {title}
        </Title>
      </Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>
      <Col span={24}>
        <Paragraph className="w-100 f-bold mb-1">Please note the following entries and correct them later on</Paragraph>
        {data &&
          data?.map((e) => <Paragraph className="w-100 c-red mb-1 text-capitalize">{e?.replace(/_/g, ' ')}</Paragraph>)}
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Button type="secondary" htmlType="submit" className="font-gilroy" size="large" onClick={onClose}>
          Got it
        </Button>
      </Col>
    </Row>
  );
};
