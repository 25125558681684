import React, { useState } from 'react';
import { Card, Spin, Button, Row, Col, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from '../../../../../../atoms/Popup';
import Administrative from './OtherPopup/Administrative';
import DebtsObligationsOwedBy from './OtherPopup/DebtsObligationsOwedBy';
import DebtsObligationsOwedTo from './OtherPopup/DebtsObligationsOwedTo';

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {
  const { currentStep, setCurrentStep, reportId } = props;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAdministrative, setVisibleAdministrative] = useState(false);
  const [debtsOwedTo, setDebtsOwedTo] = useState(false);
  const [debtsOwedBy, setDebtsOwedBy] = useState(false);

  //console.log('reportId', reportId);

  const moveToNextStep = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    setTimeout(() => setCurrentStep(currentStep + 1), 1200);
  };

  const updateAPI = () => {
    setDebtsOwedTo(false);
    setVisibleAdministrative(false);
    setDebtsOwedBy(false);
  };

  const popupAdministrative = {
    closable: false,
    visibility: visibleAdministrative,
    class: 'black-modal',
    content: (
      <Administrative
        title="Administrative Support"
        onClose={() => setVisibleAdministrative(false)}
        onDelete={() => onDelete()}
        reportID={reportId}
        updateAPI={updateAPI}
        setVisibleAdministrative={setVisibleAdministrative}
        visibleAdministrative={visibleAdministrative}
      />
    ),
    width: 560,
    onCancel: () => setVisibleAdministrative(false),
  };

  const popupDebtsOwedTo = {
    closable: false,
    visibility: debtsOwedTo,
    class: 'black-modal',
    content: (
      <DebtsObligationsOwedTo
        title="Debts and Obligations Owed To the Committee"
        onClose={() => setDebtsOwedTo(false)}
        onDelete={() => onDelete()}
        reportID={reportId}
        updateAPI={updateAPI}
        setDebtsOwedTo={setDebtsOwedTo}
        debtsOwedTo={debtsOwedTo}
      />
    ),
    width: 560,
    onCancel: () => setDebtsOwedTo(false),
  };

  const popupDebtsOwedBy = {
    closable: false,
    visibility: debtsOwedBy,
    class: 'black-modal',
    content: (
      <DebtsObligationsOwedBy
        title="Debts and Obligations Owed By the Committee"
        onClose={() => setDebtsOwedBy(false)}
        onDelete={() => onDelete()}
        reportID={reportId}
        setDebtsOwedBy={setDebtsOwedBy}
        debtsOwedBy={debtsOwedBy}
      />
    ),
    width: 560,
    onCancel: () => setDebtsOwedBy(false),
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="center">
        <Col flex="0 0 462px">
          <Row className="text-center" gutter={[8, 8]}>
            <Col span={24}>
              <Title level={4} className="mb-1 font-14 font-manrope">
                Complete the following forms if applicable
              </Title>
            </Col>
            {/* <Col span={24}>
              <Card className="sm-grey-card link-card" onClick={() => setDebtsOwedBy(true)}>
                <Text>Debts and Obligations Owed by the Committee</Text>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="sm-grey-card link-card" onClick={() => setDebtsOwedTo(true)}>
                <Text>Debts and Obligations Owed to the Committee</Text>
              </Card>
            </Col> */}
            <Col span={24}>
              <Card className="sm-grey-card link-card" onClick={() => setVisibleAdministrative(true)}>
                <Text>Administrative Support</Text>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle" className="mt-2" justify="center" gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => setCurrentStep(currentStep - 1)}
            htmlType="button"
            className="font-gilroy white-btn"
            size="large"
          >
            Back
          </Button>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => moveToNextStep()}
            htmlType="button"
            className="font-gilroy"
            size="large"
          >
            Continue
          </Button>
        </Col>
      </Row>
      <Popup {...popupAdministrative} />
      <Popup {...popupDebtsOwedBy} />
      <Popup {...popupDebtsOwedTo} />
    </Spin>
  );
};
