export const RECEIPT_FORM_DATA = 'RECEIPT_FORM_DATA';
export const RECEIPT_DROP_DATA = 'RECEIPT_DROP_DATA';
export const RECEIPT_LIST_DATA = 'RECEIPT_LIST_DATA';
export const BANK_ACCOUNTS_LIST = 'BANK_ACCOUNTS_LIST';
export const GET_EXEMPT_PLAN = 'GET_EXEMPT_PLAN';
export const EDIT_EXEMPT_PLAN = 'EDIT_EXEMPT_PLAN';
export const SET_FILTERS_OPTIONS = 'SET_FILTERS_OPTIONS';
export const CLEAR_RECEIPT_FILTER = 'CLEAR_RECEIPT_FILTER';
export const RECEIPT_UNIMPORTED_LIST_DATA = 'RECEIPT_UNIMPORTED_LIST_DATA';
export const UNIMPORT_RECEIPT_FORM_DATA = 'UNIMPORT_RECEIPT_FORM_DATA';
export const SET_FILTERS_OPTIONS_IMPORT = 'SET_FILTERS_OPTIONS_IMPORT';
