import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getExpenditureFormData } from '../../ducks/actions';
import { getContactFormData } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const expenditureFormData = useSelector((state) => state.expenditure.expenditureFormData);
  const contributerData = useSelector((state) => state.contact.contactFormData);

  const typeOfGiftList = [
    {
      label: 'Coordinated Party Expenditure',
      value: 'coordinated_party_expenditure',
    },
    {
      label: 'Contribution to Candidate/Political Committee',
      value: 'contribution_to_candidate/political_committee',
    },
  ];

  useEffect(() => {
    dispatch(getExpenditureFormData('general_contribution', 'null'));
  }, []);

  useEffect(() => {
    if (
      expenditureFormData?.expenditureData &&
      Object.keys(expenditureFormData)?.length > 0 &&
      Object.keys(expenditureFormData?.expenditureData)?.length > 0
    ) {
      setLoading(true);
      const selectedcontributorList = expenditureFormData?.contributors?.find(
        (e) => e?.value == (contactToSelect ? contactToSelect : expenditureFormData?.expenditureData?.payee_id),
      );
      const selectedtype_of_gift = typeOfGiftList.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.type_of_gift,
      );
      setValue('amount', expenditureFormData.expenditureData?.amount);
      setValue(
        'date',
        expenditureFormData?.expenditureData?.date
          ? moment(expenditureFormData?.expenditureData?.date, 'YYYY-MM-DD HH:mm')
          : '',
      );
      setValue('comments', expenditureFormData.expenditureData?.comments);
      setValue('payment_type', expenditureFormData.expenditureData?.payment_type);
      setValue('payee_id', selectedcontributorList);
      setValue('type_of_gift', selectedtype_of_gift);
      setValue('gift_description', expenditureFormData.expenditureData?.gift_description);
      setValue('remarks', expenditureFormData.expenditureData?.remarks);
      setValue('comments', expenditureFormData.expenditureData?.comments);
      setContactToSelect(null);
      setTimeout(() => {
        setContactBoxEditCase(selectedcontributorList);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [expenditureFormData, formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const updateDropdownValues = () => {
    dispatch(getExpenditureFormData('general_contribution', 'null'));
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Fair Market Amount"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Fair Market Amount is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          {/* <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="payee_id"
              label="Payee"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
              }}
              rules={{
                required: 'Payee is required.',
              }}
              selectOption={expenditureFormData?.contributors}
              validate={errors.payee_id && 'error'}
              validMessage={errors.payee_id && errors.payee_id.message}
            />
          </Col> */}

          <ContactBox
            control={control}
            errors={errors}
            selectData={expenditureFormData?.contributors}
            changeValueByDropDown={changeValueByDropDown}
            updateDropdownValues={updateDropdownValues}
            contributerData={contributerData}
            contactBoxEditCase={contactBoxEditCase}
            contactToSelect={contactToSelect}
            setContactToSelect={setContactToSelect}
            setValue={setValue}
            fieldname="payee_id"
            label="Payee"
          />

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="type_of_gift"
              label="Type of Gift"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
              }}
              rules={{
                required: 'Type of Gift is required.',
              }}
              selectOption={typeOfGiftList}
              validate={errors.type_of_gift && 'error'}
              validMessage={errors.type_of_gift && errors.type_of_gift.message}
            />
          </Col>

          <Col span={24}>
            <TextAreaField
              isRequired={true}
              fieldname="gift_description"
              label="Gift Description"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Gift Description is required.',
              }}
              validate={errors.gift_description && 'error'}
              validMessage={errors.gift_description && errors.gift_description.message}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Col span={24}>
              <TextAreaField
                isRequired={false}
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
                validate={errors.comments && 'error'}
                validMessage={errors.comments && errors.comments.message}
              />
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
