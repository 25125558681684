import React, { useState } from 'react';
import { Steps, Card, Row, Col, Tabs } from 'antd';
import Dashboard from '../../../pages/Dashboard';
import * as TabCards from './StepsForm';
import { useLocation } from 'react-router';
const { Step } = Steps;
const { TabPane } = Tabs;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  const location = useLocation();
  const activeTab = location?.state?.settingTab ? location?.state?.settingTab : '1';
  //console.log('location?.state?.settingTab', activeTab);

  let tabs = [];

  {
    committeeType == 'candidate' &&
      (tabs = [
        {
          name: 'Committee',
          Comp: 'Committee',
        },
        {
          name: 'Candidate',
          Comp: 'Candidate',
        },
        {
          name: 'Officers',
          Comp: 'Officers',
        },
        {
          name: 'Bank Accounts',
          Comp: 'Accounts',
        },
        {
          name: 'User Account',
          Comp: 'UserAccount',
        },
      ]);

    committeeType == 'independent-expenditure' &&
      (tabs = [
        {
          name: 'Committee',
          Comp: 'Committee',
        },
        {
          name: 'Officers',
          Comp: 'Officers',
        },
        {
          name: 'Bank Accounts',
          Comp: 'Accounts',
        },
        {
          name: 'User Account',
          Comp: 'UserAccount',
        },
      ]);

    committeeType == 'party' &&
      (tabs = [
        {
          name: 'Committee',
          Comp: 'Committee',
        },
        {
          name: 'Party',
          Comp: 'Party',
        },
        {
          name: 'Officers',
          Comp: 'Officers',
        },
        {
          name: 'Bank Accounts',
          Comp: 'Accounts',
        },
        {
          name: 'User Account',
          Comp: 'UserAccount',
        },
      ]);

    committeeType == 'political-action' &&
      (tabs = [
        {
          name: 'Committee',
          Comp: 'Committee',
        },
        {
          name: 'Details',
          Comp: 'Details',
        },
        {
          name: 'Officers',
          Comp: 'Officers',
        },
        {
          name: 'Bank Accounts',
          Comp: 'Accounts',
        },
        {
          name: 'User Account',
          Comp: 'UserAccount',
        },
      ]);
  }

  return (
    <Dashboard load={loading}>
      <Card className="white-card w-100 vertical-tabs">
        <Tabs defaultActiveKey={activeTab} tabPosition="left">
          {tabs.map((item, index) => {
            const Cardi = TabCards[item.Comp];
            return (
              <TabPane tab={item.name} key={index + 1} forceRender={true}>
                <Cardi {...props} />
              </TabPane>
            );
          })}
        </Tabs>
      </Card>
    </Dashboard>
  );
};
