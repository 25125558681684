import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';
import {
  GET_COMMITTEE_BY_ID,
  GET_CANDIDATE_BY_ID,
  GET_OFFICERS,
  GET_ACCOUNTS,
  GET_PAC_INFO,
} from '../../../../../configs/endpoints';

export const getCommitteeListingData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getCommitteesList`);
    dispatch({
      type: action_types.COMMITTEE_FORM_DATA,
      data: data,
    });
  };
};

export const getCommitteeFormData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_COMMITTEE_BY_ID}`);
    dispatch({
      type: action_types.COMMITTEE_FORM_DATA,
      data: data,
    });
  };
};

export const getPACFormData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_PAC_INFO}`);
    dispatch({
      type: action_types.COMMITTEE_FORM_DATA,
      data: data,
    });
  };
};

export const getCandidateFormData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_CANDIDATE_BY_ID}`);
    dispatch({
      type: action_types.CANDIDATE_FORM_DATA,
      data: data,
    });
  };
};

export const getOfficersFormData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_OFFICERS}`);
    dispatch({
      type: action_types.OFFICER_FORM_DATA,
      data: data,
    });
  };
};

export const getAccountInfoFormData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_ACCOUNTS}`);
    dispatch({
      type: action_types.ACCOUNT_FORM_DATA,
      data: data,
    });
  };
};

export const getBankAccounts = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/bankaccounts`);
    dispatch({
      type: action_types.BANK_ACCOUNTS_LIST,
      data: data,
    });
  };
};

export const getFinancialInstitutes = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getFinancialInstitutes`);
    dispatch({
      type: action_types.FINANCIAL_INSTITUTES,
      data: data,
    });
  };
};

export const getLogsByCommitteeId = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getLogsByCommitteeId`);
    dispatch({
      type: action_types.LOGS_BY_COMMITTEE_ID,
      data: data,
    });
  };
};


export const clearData = () => ({
  type: action_types.CLEAR_DATA,
})