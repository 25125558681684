import React, { useState } from 'react';
import { Row, Col, Card, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import Dashboard from '../../../../../pages/Dashboard';
import infoIcon from '../../../../../../assets/svg/info-disabled.svg';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const federalPAC = localStorage.getItem('organization');

  return (
    <Dashboard load={loading} heading="New Expenditure" backLink="/expenditures">
      <Card className="white-card w-100 pcard-0">
        <Row justify="center">
          <Col flex="0 0 462px">
            <Row className="text-center" gutter={[8, 8]}>
              <Col span={24}>
                <Title level={3}>Expenditure Type</Title>
              </Col>

              {federalPAC !== 'federal' && (
                <Col span={24}>
                  <Link className="receipt-card" to="/new-expenditure/operating_expense">
                    <Card>
                      <Text>Operating Expense</Text>
                    </Card>
                  </Link>
                </Col>
              )}
              <Col span={24}>
                <Link className="receipt-card" to="/new-expenditure/contribution_to_candidate_or_political_committee">
                  <Card>
                    <Text>Contribution to Candidate/Political Committee</Text>
                  </Card>
                </Link>
              </Col>
              <Col span={24}>
                <Link className="receipt-card" to="/new-expenditure/gift">
                  <Card>
                    <Text>Non-Monetary Gift</Text>
                  </Card>
                </Link>
              </Col>
              {federalPAC === 'federal' && (
                <Col span={24}>
                  <Tooltip
                    title={
                      <>
                        Reporting this type of expenditure is not currently supported (forms CRO 2210-A, 2210-B,
                        2210-C). We recommend downloading necessary forms from the NC State Board of Elections website
                        and submit them manually. <br />
                        <a
                          href="https://www.ncsbe.gov/campaign-finance/independent-expenditures-electioneering-communications-and-special-contributors"
                          target="_blank"
                          style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 500 }}
                        >
                          Learn more about independent filers.
                        </a>
                      </>
                    }
                  >
                    <Card className="disabled_card receipt-card">
                      <Text>Independent Expenditure</Text>
                      <img src={infoIcon} />
                    </Card>
                  </Tooltip>
                </Col>
              )}
              {federalPAC !== 'federal' && (
                <>
                  <Col span={24}>
                    <Link className="receipt-card" to="/new-expenditure/coordinated_party_expenditure">
                      <Card>
                        <Text>Coordinated Party Expenditure</Text>
                      </Card>
                    </Link>
                  </Col>

                  <Col span={24}>
                    <Link className="receipt-card" to="/new-expenditure/loan_repayment">
                      <Card>
                        <Text>Loan Repayment</Text>
                      </Card>
                    </Link>
                  </Col>

                  <Col span={24}>
                    <Link className="receipt-card" to="/new-expenditure/debt_repayment">
                      <Card>
                        <Text>Debt Repayment</Text>
                      </Card>
                    </Link>
                  </Col>

                  <Col span={24}>
                    <Link className="receipt-card" to="/new-expenditure/refund">
                      <Card>
                        <Text>Refund/Reimbursement from the Committee</Text>
                      </Card>
                    </Link>
                  </Col>

                  <Col span={24}>
                    <Link className="receipt-card" to="/new-expenditure/independent_expenditure">
                      <Card>
                        <Text>Independent Expenditure</Text>
                      </Card>
                    </Link>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
    </Dashboard>
  );
};
