import React from 'react';
import { Card, Slider, InputNumber, Button } from 'antd';
import { useDetectClickOutside } from 'react-detect-click-outside';

const Dropdown = (props) => {
  const {
    closeDropdown,
    highestAmount,
    clearAmount,
    rangeValue,
    inputValue,
    amountStart,
    amountEnd,
    applyRange,
    setInputValue,
    setAmountRange,
    setRangeBox,
  } = props;
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });
  return (
    <div className="range_card" ref={ref}>
      <Card>
        <div className="range_head">
          <div className="r_heading">Select Sum Range</div>
          {highestAmount !== 0 && (
            <div className="clear_range" onClick={clearAmount}>
              CLEAR
            </div>
          )}
        </div>
        {highestAmount === 0 && (
          <p style={{ fontSize: '12px', color: 'rgba(86, 97, 120, 1)' }}>
            There’s nothing to filter, as all range values are “0”.
          </p>
        )}
        <div className="range_slider">
          <Slider
            range
            min={0}
            max={highestAmount}
            onChange={rangeValue}
            value={inputValue}
            tipFormatter={(value) => `${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </div>
        <div className="range_input">
          <div className="input_50">
            <InputNumber
              addonBefore="$"
              min={0}
              max={inputValue[1]}
              style={{ margin: '0 16px' }}
              value={inputValue[0]}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={amountStart}
              disabled={highestAmount === 0}
              controls={false}
            />
          </div>
          <div className="input_divider">-</div>
          <div className="input_50">
            <InputNumber
              addonBefore="$"
              min={inputValue[0]}
              max={highestAmount}
              style={{ margin: '0 16px' }}
              value={inputValue[1]}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={amountEnd}
              disabled={highestAmount === 0}
              controls={false}
            />
          </div>
        </div>
        <div className="range_btn">
          <div className="btn_50">
            <Button
              onClick={() => {
                setRangeBox(false);
                setAmountRange([]);
                setInputValue([0, highestAmount]);
              }}
              className="white-btn"
            >
              {highestAmount === 0 ? 'Close' : 'Cancel'}
            </Button>
          </div>
          <div className="btn_50">
            <Button type="secondary" onClick={applyRange} disabled={highestAmount === 0}>
              Apply
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default Dropdown;
