import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Row } from 'antd';
import Logo from '../../../../assets/svg/new-logo.svg';

export default (props) => {
  return (
    <div className="header">
      <div className="container">
        <Image width={150} src={Logo} preview={false} />
      </div>
    </div>
  );
};
