import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Image, message, Space } from 'antd';
import Dashboard from '../../../../pages/Dashboard';
import organizationalReport from '../../../../../assets/svg/organizational-report.svg';
import infoIcon from '../../../../../assets/svg/info-icon.svg';
import FiledExternal from '../../../../molecules/FiledExternal';
import { Popup } from '../../../../atoms/Popup';
import { addObject, downloadFile, saveFile } from '../../../../../services/crudApis';
import { FILED_ORG_EXTERNALLY } from '../../../../../configs/endpoints';
import moment from 'moment';
import { DownloadIcon } from '../../../../atoms/CustomIcons';
import { baseUrl } from '../../../../../configs/constants';
const { Title, Text, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem('user')).date_organized;
  const mailingAddress = JSON.parse(localStorage.getItem('mailingAddress'));
  const electionYear = user && moment(user).year();

  const submitFileExternal = (values) => {
    setLoading(true);
    let payload = [];
    if (values && values.external_filed && values.external_filed.length > 0) {
      payload = values.external_filed.map((resp) => ({
        id: resp?.id,
        balance: resp.balance,
      }));
    }

    let requestBody = {
      is_filed_externally: true,
      report_end_date: moment(values?.report_end_date).format('MM-DD-YYYY'),
      account: payload,
    };

    addObject(FILED_ORG_EXTERNALLY, requestBody)
      .then((response) => {
        setLoading(false);
        history.push('/dashboard');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  const popup = {
    closable: false,
    visibility: visible,
    class: 'black-modal',
    content: (
      <FiledExternal
        title="Filed Externally"
        onClose={() => setVisible(false)}
        onDelete={() => onDelete()}
        submitFileExternal={submitFileExternal}
        popHeading="ORG"
      />
    ),
    width: 450,
    onCancel: () => setVisible(false),
  };

  const downloadStatementOrg = () => {
    try {
      setLoading(true);
      downloadFile(`${baseUrl}/getStatementOfOrg`)
        .then((response) => {
          saveFile(response, `Electafile - Statement of organization - ${new Date().toISOString()}.pdf`);
          setLoading(false);
        })
        .catch((error) => {
          message.error('Something went wrong');
          setLoading(false);
        });
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  const financialCertificate = () => {
    try {
      setLoading(true);
      downloadFile(`${baseUrl}/getCertificatePDF`)
        .then((response) => {
          saveFile(
            response,
            `Electafile - Certification of Financial Account information - ${new Date().toISOString()}.pdf`,
          );
          setLoading(false);
        })
        .catch((error) => {
          message.error('Something went wrong');
          setLoading(false);
        });
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  return (
    <>
      <Dashboard load={loading} noLink={true}>
        <Row align="middle" justify="center">
          <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
            <Card className="white-card text-center">
              <Row justify="center" gutter={[20, 25]}>
                <Col span={24}>
                  <Title level={2}>Information for Newly Created Committees</Title>
                </Col>
                <Col span={24}>
                  <Card className="info-card">
                    <Space size={20} align="start">
                      <Image src={infoIcon} width={30} preview={false} />
                      <div className="info_text">
                        <Paragraph className="smallFont14 w-100 m-0">
                          You must sign and mail the Statement of Organization and Certification of Financial Account
                          Information to your Board of Elections.
                          <br />
                          <br />
                          <b>Mailing address:</b>
                          <br />
                          {mailingAddress?.line1}
                          <br />
                          {mailingAddress?.line2}
                          <br />
                          {mailingAddress?.line3}
                        </Paragraph>
                      </div>
                    </Space>
                  </Card>
                </Col>
                <Col span={24}>
                  <Text>
                    Click the buttons below to download the completed forms.
                    <br />
                    You can download them later in Settings.
                  </Text>
                </Col>
                <Col flex="0 0 456px">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy white-btn setting_btn w-100"
                    onClick={downloadStatementOrg}
                    icon={<DownloadIcon />}
                  >
                    Statement of organization
                  </Button>
                </Col>
                <Col flex="0 0 456px">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy white-btn setting_btn w-100"
                    onClick={financialCertificate}
                    icon={<DownloadIcon />}
                  >
                    Certification of Financial Account information
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-uppercase"
                    size="large"
                    onClick={() => history.push('/file-organizational-report')}
                  >
                    Continue
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey"
                    onClick={() => history.push('/add-transactions')}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Dashboard>
      <Popup {...popup} />
    </>
  );
};
