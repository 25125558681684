import React, { useEffect } from 'react';
import {
  DatePicker,
  Button,
  Form,
  Input,
  InputNumber,
  Checkbox,
  TimePicker,
  Upload,
  Switch,
  Radio,
  Slider,
  Row,
  Col,
  Image,
  Typography,
} from 'antd';
import { PlusCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import moment from 'moment';

const { Dragger } = Upload;
const { Paragraph } = Typography;

export const InputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    readOnly,
    hintMessage,
    tooltip,
    tooltipTitle,
    optionalLabel,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
        tooltip={
          tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
        }
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ''}
          rules={rules}
          render={({ onBlur, value, onChange }) => (
            <Input
              value={value}
              disabled={readOnly}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              onWheel={(e) => e.target.blur()}
              {...iProps}
            />
          )}
        />
        {optionalLabel && <div className="optional-label">(Optional)</div>}
        {hintMessage && <div className="hint-text">{hintMessage}</div>}
      </Form.Item>
    </>
  );
};

export const InputNumberField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    readOnly,
    hintMessage,
    tooltip,
    tooltipTitle,
    optionalLabel,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
        tooltip={
          tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
        }
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ''}
          rules={rules}
          render={({ onBlur, value, onChange }) => (
            <InputNumber
              value={value}
              disabled={readOnly}
              className="inputFormControl"
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              controls={false}
              addonBefore="$"
              precision={1}
              onWheel={(e) => e.target.blur()}
              {...iProps}
            />
          )}
        />
        {optionalLabel && <div className="optional-label">(Optional)</div>}
        {hintMessage && <div className="hint-text">{hintMessage}</div>}
      </Form.Item>
    </>
  );
};

export const InputPassword = (props) => {
  const { fieldname, label, control, iProps, rules, initValue, isRequired, validate, validMessage } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item required={isRequired ? isRequired : false} label={label} validateStatus={validate} help={validMessage}>
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ''}
          rules={rules}
          as={<Input.Password {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const SelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    validMessage,
    readOnly,
    tooltip,
    tooltipTitle,
    valueGot,
    subLabel,
    hintMessage,
    handleKeyDown,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      valueGot && props.setValue(fieldname, valueGot);
    }, 1800);
  }, [valueGot, fieldname]);

  setTimeout(() => {
    valueGot && props.setValue(fieldname, valueGot);
  }, 2200);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props?.data?.label}</div>
        {props?.data?.subLabel && <div style={{ fontSize: 12, color: '#909FBA' }}>{props.data?.subLabel}</div>}
      </components.Option>
    );
  };

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
      className={props.class}
      tooltip={
        tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ''}
        rules={rules}
        render={({ onBlur, value, onChange }) => (
          <Select
            value={value}
            className="customSelect"
            isDisabled={readOnly}
            classNamePrefix={'react-select'}
            components={{ Option }}
            //menuIsOpen={true}
            styles={{
              control: (val) => ({
                ...val,
                minHeight: 50,
                borderWidth: 0,
                backgroundColor: '#eff2f9',
                boxShadow: 'none',
              }),
              valueContainer: (vcontain) => ({
                ...vcontain,
                padding: '5px 15px',
              }),
              singleValue: (provided, state) => ({
                color: '#1B2840',
              }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5, color: '#909FBA' }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                display: 'none',
              }),
              option: (vcontain, state) => ({
                ...vcontain,
                // textTransform: 'capitalize',
                color: '#424C5F',
                border: '0',
                backgroundColor: state.isFocused ? '#f1f1f1' : '#ffffff',
              }),
              multiValue: (styles, { data }) => {
                return {
                  ...styles,
                  backgroundColor: '#0e0e0e',
                  borderRadius: '8px',
                  padding: '0 4px',
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                color: '#fff',
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: '#7c7c7c',
                ':hover': {
                  backgroundColor: '#0e0e0e',
                  color: 'white',
                },
              }),
              placeholder: (place) => ({ ...place, color: 'rgba(0,0,0,.3)' }),
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            onKeyDown={(event) => (handleKeyDown ? handleKeyDown(event) : null)}
            {...iProps}
            options={selectOption}
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: { ...theme.colors },
            })}
          />
        )}
      />
      {hintMessage && <div className="hint-text">{hintMessage}</div>}
    </Form.Item>
  );
};

export const SelectField2 = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    validMessage,
    readOnly,
    tooltip,
    tooltipTitle,
    valueGot,
    subLabel,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      valueGot && props.setValue(fieldname, valueGot);
    }, 1800);
  }, [valueGot, fieldname]);

  setTimeout(() => {
    valueGot && props.setValue(fieldname, valueGot);
  }, 2200);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props?.data?.label}</div>
        {props?.data?.subLabel && <div style={{ fontSize: 12, color: '#909FBA' }}>{props.data?.subLabel}</div>}
      </components.Option>
    );
  };

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
      className={props.class}
      tooltip={
        tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ''}
        rules={rules}
        render={({ onBlur, value, onChange }) => (
          <>
            <Select
              value={value && value?.length && value.map((x) => selectOption.find((y) => y.value == x))}
              className="customSelect"
              isDisabled={readOnly}
              classNamePrefix={'react-select'}
              components={{ Option }}
              //menuIsOpen={true}
              styles={{
                control: (val) => ({
                  ...val,
                  minHeight: 50,
                  borderWidth: 0,
                  backgroundColor: '#eff2f9',
                  boxShadow: 'none',
                }),
                valueContainer: (vcontain) => ({
                  ...vcontain,
                  padding: '5px 15px',
                }),
                singleValue: (provided, state) => ({
                  color: '#1B2840',
                }),
                dropdownIndicator: (icontain) => ({ ...icontain, padding: 5, color: '#909FBA' }),
                indicatorSeparator: (icontain) => ({
                  ...icontain,
                  display: 'none',
                }),
                option: (vcontain, state) => ({
                  ...vcontain,
                  textTransform: 'capitalize',
                  color: '#424C5F',
                  border: '0',
                  backgroundColor: state.isFocused ? '#f1f1f1' : '#ffffff',
                }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: '#0e0e0e',
                    borderRadius: '8px',
                    padding: '0 4px',
                  };
                },
                multiValueLabel: (styles) => ({
                  ...styles,
                  color: '#fff',
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  color: '#7c7c7c',
                  ':hover': {
                    backgroundColor: '#0e0e0e',
                    color: 'white',
                  },
                }),
                placeholder: (place) => ({ ...place, color: 'rgba(0,0,0,.3)' }),
              }}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              {...iProps}
              options={selectOption}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: { ...theme.colors },
              })}
            />
          </>
        )}
      />
    </Form.Item>
  );
};

export const DateField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    hintMessage,
    readOnly,
    tooltip,
    tooltipTitle,
  } = props;

  useEffect(() => {
    props?.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props?.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
        tooltip={
          tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
        }
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ''}
          rules={rules}
          render={({ value, onChange }) => (
            <DatePicker
              style={{ width: '100%' }}
              value={value}
              disabled={readOnly}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              format={[
                'MM-DD-YYYY',
                'MMDDYYYY',
                'DDMMYYYY',
                'MM/DD/YYYY',
                'DD/MM/YYYY',
                'DD-MM-YYYY',
                'MMM D YYYY',
                'M-D-YYYY',
                'M/D/YYYY',
                'D-M-YYYY',
                'D/M/YYYY',
                'MDYYYY',
                'DMYYYY',
              ]}
              {...iProps}
            />
          )}
        />
        {hintMessage && <div className="hint-text">{hintMessage}</div>}
      </Form.Item>
    </>
  );
};

export const DateField2 = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    hintMessage,
    readOnly,
    tooltip,
    tooltipTitle,
  } = props;

  useEffect(() => {
    props?.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props?.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
        tooltip={
          tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
        }
      >
        <Controller
          name={fieldname}
          control={control}
          //defaultValue={initValue ? initValue : ''}
          defaultValue={initValue ? initValue : ''}
          rules={rules}
          render={({ value, onChange }) => (
            <DatePicker
              style={{ width: '100%' }}
              value={value ? moment(value, 'MM-DD-YYYY') : ''}
              disabled={readOnly}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              format={[
                'MM-DD-YYYY',
                'MMDDYYYY',
                'DDMMYYYY',
                'MM/DD/YYYY',
                'DD/MM/YYYY',
                'DD-MM-YYYY',
                'MMM D YYYY',
                'M-D-YYYY',
                'M/D/YYYY',
                'D-M-YYYY',
                'D/M/YYYY',
                'MDYYYY',
                'DMYYYY',
              ]}
              {...iProps}
            />
          )}
        />
        {hintMessage && <div className="hint-text">{hintMessage}</div>}
      </Form.Item>
    </>
  );
};

export const InputCheckbox = (props) => {
  const { fieldname, label, control, rules, initValue, isRequired, validate, validMessage, hintMessage } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ''}
        rules={rules}
        render={({ onBlur, value, onChange }) => (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChange(e.target.checked);
              props.onChange && props.onChange(e.target.checked);
            }}
          >
            {label}
            {hintMessage && <div className="hint-text">{hintMessage}</div>}
          </Checkbox>
        )}
      />
    </Form.Item>
  );
};

export const CheckboxGroup = (props) => {
  const { fieldname, label, option, control, rules, initValue, isRequired, validate, validMessage } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      label={label}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ''}
        render={({ value, onChange }) => (
          <Checkbox.Group
            value={value}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            disabled={props.disabled}
            className="w-100"
            options={option}
          />
        )}
      />
    </Form.Item>
  );
};

export const CustomCheckboxGroup = (props) => {
  const { fieldname, label, option, control, rules, initValue, isRequired, validate, validMessage } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      label={label}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ''}
        render={({ value, onChange }) => (
          // <Checkbox.Group
          //   value={value}
          //   onChange={(e) => {
          //     onChange(e);
          //     props.onChange && props.onChange(e);
          //   }}
          //   disabled={props.disabled}
          //   className="w-100"
          //   options={option}
          // />

          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
          >
            <Row gutter={[12, 12]}>
              {option?.length > 0 &&
                option?.map((item, index) => (
                  <Col span={6} key={index} className="custom_checkbox">
                    <Checkbox value={item?.value}>
                      <Image src={item?.image?.image} preview={false} width={80} />
                      <Paragraph className="smallFont12">{item?.label}</Paragraph>
                      <Paragraph className="smallFont12">{item?.year}</Paragraph>
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        )}
      />
    </Form.Item>
  );
};

export const TimeField = (props) => {
  const { fieldname, label, control, iProps, rules, initValue, isRequired, validate, validMessage } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <>
      <Form.Item required={isRequired ? isRequired : false} label={label} validateStatus={validate} help={validMessage}>
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ''}
          rules={rules}
          as={<TimePicker style={{ width: '100%' }} {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

// export const UploadField = (props) => {
//   const {
//     fieldname,
//     label,
//     control,
//     rules,
//     initValue,
//     isRequired,
//     validate,
//     validMessage,
//     filelist,
//     fileProps,
//     dummyRequest,
//     iProps,
//   } = props;

//   useEffect(() => {
//     props.valueGot && props.setValue(fieldname, props.valueGot);
//   }, []);

//   return (
//     <Form.Item
//       required={isRequired ? isRequired : false}
//       label={label}
//       validateStatus={validate}
//       help={validMessage}
//       className={props.class}
//     >
//       <Controller
//         name={fieldname}
//         control={control}
//         rules={rules}
//         defaultValue={initValue ? initValue : ''}
//         render={({ value, fileList, onChange }) => (
//           <Upload
//             className="uploadWithbtn"
//             showUploadList={false}
//             accept="image/*,.pdf"
//             maxCount={1}
//             fileList={fileList}
//             customRequest={dummyRequest}
//             onChange={(e) => {onChange(e); props.onChange(e)}}
//             {...iProps}
//           >
//             <Input

//               size="large"
//               className={`ag-upload-btn`}
//               value={value ? value.fileList[0].name : 'Please Upload File'}
//               addonAfter={<PlusCircleFilled />}
//             />
//             {value && value?.fileList[0].uid == "-1" && (
//               <Button
//                 type="link"
//                 htmlType="button"
//                 className={`download-inputbtn`}
//                 onClick={() => window.open(value.fileList[0].url)}
//                 icon={<DownloadIcon className="c-success" />}
//               />
//             )}
//           </Upload>
//         )}
//       />
//     </Form.Item>
//   );
// };

export const UploadField = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    draggerProps,
    image,
    content,
    iProps,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ''}
        render={({ value, fileList, onChange }) => (
          <Dragger
            {...draggerProps}
            {...iProps}
            value={fileList}
            itemRender={fileList}
            progress={{
              strokeColor: {
                '0%': '#17356F',
                '100%': '#17356F',
              },
              strokeWidth: 2,
              showInfo: false,
            }}
          >
            <div className="ant-upload-drag-icon">
              <Image src={image} preview={false} width={24} />
            </div>
            <div className="ant-upload-text text-uppercase">{content}</div>
          </Dragger>
        )}
      />
    </Form.Item>
  );
};

export const TextAreaField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    optionalLabel,
    tooltip,
    tooltipTitle,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.class}
      tooltip={
        tooltip && { title: tooltipTitle, icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} /> }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ''}
        rules={rules}
        as={<Input.TextArea {...iProps} />}
      />
      {optionalLabel && <div className="optional-label">(Optional)</div>}
    </Form.Item>
  );
};

export const InputRadio = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    noStyle,
    tooltip,
    tooltipTitle,
    tooltipPlacement,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);
  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      label={label}
      valuePropName="checked"
      noStyle={noStyle}
      className={props.classField ? props.classField : ''}
      tooltip={
        tooltip && {
          title: tooltipTitle,
          placement: tooltipPlacement,
          icon: <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} />,
        }
      }
    >
      <Controller
        rules={rules}
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ''}
        render={({ value, onChange }) => (
          <Radio.Group
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              props?.onChange(e);
            }}
          >
            {props.options}
          </Radio.Group>
        )}
      />
    </Form.Item>
  );
};

export const SwitchField = (props) => {
  const { fieldname, control, initValue, rules, iProps } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);
  return (
    <Form.Item className="mb-0">
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : false}
        render={({ value, onChange }) => <Switch checked={value} onChange={(e) => onChange(e)} {...iProps} />}
      />
    </Form.Item>
  );
};

export const SliderField = (props) => {
  const { fieldname, label, control, iProps, rules, initValue, isRequired, validate, validMessage, valueGot } = props;
  useEffect(() => {
    valueGot && props.setValue(fieldname, valueGot);
  }, [valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ''}
        rules={rules}
        render={({ onBlur, value, onChange }) => (
          <Slider value={value} onChange={onChange} onBlur={props.onBlur} {...iProps} />
        )}
      />
    </Form.Item>
  );
};
