import React from 'react';
import { Image, Row } from 'antd';
import loginImage from '../../../../assets/svg/login.svg';

export default (props) => {
  return (
    <div className="login-sidebar">
      <Row align="bottom">
        <Image width="100%" src={loginImage} preview={false} />
      </Row>
    </div>
  );
};
