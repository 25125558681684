import React, { useState, useEffect, Fragment } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Form, Space, Card, Table, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { SelectField, InputField, InputPassword, DateField } from '../../atoms/FormElement';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import reportStatuses from '../../../assets/svg/report-status-icon.svg';
import infoIcon from '../../../assets/svg/info-red-icon.svg';
import { resetAccount } from '../../../services/crudApis';
import moment from 'moment';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title } = props;
  const { control, errors, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const resetMyAccount = () => {
    setLoading(true);
    resetAccount()
      .then((response) => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          user.is_setup_complete = false;
        }
        localStorage.setItem('user', JSON.stringify(user));
        setLoading(false);
        message.success('Account reset.');
        setTimeout(() => {
          history.push('/import-data');
        }, 500);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[20, 20]} justify="end" align="middle">
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Title level={3} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Image src={reportStatuses} width={130} preview={false} />
        </Col>
        <Col span={24}>
          <Card className="info-red-card">
            <Space size={20}>
              <Image src={infoIcon} width={30} preview={false} />
              <div className="info_text">
                <Paragraph className="smallFont14 w-100 mb-0">
                  Reseting the account will lead to all your committees data loss. This action cannot be undone.
                </Paragraph>
                <br />
                <Paragraph className="smallFont14 w-100 mb-0">Are you sure you want to proceed?</Paragraph>
              </div>
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
            <Col>
              <Button type="primary" onClick={onClose} htmlType="button" className="font-gilroy white-btn" size="large">
                Cancel
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={resetMyAccount}>
                reset my account
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
