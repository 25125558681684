import React, { useState } from 'react';
import { message, Button, Row, Col, Typography, Image, DatePicker, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import { baseUrl } from '../../../configs/constants';
import { downloadFile, saveFile } from '../../../services/crudApis';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, paragraph, csvEndPoint, type, csvType } = props;
  const [selectedDate, setSelectedDate] = useState([moment(), moment()]);
  const [loading, setLoading] = useState(false);
  const [filterRange, setFilterRange] = useState([moment(), moment()]);

  const dateRange = (date) => {
    setSelectedDate(date);
    setFilterRange(date);
  };

  const handleCSVDownloadButtonClick = () => {
    let fromDate = '';
    let toDate = '';
    if (selectedDate && selectedDate?.length > 0) {
      fromDate = moment(selectedDate[0]).format('YYYY-MM-DD');
    } else {
      fromDate = 'null';
    }
    if (selectedDate && selectedDate?.length > 1) {
      toDate = moment(selectedDate[1]).format('YYYY-MM-DD');
    } else {
      toDate = 'null';
    }

    try {
      setLoading(true);
      downloadFile(`${baseUrl}/${csvEndPoint}/${fromDate}/${toDate}/${type === 'Debts' ? csvType : ''}`)
        .then((response) => {
          saveFile(response, `Electafile - ${type} - ${fromDate}-${toDate}.csv`);
          setLoading(false);
          setFilterRange(null);
          onClose();
        })
        .catch((error) => {
          message.error('Something went wrong');
          setLoading(false);
        });
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  const closePopUp = () => {
    setFilterRange(null);
    onClose();
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle" gutter={[20, 20]}>
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>

        <Col span={24}>
          <Paragraph>{paragraph}</Paragraph>
        </Col>
        <Col span={24}>
          <RangePicker onChange={dateRange} className="w-100" value={filterRange} />
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[20, 20]}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                className="font-gilroy white-btn"
                size="large"
                onClick={closePopUp}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="secondary"
                htmlType="submit"
                className="font-gilroy"
                size="large"
                onClick={handleCSVDownloadButtonClick}
              >
                Export CSv
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
