import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import MainHeader from '../../molecules/Dashboard/Header';
import AddCommiteeSidebar from '../../molecules/Dashboard/AddCommiteeSidebar';

const { Title } = Typography;

export default (props) => {
  return (
    <Layout className="login-layout committee-screen">
      <Row align="middle" justify="center">
        <Col span={8}>
          <AddCommiteeSidebar />
        </Col>
        <Col span={16}>
          <Row align="middle" justify="center" gutter={[20, 20]}>
            <Col span={20}>
              <MainHeader />
            </Col>
            <Col span={24} className="text-center">
              <Title level={1} className={props?.className}>
                {props?.heading}
              </Title>
            </Col>
            <Col flex="0 0 482px" style={{ padding: '0' }}>
              {props.children}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};
