import React from 'react';
import { Row, Col, Space, Typography, Card, Image } from 'antd';
import moment from 'moment';
import mapImage from '../../../../assets/svg/bg-shadow.svg';
import openURL from '../../../../assets/svg/open-url.svg';
import { ReportHeading } from '../../../../configs/constants';

const { Text, Title } = Typography;
export default (props) => {
  const { infoData } = props;
  return (
    <Card bordered={false} className="blue-card ant-card-0 info-widget">
      <div className="info-map">
        <Image width="auto" src={mapImage} preview={false} />
      </div>

      <Row gutter={0} justify="space-between">
        <Col>
          <Title level={4} className="font-gilroy c-white info_heading">
            {infoData?.committeeName} <br />
            <span style={{ height: '17px', display: 'block' }}>{infoData?.committeeId}</span>
          </Title>
          <Space size={40}>
            {/* {infoData?.statewide?.map((item, index) => ( */}
            {(infoData?.electionStartYear || infoData?.electionEndYear) && (
              <div className="info_left">
                <Title level={5} className="c-white m-0 font-400">
                  Current Election Cycle
                </Title>
                <Title level={5} className="c-white f-bold m-0">
                  {infoData?.electionStartYear} - {infoData?.electionEndYear}
                </Title>
              </div>
            )}
            {infoData?.currentReportingPeriod?.title && (
              <div className="info_left">
                <Title level={5} className="c-white m-0 font-400">
                  Current Reporting Period
                </Title>
                <Title level={5} className="c-white f-bold m-0">
                  {ReportHeading[infoData?.currentReportingPeriod?.title]}:{' '}
                  {moment(infoData?.currentReportingPeriod?.startDate).format('ll')} -{' '}
                  {moment(infoData?.currentReportingPeriod?.endDate).format('ll')}
                </Title>
              </div>
            )}
            {/* ))} */}
          </Space>
        </Col>
        <Col className="text-right">
          <div className="info_tag">
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <Title level={5} className="c-white font-400 m-0 smallFont12">
                Primary Election:
              </Title>
              <Title level={5} className="c-white f-bold m-0 smallFont12">
                {infoData?.primaryDate}
              </Title>
            </div>
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <Title level={5} className="c-white font-400 m-0 smallFont12">
                General Election:
              </Title>
              <Title level={5} className="c-white f-bold m-0 smallFont12">
                {infoData?.generalDate}
              </Title>
            </div>
          </div>

          <div className="state_board_link">
            <a href="https://www.ncsbe.gov/" target="_blank">
              NC State Board of Elections <Image width={8} src={openURL} preview={false} />
            </a>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
