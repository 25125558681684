import React from 'react';
import { Image, Row } from 'antd';
import { Link } from 'react-router-dom';
import loginImage from '../../../../assets/svg/login.svg';
import Logo from '../../../../assets/svg/new-white-logo.svg';

export default (props) => {
  return (
    <div className="committee-sidebar">
      <Row justify="space-between">
        <div className="logo">
          <Image width={150} src={Logo} preview={false} />
          {/* <Link to="/dashboard">
          </Link> */}
        </div>
        <div style={{ marginTop: 'auto' }} className="w-100">
          <Image width="100%" src={loginImage} preview={false} />
        </div>
      </Row>
    </div>
  );
};
