import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, message, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputField, TextAreaField, DateField, SelectField } from '../../../../atoms/FormElement';
import { useFieldArray } from 'react-hook-form';
import ItemForSale from './ItemForSale';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, setLoading, deletedIndexes, setDeletedIndexes } = props;
  const dispatch = useDispatch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items_sale',
  });

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      const selectedAccountList = formData?.accounts?.find((e) => e?.value == formData?.exemptData?.account_id);
      setValue('description', formData?.exemptData?.description);
      setValue('account_id', selectedAccountList);
      setValue(
        'startDate',
        formData?.exemptData?.start_date ? moment(formData?.exemptData?.start_date, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue(
        'endDate',
        formData?.exemptData?.end_date ? moment(formData?.exemptData?.end_date, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue(
        'submissionDate',
        formData?.exemptData?.submission_date ? moment(formData?.exemptData?.submission_date, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue('items_sale', formData?.exemptData?.exemptSalesItems);
    }
  }, [formData]);

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="description"
                label="Plan Description"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'Plan Description is required',
                }}
                validate={errors.description && 'error'}
                validMessage={errors.description && errors.description.message}
              />
            </Col>

            <Col span={12}>
              <DateField
                fieldname="startDate"
                label="Start Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Start Date is required.',
                }}
                validate={errors.startDate && 'error'}
                validMessage={errors.startDate && errors.startDate.message}
              />
            </Col>

            <Col span={12}>
              <DateField
                fieldname="endDate"
                label="End Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'End Date is required.',
                }}
                validate={errors.endDate && 'error'}
                validMessage={errors.endDate && errors.endDate.message}
              />
            </Col>

            <Col span={24}>
              <DateField
                fieldname="submissionDate"
                label="Plan Submission Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Plan Submission Date is required.',
                }}
                validate={errors.submissionDate && 'error'}
                validMessage={errors.submissionDate && errors.submissionDate.message}
              />
            </Col>

            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="account_id"
                label="Account"
                control={control}
                class="mb-0"
                iProps={{ placeholder: 'Please select' }}
                rules={{ required: 'Account is required.' }}
                initValue=""
                selectOption={formData && formData?.accounts}
                validate={errors.account_id && 'error'}
                validMessage={errors.account_id && errors.account_id.message}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <ItemForSale
            formData={formData}
            fields={fields}
            control={control}
            errors={errors}
            setValue={setValue}
            append={append}
            remove={remove}
            deletedIndexes={deletedIndexes}
            setDeletedIndexes={setDeletedIndexes}
          />
        </Col>
      </Row>
    </>
  );
};
