import axios from 'axios';
import { baseUrl } from '../configs/constants';
const auth = baseUrl + '/login';

const authInterceptors = axios.create({
  baseURL: baseUrl,
});

authInterceptors.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
const getQueryString = (data = {}) => {
  return Object.entries(data)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};
authInterceptors.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((window.location.pathname!=='/')  && (error.response.status === 403 && !originalRequest._dretry || error.response.status === 401)) {
      originalRequest._retry = true;
      localStorage.clear();
      window.location.assign('/')
    }
    return Promise.reject(error);
  },
);
export default authInterceptors;
