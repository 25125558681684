import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getExpenditureFormData } from '../../ducks/actions';
import { REFUND_PURPOSE_CODE, FORMS_OF_PAYMENTS } from '../../../../../../configs/constants';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const [formOfPayment, setFormOfPayment] = useState('');
  const dispatch = useDispatch();
  const expenditureFormData = useSelector((state) => state.expenditure.expenditureFormData);
  //console.log(JSON.stringify(expenditureFormData));

  useEffect(() => {
    dispatch(getExpenditureFormData('loan_repayment', 'null'));
  }, []);

  useEffect(() => {
    if (expenditureFormData && expenditureFormData?.expenditureData && Object.keys(expenditureFormData)?.length > 0) {
      const selectedAccountList = expenditureFormData?.accounts?.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.account_id,
      );

      const loanID = expenditureFormData?.contributors?.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.loan_id,
      );

      setValue('loan_id', loanID);
      setValue('amount', expenditureFormData.expenditureData?.amount);
      setValue(
        'date',
        expenditureFormData?.expenditureData?.date
          ? moment(expenditureFormData?.expenditureData?.date, 'YYYY-MM-DD HH:mm')
          : '',
      );
      setValue('comments', expenditureFormData.expenditureData?.comments);
      setValue('payment_type', expenditureFormData.expenditureData?.payment_type);
      setFormOfPayment(expenditureFormData.expenditureData?.payment_type);
      setTimeout(() => {
        setValue('check_number', expenditureFormData.expenditureData?.check_number);
        setValue('in_kind_description', expenditureFormData.expenditureData?.in_kind_description);
        setValue('account', selectedAccountList);
        setLoading(false);
      }, 1000);
    }
  }, [expenditureFormData]);

  const forgivenLoan = [
    { label: "$1,000.00 from Franklyn's Fund", value: "$1,000.00 from Franklyn's Fund", subLabel: '2-5-25' },
    { label: '$25,000 from Company Abcz', value: '$25,000 from Company Abcz', subLabel: '2-5-25' },
  ];

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (expenditureFormData && expenditureFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', expenditureFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={24}>
        <Paragraph className="c-blue">Use this from to report a payment on an existing loan.</Paragraph>
      </Col>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="loan_id"
              label="Outstanding Loan"
              control={control}
              class="mb-0 rightSublevel"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Loan That Was Forgiven is required.' }}
              initValue=""
              selectOption={expenditureFormData?.contributors}
              validate={errors.loan_id && 'error'}
              validMessage={errors.loan_id && errors.loan_id.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Amount Repaid"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Amount Repaid is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Repayment Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Repayment Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          <Col span={24}>
            <TextAreaField
              fieldname="comments"
              label="Comments"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              optionalLabel={true}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputRadio
              fieldname="payment_type"
              label="Form of Payment"
              control={control}
              initValue={''}
              classField="custom-radio"
              onChange={(e) => formPayment(e)}
              options={
                <>
                  {FORMS_OF_PAYMENTS &&
                    FORMS_OF_PAYMENTS?.map((item, index) => (
                      <Fragment key={index}>
                        <Radio.Button value={item?.value}>
                          <Image src={item?.image} preview={false} width={80} />
                          <Paragraph className="smallFont12">{item?.label}</Paragraph>
                        </Radio.Button>
                      </Fragment>
                    ))}
                </>
              }
              rules={{
                required: 'Form of Payment is required.',
              }}
              validate={errors.payment_type && 'error'}
              validMessage={errors.payment_type && errors.payment_type.message}
            />
          </Col>

          {formOfPayment && (
            <Col span={24}>
              {formOfPayment != 'in_kind' && (
                <Row gutter={16}>
                  {formOfPayment == 'check' && (
                    <Col span={12}>
                      <InputField
                        fieldname="check_number"
                        label="Check Number"
                        control={control}
                        initValue=""
                        optionalLabel="(Optional)"
                        iProps={{
                          size: 'large',
                        }}
                      />
                    </Col>
                  )}
                  <Col span={formOfPayment == 'check' ? 12 : 24}>
                    <SelectField
                      isRequired={true}
                      fieldname="account"
                      label="Account"
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      rules={{ required: 'Account is required.' }}
                      initValue=""
                      selectOption={expenditureFormData?.accounts}
                      validate={errors.account && 'error'}
                      validMessage={errors.account && errors.account.message}
                    />
                  </Col>
                </Row>
              )}

              {formOfPayment == 'in_kind' && (
                <Col span={24}>
                  <InputField
                    isRequired={true}
                    fieldname="in_kind_description"
                    label="In-Kind Description"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'In-Kind Description is required.',
                    }}
                    validate={errors.in_kind_description && 'error'}
                    validMessage={errors.in_kind_description && errors.in_kind_description.message}
                  />
                </Col>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
