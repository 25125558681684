import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, Radio, Image, message, Card, Space } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { SelectField, InputField, InputRadio, InputCheckbox } from '../../../../atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';
import plusIcon from '../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../assets/svg/trash-icon.svg';
import infoIcon from '../../../../../assets/svg/info-icon.svg';
import { countryList, specificField } from '../../../../../configs/constants';
import { addObject } from '../../../../../services/crudApis';
import { SAVE_OFFICERS } from '../../../../../configs/endpoints';
import { getOfficersFormData } from '../../../Dashboard/Settings/ducks/actions';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, errors } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);
  const [assistantCheck, setAssistantCheck] = useState(false);
  const [custodianCheck, setCustodianCheck] = useState(false);
  const [jobTitle, setJobTitle] = useState('JobTitle');
  const [CBJobTitle, setCBJobTitle] = useState('CB_JobTitle');
  const [employeeName, setEmployeeName] = useState('employersName');
  const [CBEmployeeName, setCBEmployeeName] = useState('CB_employersName');
  const [CAJobTitle, setCAJobTitle] = useState('CA_JobTitle');
  const [CAEmployeeName, setCAEmployeeName] = useState('CA_employersName');

  const [contribute, setContribute] = useState('contributingNo');
  const [assistantTreasurer, setAssistantTreasurer] = useState(false);
  const [custodianBooks, setCustodianBooks] = useState(false);
  const [assistantTreasurerButton, setAssistantTreasurerButton] = useState(true);
  const [custodianBooksButton, setCustodianBooksButton] = useState(true);
  const [contributingAssistant, setContributingAssistant] = useState('contributingAssistantNo');
  const [custodianBooksRadio, setCustodianBooksRadio] = useState('CB_No');
  const [officersPhone, setOfficersPhone] = useState('');
  const [custodianPhone, setCustodianPhone] = useState('');
  const [assistantPhone, setAssistantPhone] = useState('');
  const formData = useSelector((state) => state.setting.officerFormData);
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  const federalPAC = localStorage.getItem('organization');

  useEffect(() => {
    dispatch(getOfficersFormData());
    setLoading(true);
  }, []);

  useEffect(() => {
    setValue('custodian_officersMailingcountryList', { label: 'North Carolina', value: 'NC' });
    setValue('assistant_officersMailingcountryList', { label: 'North Carolina', value: 'NC' });
    setValue('officersMailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    setLoading(false);
    if (formData && Object.keys(formData).length > 0) {
      setLoading(false);

      const bean = formData['Officer'] ? formData['Officer'] : false;
      if (bean) {
        const selectedState = countryList?.find((e) => e?.value == bean?.state);
        const specificDrop = specificField?.find((e) => e?.value == bean?.specific_field);
        const suffixDrop = suffixData?.find((e) => e?.value == bean?.suffix);
        setValue('officersFirstName', bean.first_name);
        setValue('officersLastName', bean.last_name);
        setValue('officers_middle_name', bean.middle_name);
        setValue('officers_suffix', suffixDrop);

        setValue('jobTitle', bean.job_title);
        setValue('profession', bean.profession);
        setValue('employerName', bean.employer_name);
        setValue('specificField', bean.specific_field);
        setValue('officersMailingCity', bean.city);
        setValue('officersMailingZip', bean.post_code);
        //setValue('officersPhoneNumber', bean.phone);
        setOfficersPhone(bean?.phone);
        setValue('officersEmail', bean.email);
        setValue('officersMailingStreet', bean.mailing_address);
        setValue(
          'contributing',
          bean.is_contributor && bean.is_contributor != null ? 'contributingYes' : 'contributingNo',
        );
        setContribute(bean.is_contributor ? 'contributingYes' : 'contributingNo');
        if (bean?.profession && bean?.profession?.length > 0) {
          setJobTitle('Profession');
        } else {
          setJobTitle('JobTitle');
        }
        if (bean?.specific_field && bean?.specific_field?.length > 0) {
          setEmployeeName('specificField');
        } else {
          setEmployeeName('employersName');
        }
        setTimeout(() => {
          if (selectedState) {
            setValue('officersMailingcountryList', selectedState);
          }
          setValue('jobTitle', bean?.job_title);
          setValue('profession', bean?.profession);
          setValue('employerName', bean?.employer_name);
          setValue('specificField', specificDrop);
          setValue('jobRadio', bean?.profession && bean?.profession?.length > 0 ? 'Profession' : 'JobTitle');
          setValue(
            'employeeRadio',
            bean?.specific_field && bean?.specific_field?.length > 0 ? 'specificField' : 'employersName',
          );
          setLoading(false);
        }, 10);
      }

      setTimeout(() => {
        const beanCustodian = formData['Custodian'] ? formData['Custodian'] : false;
        if (beanCustodian) {
          setCustodianBooks(true);
          const selectedState = countryList?.find((e) => e?.value == beanCustodian?.state);
          const specificDrop = specificField?.find((e) => e?.value == beanCustodian?.specific_field);
          const suffixDrop = suffixData?.find((e) => e?.value == beanCustodian?.suffix);
          setValue('custodian_officersFirstName', beanCustodian.first_name);
          setValue('custodian_officersLastName', beanCustodian.last_name);
          setValue('custodian_middle_name', beanCustodian.middle_name);
          setValue('custodian_suffix', suffixDrop);
          setValue('custodian_books_check', beanCustodian.email_notices);
          setValue('is_candidate_spouse', beanCustodian.is_candidate_spouse);
          setValue('custodian_officersMailingCity', beanCustodian.city);
          setValue('custodian_officersMailingZip', beanCustodian.post_code);
          //setValue('custodian_officersPhoneNumber', beanCustodian.phone);
          setCustodianPhone(beanCustodian?.phone);
          setValue('custodian_officersEmail', beanCustodian.email);
          setValue('custodian_officersMailingStreet', beanCustodian.mailing_address);
          setValue(
            'custodian_contributingAssistant',
            beanCustodian.is_contributor && beanCustodian.is_contributor != null ? 'CB_Yes' : 'CB_No',
          );
          setCustodianBooksRadio(beanCustodian.is_contributor ? 'CB_Yes' : 'CB_No');

          if (beanCustodian?.profession && beanCustodian?.profession?.length > 0) {
            setCBJobTitle('CB_Profession');
          } else {
            setCBJobTitle('CB_JobTitle');
          }
          if (beanCustodian?.specific_field && beanCustodian?.specific_field?.length > 0) {
            setCBEmployeeName('CB_specificField');
          } else {
            setCBEmployeeName('CB_employersName');
          }
          setCustodianBooksButton(false);
          setTimeout(() => {
            setValue(
              'CB_jobRadio',
              beanCustodian?.profession && beanCustodian?.profession?.length > 0 ? 'CB_Profession' : 'CB_JobTitle',
            );
            setValue(
              'CB_employeeRadio',
              beanCustodian?.specific_field && beanCustodian?.specific_field?.length > 0
                ? 'CB_specificField'
                : 'CB_employersName',
            );
            if (selectedState) {
              setValue('custodian_officersMailingcountryList', selectedState);
            }
            setValue('custodian_jobTitle', beanCustodian?.job_title);
            setValue('custodian_profession', beanCustodian?.profession);
            setValue('custodian_employerName', beanCustodian?.employer_name);
            setValue('custodian_specificField', specificDrop);
            setLoading(false);
          }, 1200);
        }

        const beanAsstCustodian = formData['AsstCustodian'] ? formData['AsstCustodian'] : false;
        if (beanAsstCustodian) {
          setAssistantTreasurer(true);
          const selectedState = countryList?.find((e) => e?.value == beanAsstCustodian?.state);
          const specificDrop = specificField?.find((e) => e?.value == beanAsstCustodian?.specific_field);
          const suffixDrop = suffixData?.find((e) => e?.value == beanAsstCustodian?.suffix);
          setValue('assistant_officersFirstName', beanAsstCustodian.first_name);
          setValue('assistant_officersLastName', beanAsstCustodian.last_name);
          setValue('assistant_middle_name', beanAsstCustodian.middle_name);
          setValue('assistant_suffix', suffixDrop);
          setValue('assistant_checkbox', beanCustodian.email_notices);
          setValue('assistant_jobTitle', beanAsstCustodian.job_title);
          setValue('assistant_profession', beanAsstCustodian.profession);
          setValue('assistant_employerName', beanAsstCustodian.employer_name);
          setValue('assistant_specificField', beanAsstCustodian.specific_field);
          setValue('assistant_officersMailingCity', beanAsstCustodian.city);
          setValue('assistant_officersMailingZip', beanAsstCustodian.post_code);
          //setValue('assistant_officersPhoneNumber', beanAsstCustodian.phone);
          setAssistantPhone(beanAsstCustodian?.phone);
          setValue('assistant_officersEmail', beanAsstCustodian.email);
          setValue('assistant_officersMailingStreet', beanAsstCustodian.mailing_address);
          setValue('assistant_checkbox', beanAsstCustodian.email_notices);

          setAssistantTreasurerButton(false);
          setValue(
            'contributingAssistant',
            beanAsstCustodian.is_contributor && beanAsstCustodian.is_contributor != null
              ? 'contributingAssistantYes'
              : 'contributingAssistantNo',
          );
          setContributingAssistant(
            beanAsstCustodian.is_contributor ? 'contributingAssistantYes' : 'contributingAssistantNo',
          );

          if (beanAsstCustodian?.profession && beanAsstCustodian?.profession?.length > 0) {
            setCAJobTitle('CA_Profession');
          } else {
            setCAJobTitle('CA_JobTitle');
          }
          if (beanAsstCustodian?.specific_field && beanAsstCustodian?.specific_field?.length > 0) {
            setCAEmployeeName('CA_specificField');
          } else {
            setCAEmployeeName('CA_employersName');
          }

          setTimeout(() => {
            if (selectedState) {
              setValue('assistant_officersMailingcountryList', selectedState);
            }
            setValue('assistant_jobTitle', beanAsstCustodian?.job_title);
            setValue('assistant_profession', beanAsstCustodian?.profession);
            setValue('assistant_employerName', beanAsstCustodian?.employer_name);
            setValue('assistant_specificField', specificDrop);
            setValue(
              'CA_jobRadio',
              beanAsstCustodian?.profession && beanAsstCustodian?.profession?.length > 0
                ? 'CA_Profession'
                : 'CA_JobTitle',
            );
            setValue(
              'CA_employeeRadio',
              beanAsstCustodian?.specific_field && beanAsstCustodian?.specific_field?.length > 0
                ? 'CA_specificField'
                : 'CA_employersName',
            );

            setLoading(false);
          }, 1200);
        }
      }, 1200);
    }
  }, [formData]);

  const onChangeOfficers = (event) => {
    setOfficersPhone(event.target.value);
  };

  const onChangeCustodian = (event) => {
    setCustodianPhone(event.target.value);
  };

  const onChangeAssistant = (event) => {
    setAssistantPhone(event.target.value);
  };

  const onFinish = (values) => {
    setLoading(true);
    const treasurerBean = {
      type: 'treasurer',
      email_notices: true,
      first_name: values?.officersFirstName,
      last_name: values?.officersLastName,
      middle_name: values.officers_middle_name,
      suffix: values.officers_suffix?.value,
      is_contributor: values?.contributing == 'contributingYes' ? true : false,
      job_title: values?.contributing == 'contributingYes' && jobTitle == 'JobTitle' ? values?.jobTitle : '',
      profession: values?.contributing == 'contributingYes' && jobTitle == 'Profession' ? values?.profession : '',
      employer_name:
        values?.contributing == 'contributingYes' && employeeName == 'employersName' ? values?.employerName : '',
      specific_field:
        values?.contributing == 'contributingYes' && employeeName == 'specificField'
          ? values?.specificField?.value
          : '',
      is_candidate_spouse: false,
      address: {
        state: values?.officersMailingcountryList?.value,
        city: values?.officersMailingCity,
        post_code: values?.officersMailingZip,
        phone: officersPhone,
        email: values?.officersEmail,
        mailing_address: values?.officersMailingStreet,
      },
    };
    let assistantBean = false;
    if (assistantTreasurer) {
      assistantBean = {
        type: 'assistant_treasurer',
        first_name: values.assistant_officersFirstName,
        last_name: values.assistant_officersLastName,
        middle_name: values.assistant_middle_name,
        suffix: values.assistant_suffix?.value,
        is_contributor: values.contributingAssistant == 'contributingAssistantYes' ? true : false,
        email_notices: values.assistant_checkbox,
        is_candidate_spouse: false,
        address: {
          state: values.assistant_officersMailingcountryList?.value,
          city: values.assistant_officersMailingCity,
          post_code: values.assistant_officersMailingZip,
          phone: assistantPhone,
          email: values.assistant_officersEmail,
          mailing_address: values.assistant_officersMailingStreet,
        },

        job_title:
          contributingAssistant == 'contributingAssistantYes' && CAJobTitle == 'CA_JobTitle'
            ? values?.assistant_jobTitle
            : '',
        profession:
          contributingAssistant == 'contributingAssistantYes' && CAJobTitle == 'CA_Profession'
            ? values?.assistant_profession
            : '',
        employer_name:
          contributingAssistant == 'contributingAssistantYes' && CAEmployeeName == 'CA_employersName'
            ? values?.assistant_employerName
            : '',
        specific_field:
          contributingAssistant == 'contributingAssistantYes' && CAEmployeeName == 'CA_specificField'
            ? values?.assistant_specificField?.value
            : '',
      };
    }

    let custodianBean = false;
    if (custodianBooks) {
      custodianBean = {
        type: 'custodian',
        first_name: values.custodian_officersFirstName,
        last_name: values.custodian_officersLastName,
        middle_name: values.custodian_middle_name,
        suffix: values.custodian_suffix?.value,
        is_contributor: values.custodian_contributingAssistant == 'CB_Yes' ? true : false,
        email_notices: values.custodian_books_check,
        is_candidate_spouse: false,
        address: {
          state: values.custodian_officersMailingcountryList?.value,
          city: values.custodian_officersMailingCity,
          post_code: values.custodian_officersMailingZip,
          phone: custodianPhone,
          email: values.custodian_officersEmail,
          mailing_address: values.custodian_officersMailingStreet,
        },
        job_title: custodianBooksRadio == 'CB_Yes' && CBJobTitle == 'CB_JobTitle' ? values?.custodian_jobTitle : '',
        profession:
          custodianBooksRadio == 'CB_Yes' && CBJobTitle == 'CB_Profession' ? values?.custodian_profession : '',
        employer_name:
          custodianBooksRadio == 'CB_Yes' && CBEmployeeName == 'CB_employersName' ? values?.custodian_employerName : '',
        specific_field:
          custodianBooksRadio == 'CB_Yes' && CBEmployeeName == 'CB_specificField'
            ? values?.custodian_specificField?.value
            : '',
      };
    }
    addObject(SAVE_OFFICERS, { treasurerBean, assistantBean, custodianBean })
      .then((response) => {
        setLoading(false);
        message.success('Information Updated.');
        dispatch(getOfficersFormData());
        setTimeout(() => setCurrentStep(currentStep + 1), 2200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const assistantTreasurerClick = () => {
    setAssistantTreasurerButton(false);
    setAssistantTreasurer(true);
  };

  const custodianBooksClick = () => {
    setCustodianBooksButton(false);
    setCustodianBooks(true);
  };

  const closeCustodianBooksClick = () => {
    setCustodianBooksButton(true);
    setCustodianBooks(false);
  };

  const closeAssistantTreasurerClick = () => {
    setAssistantTreasurerButton(true);
    setAssistantTreasurer(false);
  };

  const suffixData = [
    { label: 'JR', value: 'JR' },
    { label: 'SR', value: 'SR' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
  ];

  const selectedCountryOff = (e) => {
    if (e?.value == 'WADC') {
      setValue('officersMailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData).length > 0) {
      const bean = formData['Officer'] ? formData['Officer'] : false;
      if (bean?.city) {
        setValue('officersMailingCity', bean?.city);
      } else {
        setValue('officersMailingCity', '');
      }
    }
  };

  const selectedCountryAss = (e) => {
    if (e?.value == 'WADC') {
      setValue('assistant_officersMailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData).length > 0) {
      const bean = formData['AsstCustodian'] ? formData['AsstCustodian'] : false;
      if (bean?.city) {
        setValue('assistant_officersMailingCity', bean?.city);
      } else {
        setValue('assistant_officersMailingCity', '');
      }
    }
  };

  const selectedCountryCus = (e) => {
    if (e?.value == 'WADC') {
      setValue('custodian_officersMailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData).length > 0) {
      const bean = formData['Custodian'] ? formData['Custodian'] : false;
      if (bean?.city) {
        setValue('custodian_officersMailingCity', bean?.city);
      } else {
        setValue('custodian_officersMailingCity', '');
      }
    }
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[10, 20]}>
          <Col span={24}>
            <Title level={2} className="text-center mb-0 font-gilroy">
              Treasurer
            </Title>
          </Col>

          {committeeType === 'political-action' && federalPAC === 'federal' && (
            <Col span={24}>
              <Card className="info-card">
                <Space size={20}>
                  <Image src={infoIcon} width={30} preview={false} />
                  <div className="info_text">
                    <Paragraph className="smallFont14 w-100 m-0">
                      If the treasurer is not a resident of North Carolina, then a North Carolina resident must be
                      appointed as assistant treasurer.
                    </Paragraph>
                  </div>
                </Space>
              </Card>
            </Col>
          )}
          <Col span={6}>
            <InputField
              isRequired={true}
              fieldname="officersFirstName"
              label="Name"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'First',
              }}
              rules={{
                required: 'Name is required.',
              }}
              validate={errors.officersFirstName && 'error'}
              validMessage={errors.officersFirstName && errors.officersFirstName.message}
            />
          </Col>

          <Col span={6}>
            <InputField
              fieldname="officers_middle_name"
              label=" "
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Middle',
              }}
            />
          </Col>

          <Col span={6}>
            <InputField
              fieldname="officersLastName"
              label=" "
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Last',
              }}
            />
          </Col>

          <Col span={6}>
            <SelectField
              fieldname="officers_suffix"
              label=" "
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Suffix', isClearable: true }}
              initValue=""
              selectOption={suffixData}
            />
          </Col>

          <Col span={24} className="mailingField">
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="officersMailingStreet"
                  label="Mailing Address"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Street Address',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.officersMailingStreet && 'error'}
                  validMessage={errors.officersMailingStreet && errors.officersMailingStreet.message}
                />
              </Col>

              <Col span={9}>
                <SelectField
                  isRequired={true}
                  fieldname="officersMailingcountryList"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  rules={{ required: 'Mailing Address is required.' }}
                  selectOption={countryList}
                  onChange={(e) => selectedCountryOff(e)}
                  validate={errors.officersMailingcountryList && 'error'}
                  validMessage={errors.officersMailingcountryList && errors.officersMailingcountryList.message}
                />
              </Col>

              <Col span={9}>
                <InputField
                  isRequired={true}
                  fieldname="officersMailingCity"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'City',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.officersMailingCity && 'error'}
                  validMessage={errors.officersMailingCity && errors.officersMailingCity.message}
                />
              </Col>

              <Col span={6}>
                <InputField
                  isRequired={true}
                  fieldname="officersMailingZip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Zip',
                  }}
                  rules={{
                    required: 'Mailing Address is required.',
                  }}
                  validate={errors.officersMailingZip && 'error'}
                  validMessage={errors.officersMailingZip && errors.officersMailingZip.message}
                />
              </Col>
              {(errors.officersMailingZip || errors.officersMailingCity || errors.officersMailingStreet) && (
                <Col span={24} className="mailingError">
                  Mailing Address is required.
                </Col>
              )}
            </Row>
          </Col>

          <Col span={12}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Phone Number">
                  Phone Number
                </label>
                <div className="optional-label" style={{ top: '4px' }}>
                  (Optional)
                </div>
              </div>
            </div>
            <InputMask mask="(999) 999-9999" value={officersPhone} onChange={onChangeOfficers}>
              {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
            </InputMask>
          </Col>

          <Col span={12}>
            <InputField
              fieldname="officersEmail"
              label="Email"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              isRequired={true}
              rules={{
                required: 'Email is required.',
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Check if the email is valid',
                },
              }}
              validate={errors.officersEmail && 'error'}
              validMessage={errors.officersEmail && errors.officersEmail.message}
            />
          </Col>

          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <InputRadio
                  fieldname="contributing"
                  label="Will this person be contributing to this committee?"
                  control={control}
                  onChange={(e) => setContribute(e.target.value)}
                  classField="tab-type-radio"
                  options={
                    <>
                      <Row gutter={[20, 20]}>
                        <Col span={12}>
                          <Radio value="contributingNo">No</Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value="contributingYes">Yes</Radio>
                        </Col>
                      </Row>
                    </>
                  }
                  isRequired={true}
                  rules={{
                    required: 'Will this person be contributing to this committee? is required.',
                  }}
                  validate={errors.contributing && 'error'}
                  validMessage={errors.contributing && errors.contributing.message}
                />
              </Col>
              {contribute == 'contributingYes' && (
                <>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <InputRadio
                          fieldname="jobRadio"
                          control={control}
                          initValue={'JobTitle'}
                          classField="small-radio"
                          onChange={(e) => setJobTitle(e.target.value)}
                          options={
                            <>
                              <Radio value="JobTitle">Job Title</Radio>
                              <Radio value="Profession">Profession</Radio>
                            </>
                          }
                        />
                      </Col>
                      <Col span={24}>
                        {jobTitle == 'JobTitle' && (
                          <InputField
                            isRequired={true}
                            fieldname="jobTitle"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Job Title',
                            }}
                            rules={{
                              required: 'Job Title is required.',
                            }}
                            validate={errors.jobTitle && 'error'}
                            validMessage={errors.jobTitle && errors.jobTitle.message}
                          />
                        )}

                        {jobTitle == 'Profession' && (
                          <InputField
                            isRequired={true}
                            fieldname="profession"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Profession',
                            }}
                            rules={{
                              required: 'Profession is required.',
                            }}
                            validate={errors.profession && 'error'}
                            validMessage={errors.profession && errors.profession.message}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <InputRadio
                          fieldname="employeeRadio"
                          control={control}
                          initValue={'employersName'}
                          onChange={(e) => setEmployeeName(e.target.value)}
                          classField="small-radio"
                          options={
                            <>
                              <Radio value="employersName">Employer's Name</Radio>
                              <Radio value="specificField">Specific Field</Radio>
                            </>
                          }
                        />
                      </Col>
                      <Col span={24}>
                        {employeeName == 'employersName' && (
                          <InputField
                            isRequired={true}
                            fieldname="employerName"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: "Employer's Name",
                            }}
                            rules={{
                              required: "Employer's Name is required.",
                            }}
                            validate={errors.employerName && 'error'}
                            validMessage={errors.employerName && errors.employerName.message}
                          />
                        )}

                        {employeeName == 'specificField' && (
                          <SelectField
                            isRequired={true}
                            fieldname="specificField"
                            control={control}
                            class="mb-0"
                            iProps={{ placeholder: 'Specific Field' }}
                            rules={{ required: 'Specific Field is required.' }}
                            initValue=""
                            selectOption={specificField}
                            validate={errors.specificField && 'error'}
                            validMessage={errors.specificField && errors.specificField.message}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[20, 20]}>
              {assistantTreasurer && (
                <>
                  <Col span={24}>
                    <Title level={2} className="text-center mb-0 font-gilroy">
                      Assistant Treasurer
                    </Title>
                    <div className="right-cross-btn">
                      <Button
                        type="text"
                        htmlType="button"
                        className="font-gilroy c-grey d-flex align-center"
                        onClick={() => closeAssistantTreasurerClick()}
                      >
                        <Image src={trashIcon} preview={false} size={24} />
                      </Button>
                    </div>
                  </Col>
                  <Col span={6}>
                    <InputField
                      isRequired={true}
                      fieldname="assistant_officersFirstName"
                      label="Name"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'First',
                      }}
                      rules={{
                        required: 'Name is required.',
                      }}
                      validate={errors.assistant_officersFirstName && 'error'}
                      validMessage={errors.assistant_officersFirstName && errors.assistant_officersFirstName.message}
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      fieldname="assistant_middle_name"
                      label=" "
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Middle',
                      }}
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      fieldname="assistant_officersLastName"
                      label=" "
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Last',
                      }}
                    />
                  </Col>

                  <Col span={6}>
                    <SelectField
                      fieldname="assistant_suffix"
                      label=" "
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Suffix', isClearable: true }}
                      initValue=""
                      selectOption={suffixData}
                    />
                  </Col>

                  <Col span={24} className="mailingField">
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <InputField
                          isRequired={true}
                          fieldname="assistant_officersMailingStreet"
                          label="Mailing Address"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Street Address',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.assistant_officersMailingStreet && 'error'}
                          validMessage={
                            errors.assistant_officersMailingStreet && errors.assistant_officersMailingStreet.message
                          }
                        />
                      </Col>

                      <Col span={9}>
                        <SelectField
                          isRequired={true}
                          fieldname="assistant_officersMailingcountryList"
                          control={control}
                          class="mb-0"
                          iProps={{ placeholder: 'Please select' }}
                          rules={{ required: 'Mailing Address is required.' }}
                          initValue={{}}
                          selectOption={countryList}
                          onChange={(e) => selectedCountryAss(e)}
                          validate={errors.assistant_officersMailingcountryList && 'error'}
                          validMessage={
                            errors.assistant_officersMailingcountryList &&
                            errors.assistant_officersMailingcountryList.message
                          }
                        />
                      </Col>

                      <Col span={9}>
                        <InputField
                          isRequired={true}
                          fieldname="assistant_officersMailingCity"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'City',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.assistant_officersMailingCity && 'error'}
                          validMessage={
                            errors.assistant_officersMailingCity && errors.assistant_officersMailingCity.message
                          }
                        />
                      </Col>

                      <Col span={6}>
                        <InputField
                          isRequired={true}
                          fieldname="assistant_officersMailingZip"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Zip',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.assistant_officersMailingZip && 'error'}
                          validMessage={
                            errors.assistant_officersMailingZip && errors.assistant_officersMailingZip.message
                          }
                        />
                      </Col>
                      {(errors.assistant_officersMailingZip ||
                        errors.assistant_officersMailingCity ||
                        errors.assistant_officersMailingStreet) && (
                        <Col span={24} className="mailingError">
                          Mailing Address is required.
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={12}>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <label className="" title="Phone Number">
                          Phone Number
                        </label>
                        <div className="optional-label" style={{ top: '4px' }}>
                          (Optional)
                        </div>
                      </div>
                    </div>
                    <InputMask mask="(999) 999-9999" value={assistantPhone} onChange={onChangeAssistant}>
                      {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                    </InputMask>
                  </Col>

                  <Col span={12}>
                    <InputField
                      fieldname="assistant_officersEmail"
                      label="Email"
                      optionalLabel={assistantCheck ? false : '(Optional)'}
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      isRequired={assistantCheck ? true : false}
                      rules={{
                        required: assistantCheck ? 'Email is required.' : false,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: 'Check if the email is valid',
                        },
                      }}
                      validate={assistantCheck ? errors.assistant_officersEmail && 'error' : false}
                      validMessage={
                        assistantCheck
                          ? errors.assistant_officersEmail && errors.assistant_officersEmail.message
                          : false
                      }
                    />
                  </Col>

                  <Col span={24}>
                    <InputCheckbox
                      fieldname="assistant_checkbox"
                      label="Email copy of notices"
                      class={`mb-0 one-checbox`}
                      control={control}
                      onChange={(e) => setAssistantCheck(e)}
                    />
                  </Col>

                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <InputRadio
                          fieldname="contributingAssistant"
                          label="Will this person be contributing to this committee?"
                          control={control}
                          onChange={(e) => setContributingAssistant(e.target.value)}
                          classField="tab-type-radio"
                          options={
                            <>
                              <Row gutter={[20, 20]}>
                                <Col span={12}>
                                  <Radio value="contributingAssistantNo">No</Radio>
                                </Col>
                                <Col span={12}>
                                  <Radio value="contributingAssistantYes">Yes</Radio>
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </Col>
                      {contributingAssistant == 'contributingAssistantYes' && (
                        <>
                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <InputRadio
                                  fieldname="CA_jobRadio"
                                  control={control}
                                  initValue={'CA_JobTitle'}
                                  classField="small-radio"
                                  onChange={(e) => setCAJobTitle(e.target.value)}
                                  options={
                                    <>
                                      <Radio value="CA_JobTitle">Job Title</Radio>
                                      <Radio value="CA_Profession">Profession</Radio>
                                    </>
                                  }
                                />
                              </Col>
                              <Col span={24}>
                                {CAJobTitle == 'CA_JobTitle' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="assistant_jobTitle"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: 'Job Title',
                                    }}
                                    rules={{
                                      required: 'Job Title is required.',
                                    }}
                                    validate={errors.assistant_jobTitle && 'error'}
                                    validMessage={errors.assistant_jobTitle && errors.assistant_jobTitle.message}
                                  />
                                )}

                                {CAJobTitle == 'CA_Profession' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="assistant_profession"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: 'Profession',
                                    }}
                                    rules={{
                                      required: 'Profession is required.',
                                    }}
                                    validate={errors.assistant_profession && 'error'}
                                    validMessage={errors.assistant_profession && errors.assistant_profession.message}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>

                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <InputRadio
                                  fieldname="CA_employeeRadio"
                                  control={control}
                                  initValue={'CA_employersName'}
                                  onChange={(e) => setCAEmployeeName(e.target.value)}
                                  classField="small-radio"
                                  options={
                                    <>
                                      <Radio value="CA_employersName">Employer's Name</Radio>
                                      <Radio value="CA_specificField">Specific Field</Radio>
                                    </>
                                  }
                                />
                              </Col>
                              <Col span={24}>
                                {CAEmployeeName == 'CA_employersName' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="assistant_employerName"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: "Employer's Name",
                                    }}
                                    rules={{
                                      required: "Employer's Name is required.",
                                    }}
                                    validate={errors.assistant_employerName && 'error'}
                                    validMessage={
                                      errors.assistant_employerName && errors.assistant_employerName.message
                                    }
                                  />
                                )}

                                {CAEmployeeName == 'CA_specificField' && (
                                  <SelectField
                                    isRequired={true}
                                    fieldname="assistant_specificField"
                                    control={control}
                                    class="mb-0"
                                    iProps={{ placeholder: 'Specific Field' }}
                                    rules={{ required: 'Specific Field is required.' }}
                                    initValue=""
                                    selectOption={specificField}
                                    validate={errors.assistant_specificField && 'error'}
                                    validMessage={
                                      errors.assistant_specificField && errors.assistant_specificField.message
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </>
              )}

              {custodianBooks && (
                <>
                  <Col span={24}>
                    <Title level={2} className="text-center mb-0 font-gilroy">
                      Custodian of Books
                    </Title>
                    <div className="right-cross-btn">
                      <Button
                        type="text"
                        htmlType="button"
                        className="font-gilroy c-grey d-flex align-center"
                        onClick={() => closeCustodianBooksClick()}
                      >
                        <Image src={trashIcon} preview={false} size={24} />
                      </Button>
                    </div>
                  </Col>

                  <Col span={6}>
                    <InputField
                      isRequired={true}
                      fieldname="custodian_officersFirstName"
                      label="Name"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'First',
                      }}
                      rules={{
                        required: 'Name is required.',
                      }}
                      validate={errors.custodian_officersFirstName && 'error'}
                      validMessage={errors.custodian_officersFirstName && errors.custodian_officersFirstName.message}
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      fieldname="custodian_middle_name"
                      label=" "
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Middle',
                      }}
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      fieldname="custodian_officersLastName"
                      label=" "
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Last',
                      }}
                    />
                  </Col>

                  <Col span={6}>
                    <SelectField
                      fieldname="custodian_suffix"
                      label=" "
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Suffix', isClearable: true }}
                      initValue=""
                      selectOption={suffixData}
                    />
                  </Col>

                  <Col span={24} className="mailingField">
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <InputField
                          isRequired={true}
                          fieldname="custodian_officersMailingStreet"
                          label="Mailing Address"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Street Address',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.custodian_officersMailingStreet && 'error'}
                          validMessage={
                            errors.custodian_officersMailingStreet && errors.custodian_officersMailingStreet.message
                          }
                        />
                      </Col>

                      <Col span={9}>
                        <SelectField
                          isRequired={true}
                          fieldname="custodian_officersMailingcountryList"
                          control={control}
                          class="mb-0"
                          iProps={{ placeholder: 'Please select' }}
                          rules={{ required: 'Mailing Address is required.' }}
                          selectOption={countryList}
                          onChange={(e) => selectedCountryCus(e)}
                          validate={errors.custodian_officersMailingcountryList && 'error'}
                          validMessage={
                            errors.custodian_officersMailingcountryList &&
                            errors.custodian_officersMailingcountryList.message
                          }
                        />
                      </Col>

                      <Col span={9}>
                        <InputField
                          isRequired={true}
                          fieldname="custodian_officersMailingCity"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'City',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.custodian_officersMailingCity && 'error'}
                          validMessage={
                            errors.custodian_officersMailingCity && errors.custodian_officersMailingCity.message
                          }
                        />
                      </Col>

                      <Col span={6}>
                        <InputField
                          isRequired={true}
                          fieldname="custodian_officersMailingZip"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Zip',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.custodian_officersMailingZip && 'error'}
                          validMessage={
                            errors.custodian_officersMailingZip && errors.custodian_officersMailingZip.message
                          }
                        />
                      </Col>
                      {(errors.custodian_officersMailingZip ||
                        errors.custodian_officersMailingCity ||
                        errors.custodian_officersMailingStreet) && (
                        <Col span={24} className="mailingError">
                          Mailing Address is required.
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={12}>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <label className="" title="Phone Number">
                          Phone Number
                        </label>
                        <div className="optional-label" style={{ top: '4px' }}>
                          (Optional)
                        </div>
                      </div>
                    </div>
                    <InputMask mask="(999) 999-9999" value={custodianPhone} onChange={onChangeCustodian}>
                      {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                    </InputMask>
                  </Col>

                  <Col span={12}>
                    <InputField
                      fieldname="custodian_officersEmail"
                      label="Email"
                      optionalLabel={custodianCheck ? false : '(Optional)'}
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      isRequired={custodianCheck ? true : false}
                      rules={{
                        required: custodianCheck ? 'Email is required.' : false,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: 'Check if the email is valid',
                        },
                      }}
                      validate={custodianCheck ? errors.custodian_officersEmail && 'error' : false}
                      validMessage={
                        custodianCheck
                          ? errors.custodian_officersEmail && errors.custodian_officersEmail.message
                          : false
                      }
                    />
                  </Col>

                  <Col span={24}>
                    <InputCheckbox
                      fieldname="custodian_books_check"
                      label="Email copy of notices"
                      class={`mb-0 one-checbox`}
                      control={control}
                      onChange={(e) => setCustodianCheck(e)}
                    />
                  </Col>

                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <InputRadio
                          fieldname="custodian_contributingAssistant"
                          label="Will this person be contributing to this committee?"
                          control={control}
                          onChange={(e) => setCustodianBooksRadio(e.target.value)}
                          classField="tab-type-radio"
                          options={
                            <>
                              <Row gutter={[20, 20]}>
                                <Col span={12}>
                                  <Radio value="CB_No">No</Radio>
                                </Col>
                                <Col span={12}>
                                  <Radio value="CB_Yes">Yes</Radio>
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </Col>
                      {custodianBooksRadio == 'CB_Yes' && (
                        <>
                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <InputRadio
                                  fieldname="CB_jobRadio"
                                  control={control}
                                  initValue={'CB_JobTitle'}
                                  classField="small-radio"
                                  onChange={(e) => setCBJobTitle(e.target.value)}
                                  options={
                                    <>
                                      <Radio value="CB_JobTitle">Job Title</Radio>
                                      <Radio value="CB_Profession">Profession</Radio>
                                    </>
                                  }
                                />
                              </Col>
                              <Col span={24}>
                                {CBJobTitle == 'CB_JobTitle' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="custodian_jobTitle"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: 'Job Title',
                                    }}
                                    rules={{
                                      required: 'Job Title is required.',
                                    }}
                                    validate={errors.custodian_jobTitle && 'error'}
                                    validMessage={errors.custodian_jobTitle && errors.custodian_jobTitle.message}
                                  />
                                )}

                                {CBJobTitle == 'CB_Profession' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="custodian_profession"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: 'Profession',
                                    }}
                                    rules={{
                                      required: 'Profession is required.',
                                    }}
                                    validate={errors.custodian_profession && 'error'}
                                    validMessage={errors.custodian_profession && errors.custodian_profession.message}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>

                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <InputRadio
                                  fieldname="CB_employeeRadio"
                                  control={control}
                                  initValue={'CB_employersName'}
                                  onChange={(e) => setCBEmployeeName(e.target.value)}
                                  classField="small-radio"
                                  options={
                                    <>
                                      <Radio value="CB_employersName">Employer's Name</Radio>
                                      <Radio value="CB_specificField">Specific Field</Radio>
                                    </>
                                  }
                                />
                              </Col>
                              <Col span={24}>
                                {CBEmployeeName == 'CB_employersName' && (
                                  <InputField
                                    isRequired={true}
                                    fieldname="custodian_employerName"
                                    control={control}
                                    initValue=""
                                    iProps={{
                                      size: 'large',
                                      placeholder: "Employer's Name",
                                    }}
                                    rules={{
                                      required: "Employer's Name is required.",
                                    }}
                                    validate={errors.custodian_employerName && 'error'}
                                    validMessage={
                                      errors.custodian_employerName && errors.custodian_employerName.message
                                    }
                                  />
                                )}

                                {CBEmployeeName == 'CB_specificField' && (
                                  <SelectField
                                    isRequired={true}
                                    fieldname="custodian_specificField"
                                    control={control}
                                    class="mb-0"
                                    iProps={{ placeholder: 'Specific Field' }}
                                    rules={{ required: 'Specific Field is required.' }}
                                    initValue=""
                                    selectOption={specificField}
                                    validate={errors.custodian_specificField && 'error'}
                                    validMessage={
                                      errors.custodian_specificField && errors.custodian_specificField.message
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </>
              )}

              {assistantTreasurerButton && (
                <Col span="12">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey d-flex align-center"
                    onClick={() => assistantTreasurerClick()}
                  >
                    <Image src={plusIcon} preview={false} size={24} />
                    Assistant Treasurer
                  </Button>
                </Col>
              )}

              {custodianBooksButton && (
                <Col span="12">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey d-flex align-center"
                    onClick={() => custodianBooksClick()}
                  >
                    <Image src={plusIcon} preview={false} size={24} />
                    Custodian of Books
                  </Button>
                </Col>
              )}
            </Row>
          </Col>

          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
              Continue
            </Button>
          </Col>

          <Col span={24} className="text-center mt-2 mb-1">
            <Button
              type="text"
              htmlType="button"
              className="font-gilroy c-grey"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              BACK
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
