import { combineReducers } from 'redux';
import userReducer from '../../features/userSlice';
import dashboard from '../../app/modules/Dashboard/ducks/reducers';
import receipt from '../../app/modules/Dashboard/Receipts/ducks/reducers';
import expenditure from '../../app/modules/Dashboard/Expenditures/ducks/reducers';
import exemptSales from '../../app/modules/Dashboard/ExemptSales/ducks/reducers';
import contact from '../../app/modules/Dashboard/Contacts/ducks/reducers';
import report from '../../app/modules/Dashboard/Reports/ducks/reducers';
import setting from '../../app/modules/Dashboard/Settings/ducks/reducers';
import debts from '../../app/modules/Dashboard/Debts/ducks/reducers';
import adminData from '../../app/modules/Dashboard/AdminData/ducks/reducers';

const rootReducer = combineReducers({
  user: userReducer,
  dashboard,
  receipt,
  expenditure,
  contact,
  report,
  setting,
  exemptSales,
  adminData,
  debts,
});

export default rootReducer;
