import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getExpenditureFormData } from '../../ducks/actions';
import { REFUND_PURPOSE_CODE, FORMS_OF_PAYMENTS } from '../../../../../../configs/constants';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const [formOfPayment, setFormOfPayment] = useState('');
  const [purposeCode, setPurposeCode] = useState(false);
  const dispatch = useDispatch();
  const expenditureFormData = useSelector((state) => state.expenditure.expenditureFormData);
  //console.log(JSON.stringify(expenditureFormData));

  useEffect(() => {
    dispatch(getExpenditureFormData('refund', 'null'));
  }, []);

  useEffect(() => {
    if (expenditureFormData && expenditureFormData?.expenditureData && Object.keys(expenditureFormData)?.length > 0) {
      const selectedAccountList = expenditureFormData?.accounts?.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.account_id,
      );
      const selectedReImbursementReceipt = expenditureFormData?.contributors?.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.receipt_refunded_type,
      );
      let selectedPurposeCode = [];
      expenditureFormData?.expenditureData?.purpose_code?.map((x) => {
        selectedPurposeCode.push({
          label: REFUND_PURPOSE_CODE?.find((y) => y?.value == x.toLowerCase())?.label,
          value: x,
        });
      });

      setValue('amount', expenditureFormData.expenditureData?.amount);
      setValue(
        'date',
        expenditureFormData?.expenditureData?.date
          ? moment(expenditureFormData?.expenditureData?.date, 'YYYY-MM-DD HH:mm')
          : '',
      );
      setValue('receipt_refunded_type', selectedReImbursementReceipt);
      setValue('purpose_code', selectedPurposeCode);
      setValue('comments', expenditureFormData.expenditureData?.comments);
      setValue('payment_type', expenditureFormData.expenditureData?.payment_type);
      setFormOfPayment(expenditureFormData.expenditureData?.payment_type);
      setTimeout(() => {
        setValue('check_number', expenditureFormData.expenditureData?.check_number);
        setValue('in_kind_description', expenditureFormData.expenditureData?.in_kind_description);
        setValue('account', selectedAccountList);
        setValue('remarks', expenditureFormData.expenditureData?.remarks);
        purposeSelection(selectedPurposeCode);
        setLoading(false);
      }, 1000);
    }
  }, [expenditureFormData]);

  const purposeSelection = (e) => {
    if (
      e.some(
        (resp) =>
          resp.value === 'a' ||
          resp.value === 'b' ||
          resp.value === 'c' ||
          resp.value === 'f' ||
          resp.value === 'h' ||
          resp.value === 'k' ||
          resp.value === 'o',
      )
    ) {
      setPurposeCode(true);
    } else {
      setPurposeCode(false);
    }
  };

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (expenditureFormData && expenditureFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', expenditureFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="receipt_refunded_type"
              label="Receipt Refunded/Reimbursed"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
              }}
              rules={{
                required: 'Receipt Refunded/Reimbursed is required.',
              }}
              selectOption={expenditureFormData?.contributors}
              validate={errors.receipt_refunded_type && 'error'}
              validMessage={errors.receipt_refunded_type && errors.receipt_refunded_type.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Amount Refunded/Reimbursed"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Amount Refunded/Reimbursed is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Refund/Reimbursement Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Refund/Reimbursement Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="purpose_code"
              label="Refund/Reimbursement Purpose Code"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
                isMulti: true,
              }}
              rules={{
                required: 'Refund/Reimbursement Purpose Code is required.',
              }}
              onChange={(e) => purposeSelection(e)}
              selectOption={REFUND_PURPOSE_CODE}
              validate={errors.purpose_code && 'error'}
              validMessage={errors.purpose_code && errors.purpose_code.message}
            />
          </Col>

          {purposeCode && (
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="remarks"
                label="Required Remarks"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'Required Remarks is required.',
                }}
                validate={errors.remarks && 'error'}
                validMessage={errors.remarks && errors.remarks.message}
              />
            </Col>
          )}

          <Col span={24}>
            <TextAreaField
              fieldname="comments"
              label="Comments"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              optionalLabel={true}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputRadio
              fieldname="payment_type"
              label="Form of Payment"
              control={control}
              initValue={''}
              classField="custom-radio"
              onChange={(e) => formPayment(e)}
              options={
                <>
                  {FORMS_OF_PAYMENTS &&
                    FORMS_OF_PAYMENTS?.map((item, index) => (
                      <Fragment key={index}>
                        <Radio.Button value={item?.value}>
                          <Image src={item?.image} preview={false} width={80} />
                          <Paragraph className="smallFont12">{item?.label}</Paragraph>
                        </Radio.Button>
                      </Fragment>
                    ))}
                </>
              }
              rules={{
                required: 'Form of Payment is required.',
              }}
              validate={errors.payment_type && 'error'}
              validMessage={errors.payment_type && errors.payment_type.message}
            />
          </Col>

          {formOfPayment && (
            <Col span={24}>
              {formOfPayment != 'in_kind' && (
                <Row gutter={16}>
                  {formOfPayment == 'check' && (
                    <Col span={12}>
                      <InputField
                        fieldname="check_number"
                        label="Check Number"
                        control={control}
                        initValue=""
                        optionalLabel="(Optional)"
                        iProps={{
                          size: 'large',
                        }}
                      />
                    </Col>
                  )}
                  <Col span={formOfPayment == 'check' ? 12 : 24}>
                    <SelectField
                      isRequired={true}
                      fieldname="account"
                      label="Account"
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      rules={{ required: 'Account is required.' }}
                      initValue=""
                      selectOption={expenditureFormData?.accounts}
                      validate={errors.account && 'error'}
                      validMessage={errors.account && errors.account.message}
                    />
                  </Col>
                </Row>
              )}

              {formOfPayment == 'in_kind' && (
                <Col span={24}>
                  <InputField
                    isRequired={true}
                    fieldname="in_kind_description"
                    label="In-Kind Description"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'In-Kind Description is required.',
                    }}
                    validate={errors.in_kind_description && 'error'}
                    validMessage={errors.in_kind_description && errors.in_kind_description.message}
                  />
                </Col>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
