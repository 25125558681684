import React from 'react';
import { Button, Row, Col, Typography, Image, Card } from 'antd';
import crossIcon from '../../../../../assets/svg/cross-icon.svg';

const { Title, Text } = Typography;

export default (props) => {
  const { onClose, title, typeSelected, setPopupType } = props;
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col flex="0 0 462px">
              <Row className="text-center" gutter={[8, 8]}>
                <Col span={24}>
                  <Title level={3}>Contact Type</Title>
                </Col>
                <Col span={24} className="receipt-card">
                  <Card onClick={() => (setPopupType('individual'), typeSelected())}>
                    <Text>Individual</Text>
                  </Card>
                </Col>

                <Col span={24} className="receipt-card">
                  <Card onClick={() => (setPopupType('other_organization'), typeSelected())}>
                    <Text>Other Organization</Text>
                  </Card>
                </Col>

                <Col span={24} className="receipt-card">
                  <Card onClick={() => (setPopupType('political_committee'), typeSelected())}>
                    <Text>Political Committee</Text>
                  </Card>
                </Col>

                <Col span={24} className="receipt-card">
                  <Card onClick={() => (setPopupType('financial_institution'), typeSelected())}>
                    <Text>Financial Institution</Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
