import React, { Fragment } from 'react';
import { Row, Col, Button, Image } from 'antd';
import crossIcon from '../../../../../../../../assets/svg/cross-icon.svg';

export default (props) => {
  const { data, onClose } = props;
  return (
    <>
      <Row justify="end" align="middle">
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
      </Row>

      <iframe srcDoc={data} width="100%" height="650px" frameBorder="0" />
    </>
  );
};
