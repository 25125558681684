import * as action_types from './constants';

const initialState = {
  dashboardData: null,
  availableReportsData: {},
  currentCycleData: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.DASHBOARD_DATA:
      return { ...state, dashboardData: data };

    case action_types.AVAILABLE_REPORTS:
      return { ...state, availableReportsData: data };

    case action_types.CURRENT_CYCLE:
      return { ...state, currentCycleData: data };

    default:
      return state;
  }
};
