import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Spin, Button, Image, Row, Col, Typography, Radio } from 'antd';
import { InputField, SelectField2, SelectField, DateField2 } from '../../../../../../../../../../atoms/FormElement';

const { Title } = Typography;

export default ({ purposeName, remarksName, control, setValue, watch, purposeDrop, item }) => {
  let remarks = '';
  remarks = watch(purposeName);
  return (
    <>
      <Col span={12}>
        <SelectField
          fieldname={purposeName}
          label="Purpose Code"
          control={control}
          initValue={
            item?.purpose_code &&
            item?.purpose_code?.length &&
            item?.purpose_code.map((x) => purposeDrop.find((y) => y.value == x))
          }
          iProps={{
            size: 'large',
            placeholder: 'Please select',
            isMulti: true,
          }}
          selectOption={purposeDrop}
        />
      </Col>

      {remarks &&
      remarks?.length > 0 &&
      remarks?.some(
        (resp) =>
          resp?.value === 'A' ||
          resp?.value === 'B' ||
          resp?.value === 'C' ||
          resp?.value === 'F' ||
          resp?.value === 'H' ||
          resp?.value === 'K' ||
          resp?.value === 'O',
      ) ? (
        <Col span={12}>
          <InputField
            fieldname={remarksName}
            label="Required Remarks"
            control={control}
            initValue={item?.required_remarks}
            iProps={{
              size: 'large',
            }}
          />
        </Col>
      ) : null}
    </>
  );
};
