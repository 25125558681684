import * as action_types from './constants';

const initialState = {
  contactFormData: {},
  checkUnimportedContact: null,
  duplicateContactFormData: {},
  unimportedContactFormData: {},
  contactFormDataPayee: {},
  contactDropData: {},
  contactListData: [],
  bankAccountsList: [],
  filter: {
    searchContact: undefined,
    contributedAmount: undefined,
    paidAmount: undefined,
  },
  filterImport: {
    showMandatory: undefined,
    showDublicative: undefined,
  },
  exportContact: [],
  unimportedContact: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.CONTACT_FORM_DATA:
      return { ...state, contactFormData: data };

    case action_types.CHECK_UNIMPORTED_CONTACTS:
      return { ...state, checkUnimportedContact: data };

    case action_types.DUPLICATE_CONTACT_FORM_DATA:
      return { ...state, duplicateContactFormData: data };

    case action_types.UNIMPORTED_CONTACT_FORM_DATA:
      return { ...state, unimportedContactFormData: data };

    case action_types.CONTACT_FORM_DATA_PAYEE:
      return { ...state, contactFormDataPayee: data };

    case action_types.CONTACT_DROP_DATA:
      return { ...state, contactDropData: data };

    case action_types.CONTACT_LIST_DATA:
      return { ...state, contactListData: data };

    case action_types.BANK_ACCOUNTS_LIST:
      return { ...state, bankAccountsList: data };

    case action_types.EXPORT_CANTACT:
      return { ...state, exportContact: data };

    case action_types.UNIMPORTED_CONTACT_LIST_DATA:
      return { ...state, unimportedContact: data };

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } };

    case action_types.SET_FILTERS_OPTIONS_IMPORT:
      return { ...state, filterImport: { ...state.filterImport, ...action.payload.filterImport } };

    case action_types.CLEAR_CONTACT_FILTER:
      return {
        ...state,
        filter: {
          searchContact: undefined,
          contributedAmount: undefined,
          paidAmount: undefined,
        },
      };

    default:
      return state;
  }
};
