import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Tooltip } from 'antd';
import moment from 'moment';
import { InputField, SelectField, DateField2 } from '../../../../../atoms/FormElement';
import plusIcon from '../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../assets/svg/trash-icon.svg';
import { EXPENDITURE_PURPOSE_CODE } from '../../../../../../configs/constants';
import PurposeCode from '../PurposeCode';
import ContactBoxNoDetail from '../../../../../molecules/Dashboard/ContactBoxNoDetail';

const { Paragraph, Title } = Typography;

const initQ = {
  incurred_id: '',
  amount: '',
  date: '',
  contact_id: '',
  purpose_code: '',
  remarks: '',
};

export default (props) => {
  const {
    fields,
    watch,
    control,
    errors,
    setValue,
    append,
    remove,
    deletedIndexes,
    setDeletedIndexes,
    isOld,
    payeeOption,
    updateDropdownValues,
  } = props;
  const [contactToSelect, setContactToSelect] = useState(null);

  const onRemove = (e, index) => {
    remove(index);
    if (e?.incurred_id) {
      setDeletedIndexes([...deletedIndexes, e?.incurred_id]);
    }
  };
  const onAdd = () => {
    append(initQ);
  };

  return (
    <>
      <Col span={24}>
        {fields?.map((item, index) => {
          return (
            <Fragment key={item?.id}>
              <Row
                gutter={[20, 20]}
                align="bottom"
                style={{
                  marginBottom: '20px',
                  paddingTop: '15px',
                  position: 'relative',
                  borderTop: index == 0 ? '0' : '1px solid #D2D6E2',
                }}
              >
                <Col span={24} className="d-none">
                  <InputField
                    fieldname={`incurred_debts.${index}.incurred_id`}
                    label=""
                    control={control}
                    initValue={item?.incurred_id}
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    fieldname={`incurred_debts.${index}.amount`}
                    label="Amount"
                    control={control}
                    initValue={item?.amount}
                    iProps={{
                      size: 'large',
                      prefix: '$',
                      type: 'number',
                    }}
                    isRequired={!isOld ? true : false}
                    rules={{
                      required: !isOld ? 'Amount is required.' : null,
                    }}
                    validate={
                      !isOld &&
                      Object.entries(errors).length > 0 &&
                      errors?.incurred_debts?.length > 0 &&
                      errors?.incurred_debts[index]?.amount &&
                      'error'
                    }
                    validMessage={
                      !isOld &&
                      Object.entries(errors).length > 0 &&
                      errors?.incurred_debts?.length > 0 &&
                      errors?.incurred_debts[index]?.amount &&
                      errors?.incurred_debts[index]?.amount?.message
                    }
                  />
                </Col>

                <Col span={24}>
                  <DateField2
                    fieldname={`incurred_debts.${index}.date`}
                    label="Date"
                    control={control}
                    class="mb-0"
                    iProps={{ size: 'large' }}
                    initValue={item?.date ? moment(item?.date, 'MM-DD-YYYY') : ''}
                    isRequired={!isOld ? true : false}
                    rules={{
                      required: !isOld ? 'Date is required.' : null,
                    }}
                    validate={
                      !isOld &&
                      Object.entries(errors).length > 0 &&
                      errors?.incurred_debts?.length > 0 &&
                      errors?.incurred_debts[index]?.date &&
                      'error'
                    }
                    validMessage={
                      !isOld &&
                      Object.entries(errors).length > 0 &&
                      errors?.incurred_debts?.length > 0 &&
                      errors?.incurred_debts[index]?.date &&
                      errors?.incurred_debts[index]?.date?.message
                    }
                  />
                </Col>

                <ContactBoxNoDetail
                  control={control}
                  errors={errors}
                  selectData={payeeOption}
                  setValue={setValue}
                  contactToSelect={contactToSelect}
                  setContactToSelect={setContactToSelect}
                  updateDropdownValues={updateDropdownValues}
                  fieldname={`incurred_debts.${index}.contact_id`}
                  label="Purchase Place / Payee"
                  initValue={item?.contact_id && payeeOption && payeeOption.find((x) => item?.contact_id == x?.value)}
                  hintMessage="If you don’t know the place, keep empty and it will be pre-populated with the creditor's name."
                />

                {/* <Col span={24}>
                  <SelectField
                    fieldname={`incurred_debts.${index}.contact_id`}
                    label="Purchase Place / Payee"
                    control={control}
                    initValue={item?.contact_id && payeeOption && payeeOption.find((x) => item?.contact_id == x?.value)}
                    iProps={{
                      size: 'large',
                      placeholder: 'Please select',
                    }}
                    selectOption={payeeOption}
                    hintMessage="If you don’t know the place, keep empty and it will be pre-populated with the creditor's name."
                    setValue={setValue}
                  />
                </Col> */}

                <PurposeCode
                  purposeName={`incurred_debts.${index}.purpose_code`}
                  remarksName={`incurred_debts.${index}.required_remarks`}
                  {...{ control }}
                  setValue={setValue}
                  watch={watch}
                  purposeDrop={EXPENDITURE_PURPOSE_CODE}
                  item={item}
                  isOld={isOld}
                  index={index}
                  errors={errors}
                />
                {fields?.length > 1 && (
                  <div className="accounts_remove" style={{ top: 52 }}>
                    <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                      <Image src={trashIcon} preview={false} size={24} />
                    </Button>
                  </div>
                )}
              </Row>
            </Fragment>
          );
        })}
      </Col>
      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Item
        </Button>
      </Col>
    </>
  );
};
