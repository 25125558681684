import React, { useState } from 'react';
import { Button, Row, Col, Typography, Image, DatePicker, Spin, Card, Form, Radio, message, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import addManually from '../../../assets/svg/add-manually.svg';
import uploadCSVIcon from '../../../assets/svg/uploadCSV.svg';
import downloadCSV from '../../../assets/svg/download-csv.svg';
import uploadIcon from '../../../assets/svg/upload.svg';
import checkIcon from '../../../assets/svg/check-icon.svg';
import vendorLogo1 from '../../../assets/svg/vendor-logo1.svg';
import vendorLogo2 from '../../../assets/svg/vendor-logo2.svg';
import vendorLogo3 from '../../../assets/svg/vendor-logo3.svg';
import vendorLogo4 from '../../../assets/svg/vendor-logo4.svg';
import vendorLogo5 from '../../../assets/svg/vendor-logo5.svg';
import vendorLogo6 from '../../../assets/svg/vendor-logo6.svg';
import infoIcon from '../../../assets/svg/info-icon.svg';
import uploadFileIcon from '../../../assets/svg/upload-file-icon.svg';
import trashIcon from '../../../assets/svg/icon-trash.svg';
import { baseUrl } from '../../../configs/constants';
import { BULK_IMPORT_CONTACT_CSV } from '../../../configs/endpoints';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UploadField } from '../../atoms/FormElement';
import { exportContact } from '../../modules/Dashboard/Contacts/ducks/actions';
import { addObject } from '../../../services/crudApis';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, paragraph, manualLink, afterUploadLink, bulkImportLink } = props;
  const token = JSON.parse(localStorage.getItem('token'));
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, errors, clearErrors, handleSubmit, setValue } = useForm({
    defaultValues: {
      accounts_committee: [{ name: 'useFieldArray' }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [uploadVendor, setUploadVendor] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileList, setFileList] = useState([]);

  const closePopUp = () => {
    setUploadCSV(false);
    setUploadType(null);
    setFileUpload(null);
    setUploadVendor(null);
    onClose();
  };

  const onTypeSelect = (e) => {
    setUploadType(e.target.value);
  };

  const onVendorSelect = (e) => {
    setUploadVendor(e.target.value);
    setFileUpload(null);
    setFileList([]);
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    maxCount: 1,
    // action: baseUrl + '/' + bulkImportLink,
    // headers: { Authorization: `Bearer ${token.access_token}` },
    // data: { parser: uploadVendor },

    onChange(info) {
      const { status } = info.file;
      clearErrors('uploadCSV');
      setFileList(info.fileList);
      if (status === 'done') {
        dispatch(exportContact(info.file.response));
      }
    },

    beforeUpload(e) {
      setFileUpload(e);
      const isCSV = e.type === 'text/csv';
      if (!isCSV) {
        setFileUpload(null);
        message.error(`${e.name} is not a CSV file`);
      }
      return isCSV || Upload.LIST_IGNORE;
    },

    onRemove(e) {
      setFileUpload(null);
    },
  };

  const onFinish = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', fileUpload);
    formData.append('parser', uploadVendor);
    addObject(bulkImportLink, formData)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          history.push(afterUploadLink);
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const continuePopup = () => {
    if (uploadType === 'upload') {
      setUploadCSV(true);
    } else {
      history.push(manualLink);
    }
  };

  const removeFile = () => {
    setFileUpload(null);
    setFileList([]);
  };

  const handleFileChange = (e) => {
    setFileUpload(e);
    const isCSV = e.type === 'text/csv';
    if (!isCSV) {
      setFileUpload(null);
      message.error(`${e.name} is not a CSV file`);
    }
    return isCSV || Upload.LIST_IGNORE;
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle" className="new_popup">
        {uploadCSV ? (
          <Form layout="vertical" name="normal_login" className="upload_form" onFinish={handleSubmit(onFinish)}>
            <Col span={24}>
              <Row justify="space-between" align="middle" className="popup_header">
                <Col>
                  <Title level={2} className="mb-0">
                    Import Transactions
                  </Title>
                </Col>
                <Col className="popup_close_btn">
                  <Button size="large" type="link" className="p-0" onClick={closePopUp}>
                    <Image src={crossIcon} preview={false} size={52} />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Title level={4}>Upload CSV with Receipts & Expenditures</Title>
            </Col>
            <Col span={24} className="method_select vendor_select">
              <Col span={24}>
                <Paragraph className="font_16">Select the vendor you exported the CSV from</Paragraph>
              </Col>
              <Radio.Group onChange={onVendorSelect} value={uploadVendor}>
                <Radio.Button value="Anedot">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo1} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Anedot</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="Winred">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo2} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">WinRed</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="Actblue">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo3} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">ActBlue</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="Numero">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo4} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Numero</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="Online_Banking">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo6} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Online Banking</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="Other">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={20} preview={false} />
                    </div>
                    <Image src={vendorLogo5} width={50} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Other</Paragraph>
                  </Card>
                </Radio.Button>
              </Radio.Group>
            </Col>
            {uploadVendor && (
              <>
                <Col span={24}>
                  {uploadVendor !== 'Online_Banking' ? (
                    <Paragraph className="font_16">
                      The CSV file must follow the specific format. <br /> Use the sample file below to prepare the
                      transactions for importation to ElectaFile&reg;.{' '}
                      {uploadVendor !== 'Other' && (
                        <>
                          <br />
                          <br /> Prior to uploading your CSV into ElectaFile&reg; you will need to insert a State Board
                          Account Code column into your CSV with the title "Bank Account Code In ElectaFile". For each
                          transaction listed on your CSV, you'll need to input the correct Account Code. For example, if
                          you have some transactions to Account Code 001 and some transactions to Account Code 002,
                          you'd simply add 001 or 002 to each transaction as appropriate. You'd then save and upload the
                          modified CSV.
                        </>
                      )}
                    </Paragraph>
                  ) : (
                    <Paragraph className="font_16">
                      Use this option when you have a bank statement CSV file exported from an online banking system.
                      <br />
                      <br />
                      The CSV file must follow the specific format. Use the sample file below to prepare the
                      transactions for importation to ElectaFile&reg;.
                      <br />
                      <br /> Prior to uploading your CSV into ElectaFile&reg; you will need to insert a State Board
                      Account Code column into your CSV with the title "Bank Account Code In ElectaFile". For each
                      transaction listed on your CSV, you'll need to input the correct Account Code. For example, if you
                      have some transactions to Account Code 001 and some transactions to Account Code 002, you'd simply
                      add 001 or 002 to each transaction as appropriate. You'd then save and upload the modified CSV.
                    </Paragraph>
                  )}
                </Col>
                <Col span={24}>
                  <div className="button_icon">
                    <a
                      href={`https://app.electafile.com/assets/csv/Transaction_Import_Sample_-_${uploadVendor}.csv`}
                      target="_blank"
                      rel="noreferrer"
                      download
                      className="download_csv"
                    >
                      <Image src={downloadCSV} preview={false} />
                      <span className="download_text">DOWNLOAD CSV SAMPLE </span>
                    </a>
                  </div>
                </Col>
                <Col span={24} className="upload_csv">
                  <UploadField
                    fieldname="uploadCSV"
                    label=""
                    control={control}
                    dummyRequest=""
                    initValue=""
                    draggerProps={draggerProps}
                    image={uploadIcon}
                    content="Upload Here"
                    iProps={{
                      size: 'large',
                      customRequest: () => {},
                      beforeUpload: handleFileChange,
                      fileList: fileList,
                    }}
                  />
                  {fileUpload?.name && (
                    <div className="upload_file_info">
                      <Image src={uploadFileIcon} preview={false} width={52} />
                      <div className="file_name">{fileUpload?.name}</div>
                      <Button type="text" onClick={removeFile} className="remove_icon">
                        <Image src={trashIcon} preview={false} width={22} />
                      </Button>
                    </div>
                  )}
                </Col>
              </>
            )}
            <Col span={24}>
              <div className="new_btn_group">
                <Row justify="center" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="font-gilroy white-btn"
                      size="large"
                      onClick={closePopUp}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="secondary"
                      htmlType="submit"
                      className="font-gilroy bg-red"
                      size="large"
                      disabled={!fileUpload}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Form>
        ) : (
          <>
            <Col span={24}>
              <Row justify="space-between" align="middle" className="popup_header">
                <Col>
                  <Title level={4} className="mb-0">
                    {title}
                  </Title>
                </Col>
                <Col className="popup_close_btn">
                  <Button size="large" type="link" className="p-0" onClick={closePopUp}>
                    <Image src={crossIcon} preview={false} size={52} />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Paragraph className="font_16">{paragraph}</Paragraph>
            </Col>
            <Col span={24} className="method_select">
              <h5>Select Method</h5>
              <Radio.Group onChange={onTypeSelect} value={uploadType}>
                <Radio.Button value="manually">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={30} preview={false} />
                    </div>
                    <Image src={addManually} width={76} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Add Manually</Paragraph>
                  </Card>
                </Radio.Button>
                <Radio.Button value="upload">
                  <Card align="center" justify="middle">
                    <div className="check_image">
                      <Image src={checkIcon} width={30} preview={false} />
                    </div>
                    <Image src={uploadCSVIcon} width={76} preview={false} />
                    <Paragraph className="smallFont14 w-100 m-0">Upload CSV</Paragraph>
                  </Card>
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={24}>
              <div className="new_btn_group">
                <Row justify="center" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="font-gilroy white-btn"
                      size="large"
                      onClick={closePopUp}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="secondary"
                      htmlType="submit"
                      className="font-gilroy bg-red"
                      size="large"
                      onClick={continuePopup}
                      disabled={!uploadType}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Spin>
  );
};
