import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Typography, Image, Space, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import reportStatuses from '../../../../../assets/svg/report-statuses.svg';
import cancelPayment from '../../../../../assets/svg/cancel-payment.svg';
import infoIcon from '../../../../../assets/svg/info-icon.svg';
import Dashboard from '../../../../pages/Dashboard';
import { DownloadIcon } from '../../../../atoms/CustomIcons';
import { addObject, downloadFile, saveFile } from '../../../../../services/crudApis';
import {
  SUBMIT_REPORT,
  DOWNLOAD_REPORT_SUMMARY_BY_ID,
  DOWNLOAD_REPORT_COVER_BY_ID,
} from '../../../../../configs/endpoints';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined spin />;
const { Title, Text, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reportId, setReportId] = useState(false);
  const [errorPaid, setErrorPaid] = useState(false);
  const [address, setAddress] = useState({ line1: '', line2: '', line3: '', hideBox: true });
  const [success, setSuccess] = useState(true);
  const history = useHistory();
  const [isFederalPAC, setIsFederalPAC] = useState(true);
  const federalPAC = localStorage.getItem('organization');

  useEffect(() => {
    if (federalPAC === 'federal') {
      setIsFederalPAC(false);
    }
  }, [federalPAC]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const reportId = params.get('reportId');
    setReportId(reportId);

    addObject(SUBMIT_REPORT, { reportId: reportId })
      .then((response) => {
        if (response.data && response.data.success) {
          setAddress(response.data.data);
          setSuccess(response.data.success);
          setIsSubmitted(true);
        } else {
          message.error(response?.data?.message ?? 'Something went wrong.');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error?.response && error?.response?.data?.error?.statusCode == 402) {
          setErrorPaid(true);
        }
        message.error(error?.response?.data?.error?.message);
      });
  }, []);

  const downloadReportByCover = () => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_COVER_BY_ID + '/' + reportId).then((response) => {
        saveFile(response, 'Cover:' + reportId + '.pdf');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };

  const downloadReportBySummary = () => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_SUMMARY_BY_ID + '/' + reportId).then((response) => {
        saveFile(response, 'Summary:' + reportId + '.pdf');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };
  return (
    <Dashboard load={loading} heading={errorPaid ? 'Error' : 'Success'} tags={!errorPaid ? 'Sent' : ''}>
      <Card className="white-card w-100">
        {errorPaid ? (
          <Row justify="center">
            <Col span={24}>
              <Title level={3} className="text-center c-red">
                Payment Didn't Go Through
              </Title>
            </Col>
            <Col flex="0 0 400px">
              <Row className="text-center" gutter={[30, 30]}>
                <Col span={24}>
                  <Image width={136} src={cancelPayment} preview={false} />
                </Col>
                <Col span={24}>
                  <Text className="smallFont14">
                    Something went wrong with the payment. <br />
                    Your report was not submitted.
                  </Text>
                </Col>

                <Col span={24}>
                  <Row justify="center" gutter={[20, 20]}>
                    <Col>
                      <Button
                        type="secondary"
                        htmlType="submit"
                        className="font-gilroy white-btn"
                        size="large"
                        onClick={() => history.push('/reports')}
                      >
                        Return to reports
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col span={24}>
              <Title level={3} className="text-center">
                {isSubmitted ? 'Your Report is Filed' : 'Submitting your report ...'}
              </Title>
            </Col>
            <Col flex="0 0 400px">
              <Row className="text-center" gutter={[24, 24]}>
                <Col span={24}>
                  <Image width={136} src={reportStatuses} preview={false} />
                </Col>
                <Col span={24}>
                  <Text className="smallFont14">
                    {isSubmitted
                      ? 'Your report was successfully submitted.'
                      : 'Please wait until we submit your report.'}
                  </Text>
                </Col>

                {!success || address?.hideBox ? null : (
                  <Col span={24}>
                    <Card className="info-card">
                      <Space size={20}>
                        <Image src={infoIcon} width={30} preview={false} />
                        <div className="info_text">
                          <Paragraph className="smallFont14 w-100">
                            Additionally, you must <b>sign and mail</b> the report cover to your Board of Elections.
                          </Paragraph>

                          {(address.line1 || address.line2 || address.line3) && (
                            <Paragraph className="smallFont14">
                              <b>Mailing address:</b> <br />
                              {address.line1} <br />
                              {address.line2} <br />
                              {address.line3} <br />
                            </Paragraph>
                          )}
                        </div>
                      </Space>
                    </Card>
                  </Col>
                )}

                {!reportId ? (
                  <>
                    {!success || address?.hideBox ? null : (
                      <Col span={24}>
                        <Button
                          htmlType="submit"
                          disabled
                          className="w-100 font-gilroy white-btn mb-1 icon-btn"
                          size="large"
                          icon={<DownloadIcon />}
                        >
                          Report Cover
                        </Button>

                        {isFederalPAC && (
                          <Button
                            htmlType="submit"
                            disabled
                            className="w-100 font-gilroy white-btn mb-1 icon-btn"
                            size="large"
                            icon={<DownloadIcon />}
                          >
                            Detailed Summary
                          </Button>
                        )}
                      </Col>
                    )}

                    <Col span={24}>
                      <Button type="secondary" htmlType="submit" className="font-gilroy" size="large" disabled>
                        Return to reports
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    {!success || address?.hideBox ? null : (
                      <Col span={24}>
                        <Button
                          htmlType="submit"
                          className="w-100 font-gilroy white-btn mb-1 icon-btn"
                          size="large"
                          onClick={() => downloadReportByCover()}
                          icon={<DownloadIcon />}
                        >
                          Report Cover
                        </Button>

                        {isFederalPAC && (
                          <Button
                            htmlType="submit"
                            className="w-100 font-gilroy white-btn mb-1 icon-btn"
                            size="large"
                            onClick={() => downloadReportBySummary()}
                            icon={<DownloadIcon />}
                          >
                            Detailed Summary
                          </Button>
                        )}
                      </Col>
                    )}

                    <Col span={24}>
                      <Button
                        type="secondary"
                        htmlType="submit"
                        className="font-gilroy"
                        size="large"
                        onClick={() => history.push('/reports')}
                      >
                        Return to reports
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </Card>
    </Dashboard>
  );
};
