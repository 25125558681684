import React, { useState, useEffect, Fragment } from 'react';
import { Card, Table, Row, Col, Typography, Button, Menu, Dropdown, Tag, Image, message, Input, Select } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Dashboard from '../../../pages/Dashboard';
import plusIcon from '../../../../assets/svg/plus-icon-white.svg';
import { Popup } from '../../../atoms/Popup';
import NewReportPopup from '../../../molecules/NewReportPopup';
import ConfirmDelete from '../../../molecules/ConfirmDelete';
import FiledExternal from '../../../molecules/FiledExternal';
const { Text } = Typography;
import {
  clearReportFilters,
  getReportDialogData,
  getReportListingData,
  setFiltersOptionsReport,
} from './ducks/actions';
import { useSelector, useDispatch } from 'react-redux';
import { ReportHeadingMap, ReportStatus } from '../../../../configs/constants';
import { addObject, deleteObject, getObject, downloadFile, saveFile } from '../../../../services/crudApis';
import {
  ADD_REPORT,
  DELETE_REPORT,
  GET_REPORT_HTML_BY_ID,
  DOWNLOAD_REPORT_PDF_BY_ID,
  DOWNLOAD_REPORT_SUMMARY_BY_ID,
  DOWNLOAD_REPORT_COVER_BY_ID,
  DOWNLOAD_REPORT_CDF_BY_ID,
  FILED_ORG_EXTERNALLY,
} from '../../../../configs/endpoints';
import clearIcon from '../../../../assets/svg/clear-filters.svg';
import exportCSV from '../../../../assets/svg/export-csv.svg';
import ReportPopup from './AddReports/StepsForm/FileReport/ReportPopup';
import { baseUrl } from '../../../../configs/constants';
import { useLocation } from 'react-router';
import ExportPeriod from '../../../molecules/ExportPeriod';

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableRecord, setTableRecord] = useState();
  const [heading, setHeading] = useState();
  const [yearReport, setYearReport] = useState();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [statusValue, setStatusValue] = useState([]);
  const [reportData, setReportData] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState('');
  const [isFederalPAC, setIsFederalPAC] = useState(true);
  const federalPAC = localStorage.getItem('organization');
  let reportDialogData = useSelector((state) => state.report.reportDialogData);
  let reportListData = useSelector((state) => state.report.reportListData);
  const selectedFilters = useSelector((state) => state.report.filter);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const url = location && location?.search?.split('?')[1];

  const reportSelect = [
    { value: 'sent', label: 'Sent' },
    { value: 'ready', label: 'Ready' },
    { value: 'draft', label: 'Draft' },
  ];
  useEffect(() => {
    if (federalPAC === 'federal') {
      setIsFederalPAC(false);
    }
  }, [federalPAC]);
  useEffect(() => {
    const contactData = [...reportListData];
    const currValue = selectedFilters?.searchReport?.toLowerCase();
    let filteredData = [];
    setValue(currValue);
    filteredData = contactData.filter((entry) => {
      const filterStatus =
        entry?.status && selectedFilters?.status?.length > 0
          ? selectedFilters?.status?.some((res) => entry?.status.includes(res))
          : true;
      const typedData = currValue
        ? (entry?.year && entry?.year.toLowerCase().includes(currValue)) ||
          (entry?.name && entry?.name.toLowerCase().includes(currValue)) ||
          (entry?.start_date && entry?.start_date.includes(currValue)) ||
          (entry?.end_date && entry?.end_date.includes(currValue)) ||
          (entry?.filed_date && moment(entry?.filed_date).format('MM-DD-YYYY, LT').includes(currValue)) ||
          (entry?.status && entry?.status.toLowerCase().includes(currValue))
        : true;

      return typedData && filterStatus;
    });
    setDataSource(filteredData);
  }, [selectedFilters]);

  useEffect(() => {
    if (url && url == 'fileReport') {
      setTimeout(() => {
        setVisible(true);
      }, 2000);
    }
  }, [url]);

  useEffect(() => {
    setDataSource(reportListData);
  }, [reportListData]);

  useEffect(() => {
    dispatch(clearReportFilters());
    dispatch(getReportDialogData());
    dispatch(getReportListingData());
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const menuSending = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const menuDraft = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="link" className="btn-link" onClick={() => previewReport(tableRecord)}>
          Preview
        </Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button type="link" className="btn-link" onClick={() => downloadReportByPDF(tableRecord)}>
          Download PDF
        </Button>
      </Menu.Item>
    </Menu>
  );

  const menuReady = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="link" className="btn-link" onClick={() => payFileReport(tableRecord)}>
          Pay and File Report
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="link" className="btn-link" onClick={() => previewReport(tableRecord)}>
          Preview
        </Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button type="link" className="btn-link" onClick={() => downloadReportByPDF(tableRecord)}>
          Download PDF
        </Button>
      </Menu.Item>
      <Menu.Item key="5">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const menuSent = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button type="link" className="btn-link" onClick={() => previewReport(tableRecord)}>
          Preview
        </Button>
      </Menu.Item>
      {!tableRecord?.isAmendment && (
        <Menu.Item key="2">
          <Button type="link" className="btn-link" onClick={() => createAmendment(tableRecord)}>
            Create Amendment
          </Button>
        </Menu.Item>
      )}
      {tableRecord?.type !== 'FEHR' && (
        <Menu.Item key="3">
          <Button type="link" className="btn-link" onClick={() => downloadReportByCover(tableRecord)}>
            Download Report Cover PDF
          </Button>
        </Menu.Item>
      )}
      {tableRecord?.type !== 'FEHR' && isFederalPAC && (
        <Menu.Item key="4">
          <Button type="link" className="btn-link" onClick={() => downloadReportBySummary(tableRecord)}>
            Download Detailed Summary PDF
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="5">
        <Button type="link" className="btn-link" onClick={() => downloadReportByPDF(tableRecord)}>
          Download PDF
        </Button>
      </Menu.Item>
      {tableRecord?.type !== 'FEHR' && (
        <Menu.Item key="6">
          <Button type="link" className="btn-link" onClick={() => downloadReportByCFD(tableRecord)}>
            Download CFD
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  const menuAccepted = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button type="link" className="btn-link" onClick={() => previewReport(tableRecord)}>
          Preview
        </Button>
      </Menu.Item>
      {!tableRecord?.isAmendment && (
        <Menu.Item key="2">
          <Button type="link" className="btn-link" onClick={() => createAmendment(tableRecord)}>
            Create Amendment
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="3">
        <Button type="link" className="btn-link" onClick={() => downloadReportByCover(tableRecord)}>
          Download Report Cover PDF
        </Button>
      </Menu.Item>
      {isFederalPAC && (
        <Menu.Item key="4">
          <Button type="link" className="btn-link" onClick={() => downloadReportBySummary(tableRecord)}>
            Download Detailed Summary PDF
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="5">
        <Button type="link" className="btn-link" onClick={() => downloadReportByPDF(tableRecord)}>
          Download PDF
        </Button>
      </Menu.Item>
      <Menu.Item key="6">
        <Button type="link" className="btn-link" onClick={() => downloadReportByCFD(tableRecord)}>
          Download CFD
        </Button>
      </Menu.Item>
    </Menu>
  );

  const menuRejected = (
    <Menu className="report-drop">
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const listColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      title: 'Type',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) =>
        text +
        (record?.type == 'FEHR'
          ? (record?.report_number ? ' ' + record?.report_number : '') +
            (record?.amendmentNumber ? ' - Amendment ' + record?.amendmentNumber : '')
          : ''),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      sorter: (a, b) =>
        +moment(a.start_date, 'MM-DD-YYYY').format('X') - +moment(b.start_date, 'MM-DD-YYYY').format('X'),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      sorter: (a, b) => +moment(a.end_date, 'MM-DD-YYYY').format('X') - +moment(b.end_date, 'MM-DD-YYYY').format('X'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => {
        let clname = '';
        if (text.includes('sending')) {
          clname = 'sending';
        } else if (text.includes('draft')) {
          clname = 'draft';
        } else if (text.includes('ready')) {
          clname = 'ready';
        } else if (text.includes('sent')) {
          clname = 'sent';
        } else if (text.includes('accepted')) {
          clname = 'accepted';
        } else if (text.includes('rejected')) {
          clname = 'rejected';
        } else {
          clname = 'draft';
        }
        return <Tag className={`defaultTag ${clname}`}>{text}</Tag>;
      },
    },
    {
      title: 'Filed',
      dataIndex: 'filed_date',
      sorter: (a, b) => moment(a.filed_date).unix() - moment(b.filed_date).unix(),
      render: (text) => (text ? moment(text).format('MM-DD-YYYY, LT') : '-'),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => {
        if (record?.is_filed_externally) {
        } else {
          if (record?.status == 'sending') {
            return (
              <Dropdown
                overlay={menuSending}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          } else if (record?.status == 'draft') {
            return (
              <Dropdown
                overlay={menuDraft}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          } else if (record?.status == 'ready') {
            return (
              <Dropdown
                overlay={menuReady}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          } else if (record?.status == 'sent') {
            return (
              <Dropdown
                overlay={menuSent}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          } else if (record?.status == 'accepted') {
            return (
              <Dropdown
                overlay={menuAccepted}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          } else if (record?.status == 'rejected') {
            return (
              <Dropdown
                overlay={menuRejected}
                trigger={['click']}
                placement="bottomRight"
                onClick={() => handleButtonClick(record)}
              >
                <MoreOutlined />
              </Dropdown>
            );
          }
        }
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.is_filed_externally || record.status == 'sent' || record.status == 'approved',
    }),
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    setLoading(true);
    const linkURL = record?.name.toLowerCase().replace(/ /g, '-');
    if (record?.isAmendment) {
      history.push({
        pathname: `/edit-amendment/${linkURL == '48-hour-notice' ? linkURL + '-amendment' : linkURL}/${
          record?.id
        }?year=${record?.year}`,
        state: {
          status: record?.status,
        },
      });
    } else {
      history.push({
        pathname: `/edit-reports/${linkURL}/${record?.id}?year=${record?.year}`,
        state: {
          status: record?.status,
        },
      });
    }
  };

  const payFileReport = (record) => {
    setLoading(true);
    const linkURL = record?.name.toLowerCase().replace(/ /g, '-');
    history.push({
      pathname: `/edit-reports/${linkURL}/${record?.id}`,
      state: {
        payFileReport: 'ready',
        status: record?.status,
      },
    });
  };

  const deleteRecord = (record) => {
    setLoading(true);
    setDeleteRecordID(record?.id);
    setConfirmDelete(true);
    setLoading(false);
  };

  const deleteMultiple = () => {
    //console.log(JSON.stringify(selectedRowKeys));
    setLoading(true);
    let reportToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      reportToBeDeleted.push(reportListData.find((x) => x.key === keys).id);
    }
    deleteObject(DELETE_REPORT, reportToBeDeleted)
      .then((response) => {
        message.success('Reports deleted.');
        dispatch(getReportListingData());
        dispatch(getReportDialogData());
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const fileExternal = (heading, year) => {
    //console.log('Heading: ' + ReportHeadingMap[heading]);
    setHeading(ReportHeadingMap[heading]);
    setYearReport(year);
    setLoading(true);
    setVisible(false);
    setVisible2(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);

    // let requestBody = {
    //   type: ReportHeadingMap[heading],
    //   status: ReportStatus.SENT,
    //   is_filed_externally: true,
    //   cash_at_hand_at_start: 0,
    //   no_of_fundraiser: 0,
    // };

    // //console.log('requestBody');
    // //console.log(JSON.stringify(requestBody));

    // addObject(ADD_REPORT, requestBody)
    //   .then((response) => {
    //     //console.log('response', response);

    //     dispatch(getReportListingData());
    //     dispatch(getReportDialogData());
    //     setTimeout(() => {
    //       setLoading(false);
    //     }, 1000);
    //     setVisible(false);
    //   })
    //   .catch((e) => {
    //     setLoading(false);
    //     message.error('Something went wrong');
    //     //console.log(e);
    //   });
  };

  const submitFileExternal = (values) => {
    setLoading(false);
    let payload = [];
    if (values && values.external_filed && values.external_filed.length > 0) {
      payload = values.external_filed.map((resp) => ({
        id: resp?.id,
        balance: resp.balance,
      }));
    }

    let requestBody = {};
    if (heading == 'ORG') {
      requestBody = {
        type: heading,
        status: ReportStatus.SENT,
        is_filed_externally: true,
        report_end_date: moment(values?.report_end_date).format('MM-DD-YYYY') + 'Z',
        account: payload,
      };

      addObject(FILED_ORG_EXTERNALLY, requestBody)
        .then((response) => {
          dispatch(getReportListingData());
          dispatch(getReportDialogData());
          setVisible2(false);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
          //console.log(e);
        });
    } else {
      requestBody = {
        type: heading,
        status: ReportStatus.SENT,
        is_filed_externally: true,
        year: yearReport ?? false,
      };

      addObject(ADD_REPORT, requestBody)
        .then((response) => {
          dispatch(getReportListingData());
          dispatch(getReportDialogData());
          setVisible2(false);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
          //console.log(e);
        });
    }
  };

  const hasSelected = selectedRowKeys.length > 0;

  const popup = {
    closable: false,
    visibility: visible,
    class: 'black-modal',
    content: (
      <NewReportPopup
        title="Select Report"
        reportPopData={[reportDialogData]}
        onClose={() => setVisible(false)}
        onDelete={() => onDelete()}
        fileExternal={fileExternal}
      />
    ),
    width: 950,
    onCancel: () => setVisible(false),
  };

  const popup2 = {
    closable: false,
    visibility: visible2,
    class: 'black-modal',
    content: (
      <FiledExternal
        title="Filed Externally"
        onClose={() => setVisible2(false)}
        onDelete={() => onDelete()}
        submitFileExternal={submitFileExternal}
        popHeading={heading}
      />
    ),
    width: 450,
    onCancel: () => setVisible2(false),
  };

  const previwPopup = {
    closable: false,
    visibility: visible3,
    class: 'black-modal',
    content: <ReportPopup data={reportData} x onClose={() => setVisible3(false)} onDelete={() => onDelete()} />,
    width: 850,
    onCancel: () => setVisible3(false),
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Report"
        paragraph="Are you sure you want to delete this report?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => updateApi()}
      />
    ),
    width: 450,
    onCancel: () => setVisible3(false),
  };

  const updateApi = () => {
    setLoading(true);
    deleteObject(DELETE_REPORT, [deleteRecordID])
      .then((response) => {
        message.success('Report deleted.');
        dispatch(getReportListingData());
        dispatch(getReportDialogData());
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
          setConfirmDelete(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const previewReport = (record) => {
    setLoading(true);
    getObject(GET_REPORT_HTML_BY_ID + '/' + record?.id)
      .then((response) => {
        setReportData(response.data);
        // var myWindow = window.open('', 'response', 'resizable=yes');
        // myWindow.document.write(responseHtml);
        setVisible3(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  const createAmendment = (record) => {
    setLoading(true);
    history.push({
      pathname: `/reports-amendment/${record?.name.toLowerCase().replace(/ /g, '-')}?year=${record?.year}`,
      state: { parent_id: record?.id },
    });
  };

  const downloadReportByCFD = (record) => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_CDF_BY_ID + '/' + record?.id).then((response) => {
        saveFile(response, record?.id + '.cfd');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };

  const downloadReportByPDF = (record) => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_PDF_BY_ID + '/' + record?.id)
        .then((response) => {
          saveFile(response, record?.id + '.pdf');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };

  const downloadReportByCover = (record) => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_COVER_BY_ID + '/' + record?.id).then((response) => {
        saveFile(response, 'Cover:' + record?.id + '.pdf');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };

  const downloadReportBySummary = (record) => {
    setLoading(true);
    try {
      downloadFile(DOWNLOAD_REPORT_SUMMARY_BY_ID + '/' + record?.id).then((response) => {
        saveFile(response, 'Summary:' + record?.id + '.pdf');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong');
      //console.log(e);
    }
  };

  const onClickRow = (record) => {
    if (record?.status == 'draft' || record?.status == 'ready') {
      return {
        onDoubleClick: () => {
          const linkURL = record?.name.toLowerCase().replace(/ /g, '-');
          const recordEndDate = moment(record?.end_date);
          const reportCase = moment().isSameOrAfter(recordEndDate);

          if (record?.isAmendment) {
            if (reportCase) {
              history.push({
                pathname: `/edit-amendment/${linkURL == '48-hour-notice' ? linkURL + '-amendment' : linkURL}/${
                  record?.id
                }?year=${record?.year}`,
                state: {
                  status: record?.status,
                },
              });
            } else {
              history.push({
                pathname: `/edit-amendment/${linkURL == '48-hour-notice' ? linkURL + '-amendment' : linkURL}/${
                  record?.id
                }?year=${record?.year}&case=5`,
                state: {
                  status: record?.status,
                },
              });
            }
          } else {
            if (reportCase) {
              history.push({
                pathname: `/edit-reports/${linkURL}/${record?.id}?year=${record?.year}`,
                state: {
                  status: record?.status,
                },
              });
            } else {
              history.push({
                pathname: `/edit-reports/${linkURL}/${record?.id}?year=${record?.year}&case=5`,
                state: {
                  status: record?.status,
                },
              });
            }
          }
        },
      };
    }
  };

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setLoading(false);
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  const handleChange = (value) => {
    setStatusValue(value);
    dispatch(setFiltersOptionsReport('status', value));
  };

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Transactions for the selected period will be exported."
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const clearFilters = () => {
    setStatusValue([]);
    setValue('');
    setDataSource(reportListData);
  };

  return (
    <>
      <Dashboard load={loading} heading="Reports">
        <Card className="white-card w-100 pcard-0">
          <Row justify="space-between" align="middle" className="reports_head" style={{ height: '50px' }}>
            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  {hasSelected ? (
                    <Button
                      onClick={() => deleteMultiple()}
                      type="text"
                      htmlType="button"
                      className="font-gilroy c-blue"
                    >
                      Delete Selected
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => setVisible(true)}
                      htmlType="button"
                      className="w-100 font-gilroy"
                      size="large"
                    >
                      <Image src={plusIcon} preview={false} width={22} />
                      New Report
                    </Button>
                  )}
                </Col>
                {hasSelected ? (
                  <Col>
                    <Text className="font-14">{selectedRowKeys.length} Selected </Text>
                  </Col>
                ) : (
                  <Col className="search_box">
                    <Input
                      placeholder="Search..."
                      value={value}
                      onChange={(e) => dispatch(setFiltersOptionsReport('searchReport', e.target.value))}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  <Select
                    onChange={handleChange}
                    className="filter_select"
                    placeholder="Status"
                    value={statusValue}
                    mode="multiple"
                  >
                    {reportSelect &&
                      reportSelect?.map((item, index) => (
                        <Fragment key={index}>
                          <Option value={item?.value}>{item?.label}</Option>
                        </Fragment>
                      ))}
                  </Select>
                </Col>
                <Col>
                  <Button type="ghost" htmlType="button" className="p-0" onClick={clearFilters}>
                    <img src={clearIcon} alt="clearIcon" />
                  </Button>
                </Col>
                {/* <Col>
                  <Button type="ghost" htmlType="button" className="p-0" onClick={() => setPeriodPDF(true)}>
                    <img src={exportCSV} alt="export CSV" />
                  </Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            onRow={onClickRow}
            pagination={{
              showSizeChanger: dataSource?.length > 10 ? true : false,
              pagination,
            }}
            onChange={handleTableChange}
            className="cursor_table"
          />
        </Card>
      </Dashboard>
      <Popup {...popup} />
      <Popup {...popup2} />
      <Popup {...previwPopup} />
      <Popup {...deletePopup} />
      <Popup {...exportPeriod} />
    </>
  );
};
