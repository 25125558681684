import React, { useState, useEffect } from 'react';
import { Layout, Menu, Image } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../../assets/svg/new-white-logo.svg';
import {
  DashboardIcon,
  ReportIcon,
  ReceiptsIcon,
  ExpendituresIcon,
  ContactListIcon,
  SettingsIcon,
  ExemptSalesIcon,
  DebtsIcon,
} from '../../../atoms/CustomIcons/';
import { useSelector } from 'react-redux';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default (props) => {
  const { isFederalPAC } = props;
  const location = useLocation().pathname;
  const subkey = location.split('/')[1];
  const isSetupComplete = JSON.parse(localStorage.getItem('user'))?.is_setup_complete;

  return (
    <Sider width={260} className="dashboard-sidebar" trigger={null}>
      <div className="sidebar_logo">
        {isSetupComplete ? (
          <Link to="/dashboard">
            <Image width={150} src={Logo} preview={false} />
          </Link>
        ) : (
          <Image width={150} src={Logo} preview={false} />
        )}
      </div>
      {/* <div className="election_year">2022 Election</div> */}
      <Menu defaultSelectedKeys={[subkey]} defaultOpenKeys={[subkey]} mode="inline">
        {isSetupComplete ? (
          <>
            <Menu.Item key="dashboard" icon={<DashboardIcon />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="reports" icon={<ReportIcon />}>
              <Link to="/reports">Reports</Link>
            </Menu.Item>
            {isFederalPAC && (
              <Menu.Item key="receipts" icon={<ReceiptsIcon />}>
                <Link to="/receipts">Receipts</Link>
              </Menu.Item>
            )}
            <Menu.Item key="expenditures" icon={<ExpendituresIcon />}>
              <Link to="/expenditures">Expenditures</Link>
            </Menu.Item>

            {isFederalPAC && (
              <>
                <Menu.Item key="debts" icon={<DebtsIcon className="fillIcon" />}>
                  <Link to="/debts/owed-by-the-comittee">Debts</Link>
                </Menu.Item>
                <Menu.Item key="exempt-sales" icon={<ExemptSalesIcon />}>
                  <Link to="/exempt-sales">Exempt Sales</Link>
                </Menu.Item>
              </>
            )}
            <Menu.Item key="contacts" icon={<ContactListIcon />}>
              <Link to="/contacts">Contact List</Link>
            </Menu.Item>
            <Menu.Item key="settings" className="btm-menu" icon={<SettingsIcon />}>
              <Link to="/settings">Settings</Link>
            </Menu.Item>
          </>
        ) : (
          <Menu.Item key="dashboard" icon={<DashboardIcon />}>
            Dashboard
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};
