export const baseUrl = 'https://api.electafile.com';
//export const baseUrl = 'https://electafileapi.darkhorsesolutions.co';
//export const baseUrl = process.env.REACT_APP_BASE_URL;
//export const baseUrl = 'http://192.168.100.5:3000'; //Shehan
//export const baseUrl = 'http://192.168.100.10:3000'; //shayan
//export const baseUrl = 'http://192.168.1.55:3000';

import cashIcon from '../assets/svg/cash.svg';
import checkIcon from '../assets/svg/check.svg';
import inKindIcon from '../assets/svg/in-kind.svg';
import creditCardIcon from '../assets/svg/credit-card.svg';
import debitCardIcon from '../assets/svg/debit-card.svg';
import draftIcon from '../assets/svg/draft.svg';
import moneyOrderIcon from '../assets/svg/money-order.svg';
import wireTransferIcon from '../assets/svg/wire-transfer.svg';

export const CommitteeType = {
  CANDIDATE: 'candidate',
  PARTY: 'party',
  POLITICAL_ACTION: 'political-action',
  INDEPENDENT_EXPENDITURE: 'independent-expenditure',
};

export const countryList = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'Washington DC',
    value: 'WADC',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const EXPENDITURE_PURPOSE_CODE = [
  { label: 'A - Media', value: 'A' },
  { label: 'B - Printing', value: 'B' },
  { label: 'C - Fundraising', value: 'C' },
  { label: 'D - To Another Candidate', value: 'D' },
  { label: 'E - Salaries', value: 'E' },
  { label: 'F - Equipment', value: 'F' },
  { label: 'G - Political Party', value: 'G' },
  {
    label: 'H - Holding Public Office Expense',
    value: 'H',
  },
  { label: 'I - Postage', value: 'I' },
  { label: 'J - Penalties', value: 'J' },
  { label: 'K - Office Expenses', value: 'K' },
  {
    label: 'Q - Donation to Legal Expse Fund',
    value: 'Q',
  },
  { label: 'O - Other', value: 'O' },
];

export const REFUND_PURPOSE_CODE = [
  { label: 'L - Returned to Contributor', value: 'l' },
  { label: 'M - Overpayment for Service', value: 'm' },
  { label: 'N - Exceeded Contribution Limit', value: 'n' },
  { label: 'P - Reimbursement of In-Kind', value: 'p' },
  { label: 'O - Other', value: 'o' },
];

export const FORMS_OF_PAYMENTS = [
  { label: 'Check', value: 'check', image: checkIcon },
  { label: 'Debit Card', value: 'debit_card', image: debitCardIcon },
  { label: 'Credit Card', value: 'credit_card', image: creditCardIcon },
  { label: 'Cash', value: 'cash', image: cashIcon },
  { label: 'EFT', value: 'eft', image: wireTransferIcon },
  { label: 'Draft', value: 'draft', image: draftIcon },
  { label: 'Money Order', value: 'money_order', image: moneyOrderIcon },
  { label: 'In-Kind', value: 'in_kind', image: inKindIcon },
];

export const FORMS_OF_PAYMENTS_Expenditure = [
  { label: 'Check', value: 'check', image: checkIcon },
  { label: 'Debit Card', value: 'debit_card', image: debitCardIcon },
  { label: 'Credit Card', value: 'credit_card', image: creditCardIcon },
  { label: 'Cash', value: 'cash', image: cashIcon },
  { label: 'EFT', value: 'eft', image: wireTransferIcon },
  { label: 'Draft', value: 'draft', image: draftIcon },
  { label: 'Money Order', value: 'money_order', image: moneyOrderIcon },
];

export const CONTACTS_POLITICAL_COMMITTEE_TYPE = [
  { label: 'Candidate Committee', value: 'candidate-committee' },
  { label: 'Political Party Committee', value: 'political-party-committee' },
  { label: 'Political Action Committee', value: 'political-action-committee' },
  { label: 'Legal Expense Fund', value: 'legal-expense-fund' },
  { label: 'Joint Candidate Committee', value: 'joint-candidate-committee' },
  { label: 'Referendum Committee', value: 'referendum-committee' },
  {
    label: 'Independent Expenditure PAC',
    value: 'independent-expenditure-pac',
  },
];

export const CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL = [
  { label: 'Federal', value: 'federal' },
  { label: 'State', value: 'state' },
  { label: 'County', value: 'county' },
  { label: 'Municipality', value: 'municipality' },
];

export const ReportTypeCode = {
  ORG: 'ORG',
  MYSA: 'MYSA',
  YESA: 'YESA',
  QTR1: 'QTR1',
  QTR2: 'QTR2',
  QTR3: 'QTR3',
  QTR4: 'QTR4',
  FEHR: 'FEHR',
};

export const urlToReportTypeMap = {
  'mid-year-semiannual': 'MYSA',
  'year-end-semiannual': 'YESA',
  'first-quarter-plus': 'QTR1',
  'second-quarter': 'QTR2',
  'third-quarter-plus': 'QTR3',
  'fourth-quarter': 'QTR4',
  'mid-year-semiannual-amendment': 'MYSA',
  'year-end-semiannual-amendment': 'YESA',
  'first-quarter-plus-amendment': 'QTR1',
  'second-quarter-amendment': 'QTR2',
  'third-quarter-plus-amendment': 'QTR3',
  'fourth-quarter-amendment': 'QTR4',
};

export const ReportHeadingMap = {
  Organizational: 'ORG',
  'Mid-Year Semiannual': 'MYSA',
  'Year-End Semiannual': 'YESA',
  'First Quarter Plus': 'QTR1',
  'Second Quarter': 'QTR2',
  'Third Quarter Plus': 'QTR3',
  'Fourth Quarter': 'QTR4',
  '48-Hour Notice': '48HR',
};
export const ReportStatus = {
  DRAFT: 'draft', // .cfd not generated, amendments are NOT available
  READY: 'ready', // .cfd generated, but not sent, amendments are NOT available
  ACCEPTED: 'accepted', // .cfd generated, sent, accepted by gov, amendments are available (system will generate new .cfd), ONLY this available for accepted
  REJECTED: 'rejected', // .cfd generated, sent, rejected by gov, amendments are NOT available
  PENDING: 'pending', // .cfd generated, sent, in review by gov, amendments NOT available
  DECLINED: 'declined', // .cfd generated, payment is failed, amendments NOT available
  SENT: 'sent', //.cfd generated, sent, amendments are NOT available
  SENDING: 'sending',
};
export const ReportCase = {
  ACTIVE: 1, // report case active
  Due: 2, // report case due
  AMENDMENT: 4, // report case amendment
  PREVIEW: 5, // report case amendment
  DISABLE: 3, // report case disable
  NOT_STARTED: 3, // report case not started
  LOCKED: 3, // report case locked
  isAmendment: false,
};
export const DATE_FORMAT = 'MM-DD-YYYY';

export const ReportContent = {
  ORGANIZATIONAL:
    'Reporting period: Up to the first 10 days of the committee’s activity Due date: Within 10 days of the committee organizing',
  MYSA_INACTIVE: 'Report period hasn’t ended. Will become available on 07-01-2022',
  AMENDMENT: 'File an amendment if anything has changed in this report',
  PASSED: 'You don’t need to file this report. This period passed.',
  DUE_DATE: 'Due date: ',
  DUE_DATE_MISSED: (dueDate) => `Due date: ${dueDate} missed`,
  NOT_STARTED: (startDate) => `Report period hasn’t ended. Will become available on ${startDate}`,
  REPORT_ACTIVE: (startDate, endDate) => `Reporting period: ${startDate} - ${endDate}.`,
};

export const PACCategoryList = [
  { label: 'Banking / Finance', value: 'BFI' },
  { label: 'Building / Real Estate', value: 'BRE' },
  { label: 'Conservative / Liberal', value: 'COL' },
  { label: 'Environment', value: 'ENV' },
  { label: 'Get Out the Vote', value: 'OVO' },
  { label: 'Health', value: 'HEA' },
  {
    label: 'Information Technology / Telecommunications',
    value: 'ITT',
  },
  { label: 'Insurance', value: 'INS' },
  { label: 'Legal', value: 'LEG' },
  { label: 'Manufacturing', value: 'MAN' },
  { label: 'Minority', value: 'MIN' },
  {
    label: 'Political Party not party of Party Plan Organization',
    value: 'NPP',
  },
  { label: 'Religious', value: 'REL' },
  { label: 'Trade', value: 'TRD' },
  { label: 'Utilities', value: 'UTI' },
  { label: 'Other / Not listed', value: 'OTH' },
];

export const specificField = [
  { value: '111', label: 'Crop Production' },
  { value: '112', label: 'Animal Production' },
  { value: '113', label: 'Forestry and Logging' },
  { value: '114', label: 'Fishing, Hunting and Trapping' },
  { value: '115', label: 'Support Activities for Agriculture and Forestry' },
  { value: '211', label: 'Oil and Gas Extraction' },
  { value: '212', label: 'Mining (except Oil and Gas)' },
  { value: '213', label: 'Support Activities for Mining' },
  { value: '221', label: 'Utilities' },
  { value: '236', label: 'Construction of Buildings' },
  { value: '237', label: 'Heavy and Civil Engineering Construction' },
  { value: '238', label: 'Specialty Trade Contractors' },
  { value: '311', label: 'Food Manufacturing' },
  { value: '312', label: 'Beverage and Tobacco Product Manufacturing' },
  { value: '313', label: 'Textile Mills' },
  { value: '314', label: 'Textile Product Mills' },
  { value: '315', label: 'Apparel Manufacturing' },
  { value: '316', label: 'Leather and Allied Product Manufacturing' },
  { value: '321', label: 'Wood Product Manufacturing' },
  { value: '322', label: 'Paper Manufacturing' },
  { value: '323', label: 'Printing and Related Support Activities' },
  { value: '324', label: 'Petroleum and Coal Products Manufacturing' },
  { value: '325', label: 'Chemical Manufacturing' },
  { value: '326', label: 'Plastics and Rubber Products Manufacturing' },
  { value: '327', label: 'Nonmetallic Mineral Product Manufacturing' },
  { value: '331', label: 'Primary Metal Manufacturing' },
  { value: '332', label: 'Fabricated Metal Product Manufacturing' },
  { value: '333', label: 'Machinery Manufacturing' },
  { value: '334', label: 'Computer and Electronic Product Manufacturing' },
  { value: '335', label: 'Electrical Equipment, Appliance, and Component Manufacturing' },
  { value: '336', label: 'Transportation Equipment Manufacturing' },
  { value: '337', label: 'Furniture and Related Product Manufacturing' },
  { value: '339', label: 'Miscellaneous Manufacturing' },
  { value: '423', label: 'Merchant Wholesalers, Durable Goods' },
  { value: '424', label: 'Merchant Wholesalers, Nondurable Goods' },
  { value: '425', label: 'Wholesale Electronic Markets and Agents and Brokers' },
  { value: '441', label: 'Motor Vehicle and Parts Dealers' },
  { value: '442', label: 'Furniture and Home Furnishings Stores' },
  { value: '443', label: 'Electronics and Appliance Stores' },
  { value: '444', label: 'Building Material and Garden Equipment and Supplies Dealers' },
  { value: '445', label: 'Food and Beverage Stores' },
  { value: '446', label: 'Health and Personal Care Stores' },
  { value: '447', label: 'Gasoline Stations' },
  { value: '448', label: 'Clothing and Clothing Accessories Stores' },
  { value: '451', label: 'Sporting Goods, Hobby, Book, and Music Stores' },
  { value: '452', label: 'General Merchandise Stores' },
  { value: '453', label: 'Miscellaneous Store Retailers' },
  { value: '454', label: 'Nonstore Retailers' },
  { value: '481', label: 'Air Transportation' },
  { value: '482', label: 'Rail Transportation' },
  { value: '483', label: 'Water Transportation' },
  { value: '484', label: 'Truck Transportation' },
  { value: '485', label: 'Transit and Ground Passenger Transportation' },
  { value: '486', label: 'Pipeline Transportation' },
  { value: '487', label: 'Scenic and Sightseeing Transportation' },
  { value: '488', label: 'Support Activities for Transportation' },
  { value: '491', label: 'Postal Service' },
  { value: '492', label: 'Couriers and Messengers' },
  { value: '493', label: 'Warehousing and Storage' },
  { value: '511', label: 'Publishing Industries (except Internet)' },
  { value: '512', label: 'Motion Picture and Sound Recording Industries' },
  { value: '515', label: 'Broadcasting (except Internet)' },
  { value: '516', label: 'Internet Publishing and Broadcasting' },
  { value: '517', label: 'Telecommunications' },
  { value: '518', label: 'Internet Service Providers, Web Search Portals, and Data Processing Services' },
  { value: '519', label: 'Other Information Services' },
  { value: '521', label: 'Monetary Authorities - Central Bank' },
  { value: '522', label: 'Credit Intermediation and Related Activities' },
  { value: '523', label: 'Securities, Commodity Contracts, and Other Financial Investments and Related Activities' },
  { value: '524', label: 'Insurance Carriers and Related Activities' },
  { value: '525', label: 'Funds, Trusts, and Other Financial Vehicles' },
  { value: '531', label: 'Real Estate' },
  { value: '532', label: 'Rental and Leasing Services' },
  { value: '533', label: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)' },
  { value: '541', label: 'Professional, Scientific, and Technical Services' },
  { value: '551', label: 'Management of Companies and Enterprises' },
  { value: '561', label: 'Administrative and Support Services' },
  { value: '562', label: 'Waste Management and Remediation Services' },
  { value: '611', label: 'Educational Services' },
  { value: '621', label: 'Ambulatory Health Care Services' },
  { value: '622', label: 'Hospitals' },
  { value: '623', label: 'Nursing and Residential Care Facilities' },
  { value: '624', label: 'Social Assistance' },
  { value: '711', label: 'Performing Arts, Spectator Sports, and Related Industries' },
  { value: '712', label: 'Museums, Historical Sites, and Similar Institutions' },
  { value: '713', label: 'Amusement, Gambling, and Recreation Industries' },
  { value: '721', label: 'Accommodation' },
  { value: '722', label: 'Food Services and Drinking Places' },
  { value: '811', label: 'Repair and Maintenance' },
  { value: '812', label: 'Personal and Laundry Services' },
  { value: '813', label: 'Religious, Grantmaking, Civic, Professional, and Similar Organizations' },
  { value: '814', label: 'Private Households' },
  { value: '921', label: 'Executive, Legislative, and Other General Government Support' },
  { value: '922', label: 'Justice, Public Order, and Safety Activities' },
  { value: '923', label: 'Administration of Human Resource Programs' },
  { value: '924', label: 'Administration of Environmental Quality Programs' },
  { value: '925', label: 'Administration of Housing Programs, Urban Planning, and Community Development' },
  { value: '926', label: 'Administration of Economic Programs' },
  { value: '927', label: 'Space Research and Technology' },
  { value: '928', label: 'National Security and International Affairs' },
];

export const FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const CURRENCY_FORMATTER = (number) => {
  if (!isNaN(+number) && +number != 0) {
    const threshold = 1e-10;
    const formattedNumber = Math.abs(+number) < threshold ? 0 : +number;
    return FORMATTER.format(formattedNumber);
  } else {
    return '$0';
  }
};

export const UnImportedExpenditureType = [
  'operating_expense',
  'contribution_to_candidate_or_political_committee',
  'coordinated_party_expenditure',
  'loan_repayment',
  'refund',
  'gift',
  'independent_expenditure',
  'debt_repayment_owed_by',
];

export const UnImportedReceiptType = [
  'general_contribution',
  'contribution_to_be_reimbursed',
  'refund_reimbursement_to_the_committee',
  'interest',
  'outside_source_of_income',
  'loan_proceed',
  'forgiven_loan',
  'exempt_purchase_price_sales',
  'outstanding_loan',
  'debt_repayment_owed_to',
];

export const ReportHeading = {
  'Mid-Year Semiannual': 'Mid-Year',
  'Year-End Semiannual': 'Year-End',
  'First Quarter Plus': 'Q1 Plus',
  'Second Quarter': 'Q2',
  'Third Quarter Plus': 'Q3 Plus',
  'Fourth Quarter': 'Q4',
};
