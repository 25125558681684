import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Tooltip } from 'antd';
import { InputField, SelectField } from '../../../../../../atoms/FormElement';
import plusIcon from '../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../assets/svg/trash-icon.svg';
import { InfoCircleFilled } from '@ant-design/icons';

const { Paragraph, Title } = Typography;

const initQ = {
  item_id: '',
  qty: '',
};

export default (props) => {
  const {
    formData,
    fields,
    control,
    errors,
    setValue,
    append,
    remove,
    selectData,
    deletedIndexes,
    setDeletedIndexes,
    salesDropdown,
  } = props;

  const onRemove = (e, index) => {
    remove(index);
  };
  const onAdd = () => {
    append(initQ);
  };

  return (
    <>
      <Col span={24}>
        <Title level={4} className="text-center mb-0 mt-2">
          Items Sold
        </Title>
      </Col>

      <Col span={24}>
        {fields?.map((item, index) => {
          return (
            <Fragment key={item.id}>
              <Row
                gutter={[20, 10]}
                align="bottom"
                style={{
                  paddingTop: '10px',
                  position: 'relative',
                }}
              >
                <InputField
                  fieldname={`item_sold[${index}].id`}
                  label=""
                  control={control}
                  class="d-none mb-0"
                  initValue={item?.id}
                  iProps={{ size: 'large' }}
                />
                <Col span={12}>
                  <SelectField
                    fieldname={`item_sold[${index}].item_id`}
                    label={index == 0 ? 'Item' : ''}
                    control={control}
                    valueGot={
                      salesDropdown &&
                      salesDropdown?.items?.length &&
                      salesDropdown?.items.find((e) => e?.value == item?.item_id)
                    }
                    setValue={setValue}
                    iProps={{
                      size: 'large',
                      placeholder: 'Please select',
                    }}
                    selectOption={salesDropdown ? salesDropdown?.items : []}
                    isRequired={true}
                    rules={{ required: 'Item is required.' }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.item_sold?.length > 0 &&
                      errors?.item_sold[index]?.item_id &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.item_sold?.length > 0 &&
                      errors?.item_sold[index]?.item_id &&
                      errors?.item_sold[index]?.item_id?.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname={`item_sold[${index}].qty`}
                    label={index == 0 ? 'Number of Items Sold' : ''}
                    control={control}
                    initValue={item?.qty}
                    iProps={{
                      size: 'large',
                      type: 'number',
                    }}
                    rules={{
                      required: 'Number of Items Sold is required.',
                    }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.item_sold?.length > 0 &&
                      errors?.item_sold[index]?.qty &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.item_sold?.length > 0 &&
                      errors?.item_sold[index]?.qty &&
                      errors?.item_sold[index]?.qty?.message
                    }
                  />
                </Col>

                {index > 0 && (
                  <div className="accounts_remove" style={{ top: 20 }}>
                    <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                      <Image src={trashIcon} preview={false} size={24} />
                    </Button>
                  </div>
                )}
              </Row>
            </Fragment>
          );
        })}
      </Col>
      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Item
        </Button>
      </Col>
    </>
  );
};
