import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Popover, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField, InputPassword } from '../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { authentications } from '../../../services/services';
import { login } from '../../../features/userSlice';
import Login from '../../pages/Login';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const userEmail = values.email.toLowerCase();
    authentications(userEmail, values.password)
      .then((response) => {
        let res = {
          access_token: response.data.token,
          //refresh_token: response.data.refresh_token,
        };

        if (res) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', JSON.stringify(res));
          localStorage.setItem('organisedReport', JSON.stringify(response.data.user.date_organized));
          dispatch(
            login({
              email: userEmail,
            }),
          );
          setTimeout(() => {
            history.push(response?.data?.user?.is_setup_complete ? '/dashboard' : '/import-data');
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error?.message);
      });
  };
  return (
    <Login load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={1} className="text-center mb-0 font-gilroy">
            Log In
          </Title>
          <Paragraph className="text-center mt-1 mb-0">
            ElectaFile&reg; is{' '}
            <a
              href="http://ncrules.state.nc.us/ncac/title%2008%20-%20elections/chapter%2021%20-%20departmental%20rules/08%20ncac%2021%20.0106.pdf"
              target="_blank"
              className="c-blue"
            >
              <b>compatible third party software</b>
            </a>{' '}
            for filing with the NC State Board of Elections and all county boards of elections.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <InputField
                  fieldname="email"
                  label="Email"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Please input email',
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'Check if the email is valid',
                    },
                  }}
                  validate={errors.email && 'error'}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <InputPassword
                      fieldname="password"
                      label="Password"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'Please enter password',
                      }}
                      validate={errors.password && 'error'}
                      validMessage={errors.password && errors.password.message}
                    />
                  </Col>

                  <Col span={24} className="text-right">
                    <Link to="/forgot-password" className="login-form-forgot">
                      Forgot Password
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" className="w-100 text-uppercase" size="large">
                  Log In
                </Button>
              </Col>

              <Col span={24} className="text-center">
                <Paragraph className="text-center mt-2 c-grey">Don't have an account yet?</Paragraph>
                <Link to="/create-account" className="text-uppercase c-grey font-gilroy">
                  Create Account
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Login>
  );
};
