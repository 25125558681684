import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '../../../../pages/Dashboard';
import DebtOwedBYTheCommittee from '../DebtsForms/DebtOwedBYTheCommittee';
import DebtOwedToTheCommittee from '../DebtsForms/DebtOwedToTheCommittee';
import { getObligationById } from '../ducks/actions';
import { CURRENCY_FORMATTER } from '../../../../../configs/constants';

export default (props) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const expenditureType = location?.state?.expenditureType;
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalIncurred, setTotalIncurred] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const obligationDetailData = useSelector((state) => state.debts.obligationDetailData);
  const dispatch = useDispatch();
  const url = location.pathname;
  const urlID = url.split('/')[2];

  useEffect(() => {
    if (urlID) {
      dispatch(getObligationById(urlID));
    }
  }, [urlID, dispatch]);

  return (
    <>
      <Dashboard
        load={loading}
        heading="Edit Debts"
        backLink={
          expenditureType == 'debt_owed_by_the_committee'
            ? '/debts/owed-by-the-comittee'
            : '/debts/owed-to-the-comittee'
        }
      >
        <Card className="white-card w-100">
          <Row justify="space-between" gutter={[20, 20]} className="mb-24">
            <Col />
            <Col>
              <Row gutter={42}>
                <Col span={8}>
                  <div className="amount_paid amount_debt">
                    <div className="top_card_debts">Total Amount Paid</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(totalPaid)}</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="amount_incurred amount_debt">
                    <div className="top_card_debts">Total Amount Incurred</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(totalIncurred)}</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="amount_balance amount_debt">
                    <div className="top_card_debts">Remaining Balance</div>
                    <div className="dets_amount_top">{CURRENCY_FORMATTER(remainingBalance)}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {expenditureType == 'debt_owed_by_the_committee' && (
            <DebtOwedBYTheCommittee
              setLoading={setLoading}
              formData={obligationDetailData}
              urlID={urlID}
              setTotalIncurred={setTotalIncurred}
              totalPaid={totalPaid}
              setTotalPaid={setTotalPaid}
              totalIncurred={totalIncurred}
              remainingBalance={remainingBalance}
              setRemainingBalance={setRemainingBalance}
            />
          )}

          {expenditureType == 'debt_owed_to_the_committee' && (
            <DebtOwedToTheCommittee
              setLoading={setLoading}
              formData={obligationDetailData}
              urlID={urlID}
              setTotalIncurred={setTotalIncurred}
              setTotalPaid={setTotalPaid}
              totalPaid={totalPaid}
              totalIncurred={totalIncurred}
              remainingBalance={remainingBalance}
              setRemainingBalance={setRemainingBalance}
            />
          )}
        </Card>
      </Dashboard>
    </>
  );
};
