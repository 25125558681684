import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Card, Descriptions } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import plusIcon from '../../../../assets/svg/plus.svg';
import penIcon from '../../../../assets/svg/pen-icon.svg';
import { SelectField } from '../../../atoms/FormElement';
import { specificField } from '../../../../configs/constants';
import { Popup } from '../../../atoms/Popup';
import NewContactType from '../../../molecules/Dashboard/NewContactPopup/NewContactType';
import NewContactIndividual from '../../../molecules/Dashboard/NewContactPopup//NewContactIndividual';

const { Paragraph } = Typography;

export default (props) => {
  const {
    control,
    errors,
    selectData,
    changeValueByDropDown,
    updateDropdownValues,
    contributerData,
    contactBoxEditCase,
    fieldname,
    label,
    setValue,
    contactToSelect,
    setContactToSelect,
    arraySelectedItem,
    fixedContactType,
    column,
    errorField,
    clearErrors,
    placeholder,
    initValue,
    hintMessage,
  } = props;
  const [contactID, setContctID] = useState('');
  const [openContactTypePopup, setOpenContactTypePopup] = useState(false);
  const [savePopupType, setSavePopupType] = useState('');

  const [popupType, setPopupType] = useState('');
  const [contctValue, setContctValue] = useState('');
  const [typeIndividual, setTypeIndividual] = useState(false);
  const [editPopupForm, setEditPopupForm] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(null);

  const specific_field = contributerData?.specific_field
    ? specificField?.find((e) => e?.value == contributerData?.specific_field)
    : '';

  useEffect(() => {
    if (setValue && selectData?.length > 0 && contactToSelect) {
      const selectedPayeeList = selectData?.find((e) => e?.value == contactToSelect);

      if (fieldname == arrayIndex) {
        setValue(fieldname, selectedPayeeList);
      }
      contributorData(selectedPayeeList);
      setContactToSelect(null);
    }
  }, [selectData]);

  useEffect(() => {
    if (setValue && arraySelectedItem) {
      setValue(fieldname, arraySelectedItem);
      setContactToSelect(null);
    }
  }, [arraySelectedItem]);

  useEffect(() => {
    if (contactBoxEditCase) {
      contributorData(contactBoxEditCase);
    }
  }, [contactBoxEditCase]);

  const contributorData = (e) => {
    setContctValue(e?.value);
  };

  const openContactType = () => {
    setOpenContactTypePopup(fixedContactType ? false : true);
    setTypeIndividual(fixedContactType ? true : false);
    setArrayIndex(fieldname);
    setContctValue('');
    setEditPopupForm(false);
  };

  const typeSelected = () => {
    setOpenContactTypePopup(false);
    setTypeIndividual(true);
  };

  const updateApi = (contactIds) => {
    setContactToSelect(contactIds);
    setTypeIndividual(false);
    if (errorField) {
      clearErrors(fieldname);
    }
    setPopupType('');
    if (!editPopupForm) {
      updateDropdownValues();
    }
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
  };

  const editContributer = (e) => {
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
    setPopupType(e);
    typeSelected();
    setEditPopupForm(true);
  };

  const selectContactType = {
    closable: false,
    visibility: openContactTypePopup,
    class: 'black-modal',
    content: (
      <NewContactType
        title="New Contact"
        onClose={() => setOpenContactTypePopup(false)}
        typeSelected={typeSelected}
        setPopupType={setPopupType}
      />
    ),
    width: 600,
    onCancel: () => setOpenContactTypePopup(false),
  };

  const individualPopup = {
    closable: false,
    visibility: typeIndividual,
    class: 'black-modal',
    content: (
      <NewContactIndividual
        title="New Contact"
        onClose={() => setTypeIndividual(false)}
        setTypeIndividual={setTypeIndividual}
        popupType={fixedContactType ? fixedContactType : popupType}
        updateApi={(contctValue) => updateApi(contctValue)}
        editPopupForm={editPopupForm}
        data={editPopupForm ? contributerData : {}}
        contctValue={contctValue}
      />
    ),
    width: 600,
    onCancel: () => setTypeIndividual(false),
  };

  const handleKeyDownFunction = (event) => {
    if (event.keyCode === 8) {
      // Backspace key
      setValue(fieldname, null);
      setOpenContactCard(false);
    }
  };
  return (
    <>
      <Col span={column ? column : 24}>
        <SelectField
          fieldname={fieldname}
          initValue={initValue}
          label={label}
          control={control}
          setValue={setValue}
          iProps={{
            size: 'large',
            placeholder: placeholder ?? 'Please select',
          }}
          handleKeyDown={(e) => handleKeyDownFunction(e)}
          onChange={(e) => contributorData(e)}
          hintMessage={hintMessage}
          selectOption={selectData && selectData?.length > 0 ? selectData : []}
        />

        <div className="add_new_contat">
          <Button
            type="text"
            htmlType="button"
            className="font-gilroy c-grey d-flex align-center"
            onClick={() => openContactType()}
          >
            <Image src={plusIcon} preview={false} size={24} />
            Add New
          </Button>
        </div>
      </Col>

      <Popup {...selectContactType} />
      <Popup {...individualPopup} />
    </>
  );
};
