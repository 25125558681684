import React, { useEffect, useState, Fragment } from 'react';
import { Spin, Button, Image, Row, Col, Typography, Form, Radio, message } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import crossIcon from '../../../../../../../../../assets/svg/cross-icon.svg';
import { InputField, InputRadio } from '../../../../../../../../atoms/FormElement';
import plusIcon from '../../../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../../../assets/svg/trash-icon.svg';
import { countryList } from '../../../../../../../../../configs/constants';
import { addAdminSupports, deleteSupport } from '../../../../../ducks/services';
import { getAdminSupportData } from '../../../../../ducks/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

const initQ = {
  serviceName: '',
  serviceBalance: '',
};

export default (props) => {
  const { onClose, link, title, reportId, updateAPI, visibleAdministrative, setVisibleAdministrative, hideBtn } = props;
  const [support, setSupport] = useState('support_no');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const adminSupportData = useSelector((state) => state.report.adminSupportData);

  //console.log('adminSupportData', adminSupportData);

  const { control, errors, setValue, reset, watch, handleSubmit } = useForm({
    shouldUnregister: false,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'support_service',
  });
  const onRemove = (e, index) => {
    remove(index);
  };
  const onAdd = () => {
    append(initQ);
    let leng = `${fields.length + 1}`;
  };

  const onFinish = (val) => {
    setLoading(true);
    const payload = {
      report_id: props?.reportID?.split('?')[0],
      administrative_support: val?.administrative_support,
      name_of_parent: val?.name_of_parent,
      billing: val?.billing,
      mailing: val?.mailing,
      record_keeping: val?.record_keeping,
      fundraising_activities: val?.fundraising_activities,
      membership_development: val?.membership_development,
      office_supplies: val?.office_supplies,
      office_space: val?.office_space,
      computer_services: val?.computer_services,
      parent_labor_cost: val?.parent_labor_cost,
    };
    if (val?.support_service?.length > 0) {
      for (const keys of val?.support_service) {
        payload[keys?.serviceName + '_map'] = keys?.serviceBalance;
      }
    }

    if (val?.administrative_support == 'support_yes') {
      addAdminSupports(payload)
        .then((response) => {
          message.success('Administrative Support added.');
          updateAPI();
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    } else {
      deleteSupport(props?.reportID)
        .then((response) => {
          message.success('Administrative Support updated.');
          updateAPI();
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (adminSupportData && Object.keys(adminSupportData)?.length > 0) {
      setValue('administrative_support', adminSupportData?.administrative_support);
      setSupport(adminSupportData?.administrative_support);

      let mapArray = [];
      for (const [key, value] of Object.entries(adminSupportData)) {
        if (key.endsWith('_map')) {
          const newKey = key.replace(/_map/g, '');
          if (newKey) {
            mapArray.push({
              serviceName: newKey,
              serviceBalance: value,
            });
          }
        }
      }
      setTimeout(() => {
        setValue('name_of_parent', adminSupportData?.name_of_parent);
        setValue('billing', adminSupportData?.billing);
        setValue('mailing', adminSupportData?.mailing);
        setValue('record_keeping', adminSupportData?.record_keeping);
        setValue('fundraising_activities', adminSupportData?.fundraising_activities);
        setValue('membership_development', adminSupportData?.membership_development);
        setValue('office_supplies', adminSupportData?.office_supplies);
        setValue('office_space', adminSupportData?.office_space);
        setValue('computer_services', adminSupportData?.computer_services);
        setValue('parent_labor_cost', adminSupportData?.parent_labor_cost);
        setValue('support_service', mapArray);
      }, 500);
    } else {
      reset();
    }
  }, [adminSupportData]);

  useEffect(() => {
    dispatch(getAdminSupportData(props?.reportID));
  }, [props]);

  const closingPopUp = () => {
    visibleAdministrative && setVisibleAdministrative(false);
  };

  let supportStatus = false;
  supportStatus = watch('administrative_support');

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={closingPopUp}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          <Paragraph>PACs use this form to report administrative support from their specified parent entity.</Paragraph>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]} align="bottom" className="add_account">
              <Col span={24}>
                <InputRadio
                  fieldname="administrative_support"
                  label="Add Administrative Support to the Report"
                  control={control}
                  onChange={(e) => setSupport(e.target.value)}
                  classField="tab-type-radio"
                  initValue="support_no"
                  options={
                    <>
                      <Row gutter={[20, 20]}>
                        <Col span={12}>
                          <Radio value="support_yes">Yes</Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value="support_no">No</Radio>
                        </Col>
                      </Row>
                    </>
                  }
                  // isRequired={true}
                  // rules={{
                  //   required: 'Add Administrative Support to the Report is required.',
                  // }}
                  // validate={errors.contributing && 'error'}
                  // validMessage={errors.contributing && errors.contributing.message}
                />
              </Col>

              {supportStatus == 'support_yes' && (
                <>
                  <Col span={24}>
                    <InputField
                      fieldname="name_of_parent"
                      label="Name of Parent Entity"
                      control={control}
                      initValue=""
                      tooltip={true}
                      tooltipTitle="If a political committee defines a “parent entity” on their Statement of Organization (CRO-2100D) form, they are entitled to accept reasonable administrative support from that “parent entity”."
                      iProps={{
                        size: 'large',
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Title level={4}>Administrative Support Services</Title>
                  </Col>

                  <Col span={24}>
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <Row gutter={[20, 20]}>
                          <Col span={17}>
                            <Paragraph>Support Service</Paragraph>
                          </Col>
                          <Col span={7}>
                            <Paragraph>Amount</Paragraph>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <InputField
                          fieldname="billing"
                          label="Billings"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="mailing"
                          label="Mailings"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="record_keeping"
                          label="Record Keeping"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="computer_services"
                          label="Computer Services"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="fundraising_activities"
                          label="Fundraising Activities"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="membership_development"
                          label="Membership Development"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="office_supplies"
                          label="Office Supplies"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        <InputField
                          fieldname="office_space"
                          label="Office Space"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          class="left_label"
                        />
                      </Col>

                      <Col span={24}>
                        {fields?.map((item, index) => (
                          <Fragment key={item.id}>
                            <Row gutter={20} align="bottom" className="administrative_array admin_support_array">
                              <InputField
                                fieldname={`support_service[${index}].id`}
                                label=""
                                control={control}
                                class="d-none mb-0"
                                initValue={item?.id}
                                iProps={{ size: 'large' }}
                              />

                              <Col span={17}>
                                <InputField
                                  fieldname={`support_service[${index}].serviceName`}
                                  label=""
                                  control={control}
                                  class="mb-0"
                                  initValue={item?.serviceName}
                                  iProps={{ size: 'large', placeholder: 'Enter Service Name' }}
                                />
                              </Col>

                              <Col span={7}>
                                <InputField
                                  fieldname={`support_service[${index}].serviceBalance`}
                                  label=""
                                  control={control}
                                  class="mb-0"
                                  initValue={
                                    item?.serviceBalance && item?.serviceBalance > 0 ? item?.serviceBalance : '0'
                                  }
                                  iProps={{ size: 'large', prefix: '$', type: 'number' }}
                                />
                                <div className="accounts_remove">
                                  <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                                    <Image src={trashIcon} preview={false} size={24} />
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Fragment>
                        ))}
                      </Col>

                      <Col span={24}>
                        <Button
                          type="text"
                          htmlType="button"
                          className="font-gilroy c-grey d-flex align-center mt-2"
                          onClick={onAdd}
                        >
                          <Image src={plusIcon} preview={false} size={24} />
                          Add other Support Service
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <InputField
                      fieldname="parent_labor_cost"
                      label="Parent Entity Employee Labor Cost"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        prefix: '$',
                        type: 'number',
                      }}
                      hintMessage="Employee must have spent more than 35% of time during normal business hours on committee work"
                    />
                  </Col>
                </>
              )}

              <Col span={24} className="text-center mt-2 mb-1">
                <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={closingPopUp}
                      htmlType="button"
                      className="font-gilroy white-btn"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Col>

                  {!hideBtn && (
                    <Col>
                      <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                        Save
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
