import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getReceiptListingData, get48HourReceiptListingData } from '../../../../Receipts/ducks/actions';

const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const dispatch = useDispatch();
  const reportDetailData = useSelector((state) => state.report.reportDetailData);
  const receiptListData = useSelector((state) => state.receipt.receiptListData);

  useEffect(() => {
    if (reportDetailData?.type == 'FEHR') {
      dispatch(get48HourReceiptListingData(reportDetailData?.id));
    } else {
      const startDate = moment(reportDetailData?.start_date).format('MM-DD-YYYY');
      const endDate = moment(reportDetailData?.end_date).format('MM-DD-YYYY');
      dispatch(getReceiptListingData(startDate, endDate));
    }
  }, [reportDetailData]);

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text) => moment(text).format('MM-DD-YYYY'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Expenditure Type',
      dataIndex: 'type',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
    {
      title: 'Payee',
      dataIndex: 'payee',
    },
    {
      title: 'Form',
      dataIndex: 'payment_type',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
    {
      title: 'Account Code',
      dataIndex: 'bankaccount',
    },
  ];

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setLoading(false);
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Table columns={listColumns} dataSource={receiptListData} pagination={{ pageSize: '6' }} />
    </Spin>
  );
};
