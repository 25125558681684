import React from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import crossIcon from '../../../../assets/svg/cross-icon.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const { onClose, title } = props;
  return (
    <Row justify="space-between" align="middle">
      <Col></Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>
      <Col span={24}>
        <Paragraph className="w-100 c-l-blue mb-1">
          Political party executive and affiliated committees (not subordinate/auxiliary committees) are eligible to
          raise contributions through an Exempt Purchase Price Sale (‘Exempt Sale’).
          <br />
          <br />
          An Exempt Sale is a sale of goods or services, such as t-shirts, buttons or food items, in which: the price
          for each item is reasonably close to its market price; and the total purchase for each contributor is $50 or
          less. <br />
          <br />
          The Treasurer submits an Exempt Sales Plan{' '}
          <a
            href="https://s3.amazonaws.com/dl.ncsbe.gov/Campaign_Finance/Forms/cro2600/cro2600.pdf"
            className="c-doger-blue"
            target="_blank"
          >
            (CRO-2600)
          </a>{' '}
          to the Executive Director of the State Board of Elections &amp; Ethics Enforcement for approval prior to
          conducting the sale.
          <br />
          <br />
          The total amount raised from all sales under all plans by the committee does not exceed $20,000 per election
          cycle.
        </Paragraph>
      </Col>
    </Row>
  );
};
