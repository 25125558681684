import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Descriptions, Image, Card, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import reportStatuses from '../../../../../../../assets/svg/report-statuses.svg';
import accepted from '../../../../../../../assets/svg/accepted.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getReportDetailData } from '../../../ducks/actions';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [reportAccepted, setReportAccepted] = useState(false);
  const dispatch = useDispatch();
  const id = location.pathname.split('/')[3];
  const reportDetailData = useSelector((state) => state.report.reportDetailData);
  const committeeName = JSON.parse(localStorage.getItem('user'))?.name;
  const BOEID = JSON.parse(localStorage.getItem('Committee'))?.boardElectionsID;

  useEffect(() => {
    dispatch(getReportDetailData(id));
  }, []);

  useEffect(() => {
    if (reportDetailData?.status == 'approved' || reportDetailData?.status == 'sent') {
      setReportAccepted(true);
    }
  }, [reportDetailData]);

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <div className="detail-report">
          <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
            <Descriptions.Item label="Committee Name">{committeeName}</Descriptions.Item>
            <Descriptions.Item label="Board of Elections ID">{BOEID}</Descriptions.Item>
            <Descriptions.Item label="Report Period Start Date">
              {reportDetailData?.start_date && moment(reportDetailData?.start_date).format('MM-DD-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Report Period End Date">
              {reportDetailData?.end_date && moment(reportDetailData?.end_date).format('MM-DD-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Number of Fundraisers">{reportDetailData?.no_of_fundraiser}</Descriptions.Item>
          </Descriptions>
        </div>
      </Col>
      <Col span={12}>
        <div className="file_report">
          <Card className={reportAccepted ? 'accepted-card' : 'grey-card'}>
            <Row className="text-center" gutter={[24, 24]}>
              <Col span={24}>
                <Image width={136} src={reportAccepted ? accepted : reportStatuses} preview={false} />
              </Col>
              <Col span={24}>
                <Title className="c-doger-blue w-100 text-capitalize" level={4}>
                  {reportDetailData?.status}
                </Title>
                <Paragraph className="smallFont14">Waiting for confirmation from the Board of Elections</Paragraph>
              </Col>
              <Col span={12} className="text-left mt-2">
                <Paragraph className="smallFont12 mb-0 w-100">Sent</Paragraph>
                <Paragraph className="smallFont14 w-100">
                  {reportDetailData?.created_date && moment(reportDetailData?.created_date).format('MM-DD-YYYY')}
                </Paragraph>
              </Col>
              {reportDetailData?.status == 'approved' && (
                <Col span={12} className="text-left mt-2">
                  <Paragraph className="smallFont12 mb-0 w-100">Accepted</Paragraph>
                  <Paragraph className="smallFont14 w-100">01-05-2022, 5:45 PM</Paragraph>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
  );
};
