import axiosInterceptor from './axiosInterceptor';
import { baseUrl } from '../configs/constants';

export const addReceipts = (data, url = '/receipts') => {
  return axiosInterceptor.post(baseUrl + url, data);
};

export const editReceipts = (data, id, url = '/receipts') => {
  if (url == '/updateReceiptEndorsement') {
    return axiosInterceptor.post(baseUrl + url, data);
  } else {
    return axiosInterceptor.patch(baseUrl + url + '/' + id, data);
  }
};

export const editImportReceipts = (data, type, id) => {
  return axiosInterceptor.patch(baseUrl + '/updateUnimportedReceiptAndExpenditure' + '/' + type + '/' + id, data);
};

export const deleteReceipts = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteReceipts`, ids);
};

export const deleteUnimportedReceipt = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteUnimportedReceiptAndExpenditure`, ids);
};
