import React, { useState, useEffect, Fragment } from 'react';
import { Typography, Row, Col, Image, Descriptions, Button, message, Form, Spin } from 'antd';
import completed from '../../../../../assets/svg/completed.svg';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { InputField, DateField, SelectField } from '../../../../atoms/FormElement';
import { addObject } from '../../../../../services/crudApis';
import { SAVE_INITIAL_BALANCE } from '../../../../../configs/endpoints';
import moment from 'moment';
import ErrorList from '../../../../molecules/ErrorList';
import { Popup } from '../../../../atoms/Popup';

const antIcon = <LoadingOutlined spin />;
const { Title, Paragraph } = Typography;
const _ = require('lodash');

export default (props) => {
  const { status } = props;
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      prev_transaction: [{ name: 'useFieldArray' }],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'prev_transaction',
  });
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  const currentYear = new Date().getFullYear();
  const electionYear = [];

  Array.apply(null, { length: 11 }).map((e, i) =>
    electionYear.push({ label: `${currentYear - i}`, value: currentYear - i }),
  );

  const onFinish = (val) => {
    //console.log('values', val);
    setLoading(true);

    let payload = {};
    if (val && val.prev_transaction && val.prev_transaction.length > 0) {
      payload.accountBalances = val.prev_transaction.map((resp) => ({
        id: resp?.id,
        balance: resp.balance,
      }));
    }
    payload.date_organized = val?.dateOrganized ? moment(val?.dateOrganized).format('YYYY-MM-DD') : '';
    payload.election_start_year =
      committeeType == 'candidate' ? parseInt(val?.election_start_year?.value) : parseInt(val?.election_start_year);
    payload.term_in_office = committeeType == 'candidate' ? parseInt(val?.term_in_office?.value) : 2;

    //console.log('payload', payload);
    addObject(SAVE_INITIAL_BALANCE, payload)
      .then((response) => {
        setLoading(false);
        message.success('Information Updated.');
        history.push('/dashboard');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  useEffect(() => {
    setLoading(true);
    if (status && Object.keys(status).length > 0) {
      setLoading(false);
      if (status?.date_organized) {
        setValue('dateOrganized', moment(status?.date_organized, 'YYYY-MM-DD HH:mm'));
      } else {
        setValue('dateOrganized', '');
      }
      if (status?.bankAccounts) {
        setValue('prev_transaction', status?.bankAccounts);
      }
      if (status?.errorList.length > 0) {
        setErrorPopup(true);
      }
    }
  }, [status]);

  const candidateTerm = [
    { label: '2 years', value: 2 },
    { label: '4 years', value: 4 },
    { label: '6 years', value: 6 },
    { label: '8 years', value: 8 },
  ];

  const errorPopUp = {
    closable: false,
    visibility: errorPopup,
    class: 'black-modal',
    content: (
      <ErrorList title="Circular dependency detected" data={status?.errorList} onClose={() => setErrorPopup(false)} />
    ),
    width: 580,
    onCancel: () => setErrorPopup(false),
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[35, 35]} className="text-center">
        <Col span={24}>
          <Image src={completed} preview={false} width={242} className="mb-1" />
          <Paragraph className="smallFont14">
            Your committee data was imported successfully. From now on, you
            <br /> can continue recording transactions and file reports on ElectaFile&reg;.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Title level={4} className="text-center mb-1">
            Committee Data Imported
          </Title>
          <Descriptions column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} className="success-description">
            <Descriptions.Item label="Committee Name">{status.name}</Descriptions.Item>
            <Descriptions.Item label="Board of Elections ID">{status.boe_id}</Descriptions.Item>
            <Descriptions.Item label="Bank Accounts">{status.accounts}</Descriptions.Item>
            <Descriptions.Item label="Officers">{status.officers}</Descriptions.Item>
            <Descriptions.Item label="Contacts">{status.contacts}</Descriptions.Item>
            <Descriptions.Item label="Receipts">{status.receipts}</Descriptions.Item>
            <Descriptions.Item label="Expenditures">{status.expenditures}</Descriptions.Item>
            {/* <Descriptions.Item label="Current Election Cycle">Statewide General Elections, 2021-2022</Descriptions.Item> */}
            <Descriptions.Item label="Cash on Hand at Start">{status.cat}</Descriptions.Item>
            <Descriptions.Item label="Reports Filed">{status.reports}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]} align="bottom" className="add_account">
              <Col span={24}>
                <DateField
                  fieldname="dateOrganized"
                  label="Date organized"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large' }}
                  initValue=""
                  isRequired={true}
                  rules={{
                    required: 'Date organized is required.',
                  }}
                  validate={errors.dateOrganized && 'error'}
                  validMessage={errors.dateOrganized && errors.dateOrganized.message}
                />
              </Col>
              <Col span={12}>
                {committeeType == 'candidate' ? (
                  <SelectField
                    fieldname="election_start_year"
                    label="Election Cycle Start Year"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Please select' }}
                    initValue=""
                    tooltip={true}
                    tooltipTitle="When your current election cycle has started. Based on this and the term in the office, we will define the election year."
                    selectOption={electionYear}
                    rules={{
                      required: "Candidate's Term in the Office is required.",
                    }}
                    validate={errors.election_start_year && 'error'}
                    validMessage={errors.election_start_year && errors.election_start_year.message}
                  />
                ) : (
                  <InputField
                    fieldname="election_start_year"
                    label="Election Cycle Start Year"
                    control={control}
                    class="mb-0 label_inside"
                    initValue="2023"
                    iProps={{ size: 'large' }}
                    readOnly={true}
                  />
                )}
              </Col>

              <Col span={12}>
                {committeeType == 'candidate' ? (
                  <SelectField
                    fieldname="term_in_office"
                    label="Candidate's Term in the Office"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Please select' }}
                    initValue=""
                    tooltip={true}
                    tooltipTitle="When your current election cycle has started. Based on this and the term in the office, we will define the election year."
                    selectOption={candidateTerm}
                    isRequired={true}
                    rules={{
                      required: "Candidate's Term in the Office is required.",
                    }}
                    validate={errors.term_in_office && 'error'}
                    validMessage={errors.term_in_office && errors.term_in_office.message}
                  />
                ) : (
                  <InputField
                    fieldname="term_in_office"
                    label="Election Year"
                    control={control}
                    class="mb-0 label_inside"
                    initValue="2024"
                    iProps={{ size: 'large' }}
                    readOnly={true}
                  />
                )}
              </Col>

              <Col span={24}>
                {fields?.map((item, index) => (
                  <Fragment key={item.id}>
                    <Row gutter={[20, 20]} align="bottom" style={{ marginBottom: '20px' }}>
                      <InputField
                        fieldname={`prev_transaction[${index}].id`}
                        label=""
                        control={control}
                        class="d-none mb-0"
                        initValue={item?.id}
                        iProps={{ size: 'large' }}
                      />
                      <Col span={12}>
                        <InputField
                          fieldname={`prev_transaction[${index}].code`}
                          label="Account Code"
                          control={control}
                          class="mb-0 label_inside"
                          initValue={item?.code}
                          iProps={{ size: 'large' }}
                          readOnly={true}
                        />
                      </Col>
                      <Col span={12}>
                        <InputField
                          isRequired={true}
                          fieldname={`prev_transaction[${index}].balance`}
                          label="Election Cycle Begin Balance"
                          tooltip={true}
                          tooltipTitle="Total amount of funds available on this bank account at the start of this election cycle."
                          control={control}
                          initValue={item?.begin_balance}
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          rules={{
                            required: 'Election Cycle Begin Balance is required.',
                          }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.prev_transaction?.length > 0 &&
                            errors?.prev_transaction[index]?.balance &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.prev_transaction?.length > 0 &&
                            errors?.prev_transaction[index]?.balance &&
                            errors?.prev_transaction[index]?.balance?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Col>

              <Col span={24} className="text-center">
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Popup {...errorPopUp} />
      </Row>
    </Spin>
  );
};
