import React, { Fragment } from 'react';
import { Card, Col, Image, Row, Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import noReport from '../../../../assets/svg/no-report.svg';
import plusIcon from '../../../../assets/svg/plus.svg';
import organizational from '../../../../assets/svg/report/org.svg';
import midYear from '../../../../assets/svg/report/my.svg';
import quarter1 from '../../../../assets/svg/report/q1.svg';
import quarter2 from '../../../../assets/svg/report/q2.svg';
import quarter3 from '../../../../assets/svg/report/q3.svg';
import quarter4 from '../../../../assets/svg/report/q4.svg';
import yearEnd from '../../../../assets/svg/report/ye.svg';
import hour48 from '../../../../assets/svg/report/h-48.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const { reportData } = props;
  const history = useHistory();

  const reportsClick = (record) => {
    const url = record?.name.replace(/ /g, '-').toLowerCase();
    history.push(`/reports/${url}?year=${record.year}`);
  };

  const fileReport = () => {
    history.push('/reports?fileReport');
  };
  return (
    <>
      {reportData && reportData?.reportsToFile?.length > 0 ? (
        <>
          <Title level={3} className="text-center mb-1">
            Reports to be Filed
          </Title>
          <Paragraph className="text-center w-100 c-l-blue mb-4">
            Here are the reports you are required to file as of today
          </Paragraph>
          <Row gutter={[24, 48]}>
            {reportData?.reportsToFile?.map((item, index) => (
              <Fragment key={index}>
                <Col span={8}>
                  <Card
                    className="white-card report-card text-center hover-card"
                    style={{ height: '270px' }}
                    onClick={() => reportsClick(item)}
                  >
                    <Image
                      className="mb-2"
                      src={
                        item?.name == 'Organizational'
                          ? organizational
                          : '' || item?.name == 'Mid-Year Semiannual'
                          ? midYear
                          : '' || item?.name == 'Year-End Semiannual'
                          ? yearEnd
                          : '' || item?.name == 'First Quarter Plus'
                          ? quarter1
                          : '' || item?.name == 'Second Quarter'
                          ? quarter2
                          : '' || item?.name == 'Secord Quarter'
                          ? quarter2
                          : '' || item?.name == 'Third Quarter Plus'
                          ? quarter3
                          : '' || item?.name == 'Forth Quarter'
                          ? quarter4
                          : '' || item?.name == '48-Hour Notice'
                          ? hour48
                          : ''
                      }
                      preview={false}
                      width={80}
                    />
                    <Title level={4} className="mb-0">
                      {item?.name}
                    </Title>
                    {item?.subHeading && (
                      <Title className="mb-0 mt-0" level={5}>
                        {item?.subHeading}
                      </Title>
                    )}
                    <div className="mt-auto">
                      {item?.name !== '48-Hour Notice' && <Paragraph className="w-100 mb-0">{item?.subText}</Paragraph>}
                      {item?.date && item?.name !== '48-Hour Notice' ? (
                        <Paragraph className={item?.dueDateMissed ? 'w-100 mb-0 c-red' : 'w-100 mb-0 c-blue'}>
                          {item?.date}
                        </Paragraph>
                      ) : (
                        <div style={{ fontSize: '12px', color: '#424C5F' }}>
                          All contributions of $1,000 or more must be reported within 48 hours
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              </Fragment>
            ))}
            {reportData?.reportsToFile && reportData?.reportsToFile?.length < 3 && (
              <Col
                span={reportData?.reportsToFile?.length == 1 ? 6 : 4}
                style={{ marginTop: '24px', height: '222px' }}
              >
                <Card className="white-card text-center plus-card hover-card" onClick={() => history.push('/reports')}>
                  <Image src={plusIcon} preview={false} size={24} />
                </Card>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <>
          <div className="text-center no-report">
            <Title level={3} className="mb-1">
              No Reports to File
            </Title>
            <Paragraph className="w-100 mb-3 c-l-blue">Everything that is needed to be filed was filed.</Paragraph>
            <Image className="mb-2" src={noReport} preview={false} width={268} />
            <div style={{ textAlign: 'center' }}>
              <Button type="link" className="p-0 text-center" onClick={() => fileReport()}>
                File report Amendment
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
