import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';
import { GET_UNIMPORTED_BY_ID, GET_BY_ID } from '../../../../../configs/endpoints';
import { downloadFile, saveFile } from '../../../../../services/crudApis';
import { message } from 'antd';

export const getContactListingData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getContactsList`);
    dispatch({
      type: action_types.CONTACT_LIST_DATA,
      data: data,
    });
  };
};

export const checkUnimportedContact = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/checkUnimportedContact`);
    dispatch({
      type: action_types.CHECK_UNIMPORTED_CONTACTS,
      data: data,
    });
  };
};

export const getUnimportedContactsList = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getUnimportedContactsList`);
    dispatch({
      type: action_types.UNIMPORTED_CONTACT_LIST_DATA,
      data: data,
    });
  };
};

export const getContactFormData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_BY_ID}/${id}`);
    dispatch({
      type: action_types.CONTACT_FORM_DATA,
      data: data,
    });
  };
};

export const getDuplicateContactFormData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_BY_ID}/${id}`);
    dispatch({
      type: action_types.DUPLICATE_CONTACT_FORM_DATA,
      data: data,
    });
  };
};

export const getUnimportedContactFormData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_UNIMPORTED_BY_ID}/${id}`);
    dispatch({
      type: action_types.UNIMPORTED_CONTACT_FORM_DATA,
      data: data,
    });
  };
};

export const getContactFormDataPayee = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/${GET_BY_ID}/${id}`);
    dispatch({
      type: action_types.CONTACT_FORM_DATA_PAYEE,
      data: data,
    });
  };
};

export const getBankAccounts = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/bankaccounts`);
    dispatch({
      type: action_types.BANK_ACCOUNTS_LIST,
      data: data,
    });
  };
};

export const getContributorData = (contributor_id, amount) => {
  return axios.get(`${baseUrl}/getContributorTotal/${contributor_id}/${amount}`);
};
//630e6f9f423bb02d51dc51f2/500

export const setFiltersOptionsContact = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filter: { [type]: value } },
    });
  };
};

export const clearContactFilters = () => ({
  type: action_types.CLEAR_CONTACT_FILTER,
});

export const exportContact = (data) => ({
  type: action_types.EXPORT_CANTACT,
  data: data,
});

export const setFiltersImport = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_IMPORT,
      payload: { filterImport: { [type]: value } },
    });
  };
};
