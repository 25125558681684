import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { InputRadio, InputField, TextAreaField, SelectField, DateField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../ducks/actions';
import { FORMS_OF_PAYMENTS } from '../../../../../../configs/constants';
import moment from 'moment';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const [formOfPayment, setFormOfPayment] = useState('');
  const dispatch = useDispatch();
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const selectedAccountList = receiptFormData?.accounts?.find((e) => e?.value == formData?.receiptData?.account_id);
      const selectedExpenditureList = receiptFormData?.contributors?.find(
        (e) => e?.value == formData?.receiptData?.reimbursed_expenditure_id,
      );
      setValue('amount', formData.receiptData?.amount);
      setValue('remimbursement_purpose', formData.receiptData?.remimbursement_purpose);
      setValue('reimbursed_expenditure_id', selectedExpenditureList);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');
      setValue('comments', formData.receiptData?.comments);
      setValue('formOfPayment', formData.receiptData?.payment_type);
      setFormOfPayment(formData.receiptData?.payment_type);
      setTimeout(() => {
        setValue('check_number', formData.receiptData?.check_number);
        setValue('in_kind_description', formData.receiptData?.in_kind_description);
        setValue('account', selectedAccountList);
        setLoading(false);
      }, 1000);
    }
  }, [receiptFormData, formData]);

  useEffect(() => {
    dispatch(getReceiptDropdownData('refund_reimbursement_to_the_committee', 'null'));
  }, []);

  const expenditureList = [
    { label: '$100.00 to Wade Warren', value: '$100.00 to Wade Warren' },
    { label: '$5,520.00 to Company Abcz', value: '$5,520.00 to Company Abcz' },
  ];

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (receiptFormData && receiptFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', receiptFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="reimbursed_expenditure_id"
              label="Expenditure Refunded/Reimbursed"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Expenditure Refunded/Reimbursed is required.' }}
              initValue=""
              selectOption={receiptFormData?.contributors}
              validate={errors.reimbursed_expenditure_id && 'error'}
              validMessage={errors.reimbursed_expenditure_id && errors.reimbursed_expenditure_id.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Amount Refunded/Reimbursed"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Amount Refunded/Reimbursed is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="remimbursement_purpose"
              label="Refund/Reimbursement Purpose"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Refund/Reimbursement Purpose is required.',
              }}
              validate={errors.remimbursement_purpose && 'error'}
              validMessage={errors.remimbursement_purpose && errors.remimbursement_purpose.message}
            />
          </Col>

          <Col span={24}>
            <TextAreaField
              fieldname="comments"
              label="Comments"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              optionalLabel={true}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputRadio
              fieldname="formOfPayment"
              label="Form of Payment"
              control={control}
              initValue={''}
              classField="custom-radio"
              onChange={(e) => formPayment(e)}
              options={
                <>
                  {FORMS_OF_PAYMENTS &&
                    FORMS_OF_PAYMENTS?.map((item, index) => (
                      <Fragment key={index}>
                        <Radio.Button value={item?.value}>
                          <Image src={item?.image} preview={false} width={80} />
                          <Paragraph className="smallFont12">{item?.label}</Paragraph>
                        </Radio.Button>
                      </Fragment>
                    ))}
                </>
              }
              rules={{
                required: 'Form of Payment is required.',
              }}
              validate={errors.formOfPayment && 'error'}
              validMessage={errors.formOfPayment && errors.formOfPayment.message}
            />
          </Col>

          {formOfPayment && (
            <Col span={24}>
              {formOfPayment != 'in_kind' && (
                <Row gutter={16}>
                  {formOfPayment == 'check' && (
                    <Col span={12}>
                      <InputField
                        fieldname="check_number"
                        label="Check Number"
                        control={control}
                        initValue=""
                        optionalLabel="(Optional)"
                        iProps={{
                          size: 'large',
                        }}
                      />
                    </Col>
                  )}
                  <Col span={formOfPayment == 'check' ? 12 : 24}>
                    <SelectField
                      isRequired={true}
                      fieldname="account"
                      label="Account"
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      rules={{ required: 'Account is required.' }}
                      initValue=""
                      selectOption={receiptFormData?.accounts}
                      validate={errors.account && 'error'}
                      validMessage={errors.account && errors.account.message}
                    />
                  </Col>
                </Row>
              )}

              {formOfPayment == 'in_kind' && (
                <Col span={24}>
                  <InputField
                    isRequired={true}
                    fieldname="in_kind_description"
                    label="In-Kind Description"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'In-Kind Description is required.',
                    }}
                    validate={errors.in_kind_description && 'error'}
                    validMessage={errors.in_kind_description && errors.in_kind_description.message}
                  />
                </Col>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
