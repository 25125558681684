import React, { useState, useEffect } from 'react';
import Dashboard from '../../../../pages/Dashboard';
import {
  Card,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Input,
  Image,
  message,
  Tooltip,
  Slider,
  InputNumber,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../../assets/svg/plus-icon-white.svg';
import exportCSV from '../../../../../assets/svg/export-csv.svg';
import arrowIcon from '../../../../../assets/svg/select-icon.svg';
import clearIcon from '../../../../../assets/svg/clear-filters.svg';
import closeBlue from '../../../../../assets/svg/close-blue.svg';
import defaultExclamation from '../../../../../assets/svg/default-exclamation.svg';
import redExclamation from '../../../../../assets/svg/red-exclamation.svg';
import reviewIcon from '../../../../../assets/svg/review-icon.svg';
import { getUnimportedContactsList, setFiltersImport } from '../ducks/actions';
import { addObject, deleteObject, getObject } from '../../../../../services/crudApis';
import { ADD_IMPORTED_CONTACT, DELETE_IMPORT_CONTACT, DOWNLOAD_CONTACT_CSV } from '../../../../../configs/endpoints';
import ConfirmDelete from '../../../../molecules/ConfirmDelete';
import { Popup } from '../../../../atoms/Popup';
import ReportAmendment from '../../../../molecules/ReportAmendment';
import { baseUrl } from '../../../../../configs/constants';
import ExportPeriod from '../../../../molecules/ExportPeriod';
import ExportContacts from '../../../../molecules/ExportContacts';
import AmountDropdown from '../../../../molecules/AmountDropdown';
import DiscardImport from '../../../../molecules/DiscardImport';
import { CloseIcon } from '../../../../atoms/CustomIcons';

const { Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableRecord, setTableRecord] = useState();
  const [dataSource, setDataSource] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [notDeleted, setNotDeleted] = useState(false);
  const [discardTransactions, setDiscardTransactions] = useState(false);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [contactExport, setContactExport] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [countError, setCountError] = useState(null);
  const [countDublicate, setCountDublicate] = useState(null);
  const exportedContactList = useSelector((state) => state.contact.exportContact);
  const selectedImportFilters = useSelector((state) => state.contact.filterImport);

  const contactListData = useSelector((state) => state.contact.unimportedContact);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    dispatch(getUnimportedContactsList());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => updateApi(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const storeStateInLocalStorage = (sort, newPagination) => {
    localStorage.setItem('antdTableStateContact', JSON.stringify({ sorting: sort, pagination: newPagination }));
  };

  useEffect(() => {
    const storedState = localStorage.getItem('antdTableStateContact');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      setSorting({
        column: parsedState.sorting?.field,
        order: parsedState.sorting?.order,
      });
      setPagination({
        current: parsedState.pagination?.current,
        pageSize: parsedState.pagination?.pageSize,
      });
    }
  }, []);

  const listColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sorting?.column === 'name' ? sorting.order : null,
    },
    {
      title: 'Contact Type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sortOrder: sorting?.column === 'type' ? sorting.order : null,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => +a.email.replace('$', '').split(',').join('') - +b.email.replace('$', '').split(',').join(''),
      render: (text) => (text ? text : '-'),
      sortOrder: sorting?.column === 'email' ? sorting.order : null,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: (a, b) => +a.phone.replace('$', '').split(',').join('') - +b.phone.replace('$', '').split(',').join(''),
      render: (text) => (text ? text : '-'),
      sortOrder: sorting?.column === 'phone' ? sorting.order : null,
    },
    {
      title: 'Address',
      dataIndex: 'mailing_address',
      render: (text) => (text ? text : '-'),
      sortOrder: sorting?.column === 'mailing_address' ? sorting.order : null,
      sorter: (a, b) =>
        +a.mailing_address.replace('$', '').split(',').join('') -
        +b.mailing_address.replace('$', '').split(',').join(''),
    },
    {
      title: '',
      dataIndex: 'hasError',
      render: (text, record) => (
        <>
          {record?.hasError && record?.hasError !== 'false' && (
            <span className="error_message">
              {text}
              <Tooltip
                defaultOpen={true}
                title="Mandatory data is missing or invalid data is missing or can’t be accepted. Click on this entry (table row) to complete the details."
                placement="topRight"
              >
                <Image src={redExclamation} preview={false} width={11} />
              </Tooltip>
            </span>
          )}
          {record?.hasWarning && record?.hasWarning !== 'false' && (
            <span className="warning_message">
              {record?.hasWarning}{' '}
              <Tooltip
                title="There’s an identical contact in this list."
                placement="topRight"
                align={{
                  offsetX: 50,
                }}
              >
                <Image src={defaultExclamation} preview={false} width={11} />
              </Tooltip>
            </span>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    let str = record?.type;
    str = str.replace(/_/g, '-').toLowerCase();

    if (record?.type === 'assistant_treasurer' || record?.type === 'custodian' || record?.type === 'treasurer') {
      history.push({
        pathname: `/settings`,
        state: {
          settingTab: '3',
        },
      });
    } else {
      history.push({
        pathname: `/edit-import-contact/${str}`,
        state: {
          contactId: record?.id,
        },
      });
    }
  };

  const deleteObjectById = (array, idToDelete) => {
    setLoading(false);
    return array.filter((obj) => obj.id !== idToDelete);
  };

  const deleteRecord = (record) => {
    setLoading(true);
    setDeleteRecordID(record?.id);
    setReportVisible(false);
    setConfirmDelete(true);
    setLoading(false);
  };

  const submitFileExternal = (date) => {
    const value = getValues();
    value.effectiveDate = date;
    // onFinish(value);
  };

  const deleteMultiple = () => {
    setLoading(true);
    let contactsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      contactsToBeDeleted.push(contactListData.find((x) => x.key === keys).id);
    }
    deleteObject(DELETE_IMPORT_CONTACT, contactsToBeDeleted)
      .then(() => {
        message.success('Contacts deleted.');
        dispatch(getUnimportedContactsList());
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
          dispatch(setFiltersImport('showDublicative', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Contact"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => updateApi()}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const popNotDeleted = {
    closable: false,
    visibility: notDeleted,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Couldn't Delete the Contact"
        paragraph="This contact is used in one or more transactions. Please delete these transactions first in order to delete this contact."
        onClose={() => setNotDeleted(false)}
        PpopUpNotDeleted={true}
      />
    ),
    width: 450,
    onCancel: () => setNotDeleted(false),
  };

  const updateApi = (record) => {
    setLoading(true);
    deleteObject(DELETE_IMPORT_CONTACT, [record?.id])
      .then((response) => {
        if (response?.data?.notDeleted?.length > 0) {
          setConfirmDelete(false);
          setNotDeleted(true);
        } else {
          setConfirmDelete(false);
          setNotDeleted(false);
          setSelectedRowKeys([]);
          message.success('Contact deleted.');
          dispatch(getUnimportedContactsList());
        }

        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
          dispatch(setFiltersImport('showDublicative', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  // const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
  //   setLoading(true);
  //   fetch(``).then(({ results }) => {
  //     setLoading(false);
  //     setPagination({
  //       ...params.pagination,
  //     });
  //   });
  // };

  // useEffect(() => {
  //   fetchData({
  //     pagination,
  //   });
  // }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setSorting({
      column: sorter?.field,
      order: sorter?.order,
    });
    setPagination(pagination);
    storeStateInLocalStorage(sorter, pagination);
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        let str = record?.type;
        str = str.replace(/_/g, '-').toLowerCase();
        if (record?.type === 'assistant_treasurer' || record?.type === 'custodian' || record?.type === 'treasurer') {
          history.push({
            pathname: `/settings`,
            state: {
              settingTab: '3',
            },
          });
        } else {
          history.push({
            pathname: `/edit-import-contact/${str}`,
            state: {
              contactId: record?.id,
            },
          });
        }
      },
    };
  };

  useEffect(() => {
    setDataSource(contactListData);
  }, [contactListData]);

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Transactions for the selected period will be exported."
        csvEndPoint={DOWNLOAD_CONTACT_CSV}
        type="Contact"
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const exportContactList = {
    closable: false,
    visibility: contactExport,
    class: 'black-modal new_modal',
    content: (
      <ExportContacts
        title="Add Contacts"
        paragraph="You can add contacts manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setContactExport(false)}
      />
    ),
    width: 542,
    onCancel: () => setContactExport(false),
  };

  const contactMurge = () => {
    setLoading(true);
    addObject(ADD_IMPORTED_CONTACT)
      .then((response) => {
        dispatch(getUnimportedContactsList());
        setLoading(false);
        if (dataSource?.length === 1) {
          message.success(`${dataSource?.length} contact imported.`);
        } else {
          message.success(`${dataSource?.length} contacts imported.`);
        }
        history.push('/contacts');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const hasErrorInList = (array) => {
    if (array && array?.length > 0) {
      for (let obj of array) {
        if (obj.hasOwnProperty('hasError')) {
          if (obj['hasError'] !== 'false' && obj['hasError']) {
            return true; // Key has the specified value, return true
          }
        }
      }
      return false; // Key does not exist in any object
    }
  };
  const hasSelected = selectedRowKeys.length > 0;

  const countObjects = (key) => {
    let count = null;
    contactListData?.forEach((obj) => {
      if (obj[key] !== 'false') {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    if (contactListData) {
      let countError = countObjects('hasError');
      let countDublicates = countObjects('hasWarning');

      if (countError) {
        setCountError(countError);
      } else {
        setCountError(null);
        dispatch(setFiltersImport('showMandatory', undefined));
      }

      if (countDublicates) {
        setCountDublicate(countDublicates);
      } else {
        setCountDublicate(null);
        dispatch(setFiltersImport('showDublicative', undefined));
      }
    }
  }, [contactListData]);

  useEffect(() => {
    const contactData = [...contactListData];
    let filteredData = [];
    filteredData = contactData?.filter((entry) => {
      return (
        (selectedImportFilters?.showMandatory && entry?.hasError !== 'false') ||
        (selectedImportFilters?.showDublicative && entry?.hasWarning !== 'false')
      );
    });
    if (filteredData?.length > 0) {
      setDataSource(filteredData);
    } else {
      setDataSource(contactListData);
    }
  }, [selectedImportFilters, contactListData]);

  const discardButton = () => {
    setLoading(true);
    getObject('discardUnimportedContact')
      .then(() => {
        setLoading(false);
        dispatch(setFiltersImport('showMandatory', undefined));
        dispatch(setFiltersImport('showDublicative', undefined));
        setDiscardTransactions(false);
        history.push('/contacts');
      })
      .catch(() => {});
  };

  const discardImport = {
    closable: false,
    visibility: discardTransactions,
    class: 'black-modal',
    content: (
      <DiscardImport
        title="Discard Contacts Import"
        paragraph="Are you sure you want to discard import? Any changes you made here will be lost."
        onClose={() => setDiscardTransactions(false)}
        updateApi={discardButton}
      />
    ),
    width: 450,
    onCancel: () => setDiscardTransactions(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="Import Contacts">
        <Card className="white-card w-100 pcard-0">
          <div className="reports_head review_head">
            {hasSelected ? (
              <Row align="middle" justify="space-between" gutter={[10, 10]}>
                <Col>
                  <Button onClick={() => deleteMultiple()} type="text" htmlType="button" className="font-gilroy c-blue">
                    Delete Selected
                  </Button>
                </Col>
                <Col>
                  <Text className="font-14">{selectedRowKeys.length} Selected </Text>
                </Col>
              </Row>
            ) : (
              <div className="review_cantacts">
                <Image src={reviewIcon} preview={false} width={72} />
                <div className="review_text">
                  <h4>Review {contactListData?.length} Contacts You’re Importing</h4>
                  <p>The following contacts will be imported to your account.</p>
                </div>
              </div>
            )}
          </div>

          {(countDublicate || countError) && (
            <div className="show_warning_cards">
              {countError && (
                <div className="error_card">
                  <Image src={redExclamation} preview={false} width={20} />
                  {countError === 1 ? (
                    <>
                      <div className="e_card_text">
                        There is {countError} incomplete or not acceptable contact. Update the contact details or{' '}
                        <b>it will not be imported</b>. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showMandatory ? (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', undefined))}>
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="e_card_text">
                        There are {countError} incomplete or not acceptable contact. Update these contact details or{' '}
                        <b>they will not be imported</b>. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showMandatory ? (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', undefined))}>
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {countDublicate && (
                <div className="error_card warning_card">
                  <Image src={defaultExclamation} preview={false} width={20} />

                  {countDublicate === 1 ? (
                    <>
                      <div className="e_card_text">
                        There is {countDublicate} contact that require your attention. You can ignore it and still
                        import it. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showDublicative ? (
                            <Button
                              type="text"
                              onClick={() => dispatch(setFiltersImport('showDublicative', undefined))}
                            >
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showDublicative', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="e_card_text">
                        There are {countDublicate} contacts that require your attention. You can ignore this and still
                        import them all. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showDublicative ? (
                            <Button
                              type="text"
                              onClick={() => dispatch(setFiltersImport('showDublicative', undefined))}
                            >
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showDublicative', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: dataSource?.length > 10 ? true : false,
              ...pagination,
            }}
            onChange={handleTableChange}
            onRow={onClickRow}
            className="cursor_table"
          />
          <Col span={24}>
            <div className="new_table_btn">
              <div className="discard_btn">
                <Button
                  type="link"
                  htmlType="button"
                  className="font-gilroy"
                  size="large"
                  icon={<CloseIcon />}
                  onClick={() => setDiscardTransactions(true)}
                >
                  Discard & Close
                </Button>
              </div>
              <Row justify="center" gutter={[20, 20]}>
                <Col>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="font-gilroy white-btn"
                    size="large"
                    onClick={() => {
                      dispatch(setFiltersImport('showDublicative', undefined));
                      dispatch(setFiltersImport('showMandatory', undefined));
                      history.push('/contacts');
                    }}
                  >
                    Finish Later
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="secondary"
                    htmlType="button"
                    className="font-gilroy bg-red"
                    size="large"
                    onClick={contactMurge}
                    disabled={hasErrorInList(contactListData)}
                  >
                    Complete Import
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Card>
      </Dashboard>
      <Popup {...deletePopup} />
      <Popup {...popNotDeleted} />
      <Popup {...exportPeriod} />
      <Popup {...exportContactList} />
      <Popup {...discardImport} />
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
    </>
  );
};
