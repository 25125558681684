import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { SelectField, InputField, InputPassword, InputCheckbox } from '../../atoms/FormElement';
import { useDispatch } from 'react-redux';
import Login from '../../pages/Login';
import { signup } from '../../../services/services';
import { login } from '../../../features/userSlice';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const committeeType = [
    { label: 'Candidate Committee', value: 'candidate' },
    { label: 'Party Committee', value: 'party' },
    { label: 'Political Action Committee', value: 'political-action' },
    { label: 'Independent Expenditure Committee', value: 'independent-expenditure' },
  ];

  const onFinish = (values) => {
    const userEmail = values.email.toLowerCase();
    setLoading(true);
    signup(values.committeeType.value, userEmail, values.password)
      .then((response) => {
        let res = {
          access_token: response.data.token,
          //refresh_token: response.data.refresh_token,
        };
        if (res) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', JSON.stringify(res));
          localStorage.setItem('organisedReport', JSON.stringify(response.data.user.date_organized));
          dispatch(
            login({
              email: userEmail,
            }),
          );
          setTimeout(() => {
            history.push('/welcome');
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error?.message);
      });
  };
  return (
    <Login load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            Create Account
          </Title>
          <Paragraph className="text-center mt-1">Let's get your reports filed</Paragraph>
        </Col>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <SelectField
                  isRequired={true}
                  fieldname="committeeType"
                  label="Committee Type"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  rules={{ required: 'Committee Type is required.' }}
                  initValue=""
                  selectOption={committeeType}
                  validate={errors.committeeType && 'error'}
                  validMessage={errors.committeeType && errors.committeeType.message}
                />
              </Col>

              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="email"
                  label="Work Email"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Work Email is required.',
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                      message: 'Work Email not proper',
                    },
                  }}
                  validate={errors.email && 'error'}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>

              <Col span={24}>
                <InputPassword
                  isRequired={true}
                  fieldname="password"
                  label="Create Password"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Create Password is required.',
                  }}
                  validate={errors.password && 'error'}
                  validMessage={errors.password && errors.password.message}
                />
              </Col>

              <Col span={24}>
                <InputCheckbox
                  isRequired={true}
                  fieldname="agree"
                  label={
                    <>
                      I agree to the{' '}
                      <a
                        href="https://electafile.com/terms-conditions-of-service/"
                        className="c-doger-blue"
                        target="_blank"
                      >
                        Terms &amp; Conditions of Service
                      </a>{' '}
                    </>
                  }
                  class={`mb-0 one-checbox`}
                  control={control}
                  rules={{
                    required: 'This is required.',
                  }}
                  validate={errors.agree && 'error'}
                  validMessage={errors.agree && errors.agree.message}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" className="w-100" size="large">
                  Create Account
                </Button>
              </Col>

              <Col span={24} className="text-center">
                <Paragraph className="text-center mt-2 c-grey">Already have an account?</Paragraph>
                <Link to="/" className="text-uppercase c-grey">
                  Log in
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Login>
  );
};
