import React, { useState, useEffect } from 'react';
import { Card, Spin, Button, Row, Col, Typography, Image, message, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import reportStatuses from '../../../../../../../assets/svg/report-statuses.svg';
import infoIcon from '../../../../../../../assets/svg/info-icon.svg';
import { getObject } from '../../../../../../../services/crudApis';
import { GET_CHECKOUT_LINK, GET_REPORT_HTML_BY_ID, GET_REPORT_LISTING } from '../../../../../../../configs/endpoints';
import { Popup } from '../../../../../../atoms/Popup';
import ReportPopup from './ReportPopup';
import { editObject } from '../../../../../../../services/crudApis';
import { ADD_REPORT } from '../../../../../../../configs/endpoints';
import { useLocation } from 'react-router';

const antIcon = <LoadingOutlined spin />;
const { Title, Text, Paragraph } = Typography;

export default (props) => {
  const { reportId, setStatus, status, startDate, endDate, dueDate } = props;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState();
  const [visible, setVisible] = useState(false);
  const userEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const location = useLocation();
  const url = location.pathname.split('/')[2];
  let geturl = new URL(window.location.href);
  let searchParams = new URLSearchParams(geturl.search);
  const reportCase = searchParams.get('case');

  const redirectToCheckout = async () => {
    setLoading(true);
    if (reportId && reportId.length > 0) {
      setLoading(true);
      getObject(GET_CHECKOUT_LINK + '/' + reportId.split('?')[0])
        .then(async (response) => {
          if (response && response.data) {
            //console.log('STRIPE CHECKOUT LINK');
            //console.log(JSON.stringify(response.data));
            window.location.assign(response.data);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Unable to Find the Report. Please Refresh the page or try again.');
          //console.log(e);
        });
    }
  };

  const previewReport = async () => {
    setLoading(true);
    getObject(GET_REPORT_HTML_BY_ID + '/' + reportId.split('?')[0])
      .then((response) => {
        setReportData(response.data);
        // var myWindow = window.open('', 'response', 'resizable=yes');
        // myWindow.document.write(responseHtml);
        setVisible(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  const popup = {
    closable: false,
    visibility: visible,
    class: 'black-modal',
    content: <ReportPopup data={reportData} x onClose={() => setVisible(false)} onDelete={() => onDelete()} />,
    width: 850,
    onCancel: () => setVisible(false),
  };

  useEffect(() => {
    if (status && status !== 'ready') {
      let requestBody = {
        status: reportCase === '5' ? 'draft' : 'ready',
      };

      editObject(ADD_REPORT + '/' + reportId.split('?')[0], requestBody)
        .then((response) => {
          setStatus('ready');
        })
        .catch((e) => {
          message.error('Something went wrong');
          //console.log(e);
        });
    }
  }, [status]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="file_report">
        <Card className="grey-card">
          <Row justify="center">
            <Col flex="0 0 400px">
              <Row className="text-center" gutter={[24, 24]}>
                <Col span={24}>
                  <Image width={136} src={reportStatuses} preview={false} />
                </Col>
                <Col span={24}>
                  <Title className="c-doger-blue" level={4}>
                    Ready for Filing
                  </Title>
                </Col>
                <Col span={24}>
                  <Button
                    htmlType="submit"
                    className="w-100 font-gilroy white-btn mb-1"
                    size="large"
                    onClick={previewReport}
                  >
                    Preview report
                  </Button>
                  {reportCase !== '5' && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100 font-gilroy"
                      size="large"
                      onClick={redirectToCheckout}
                    >
                      Pay and file with board of elections
                    </Button>
                  )}
                </Col>
                {reportCase === '5' && (
                  <Col span={24}>
                    <Card className="info-card">
                      <Space size={20}>
                        <Image src={infoIcon} width={30} preview={false} />
                        <div className="info_text">
                          <Paragraph className="smallFont14 w-100 m-0">
                            This report will be available for filing after <b>{moment(endDate).format('MM-DD-YYYY')}</b>
                            {/* <b>
                              {moment(endDate).add(1, 'days').format('MM-DD-YYYY')}{' '}
                              {dueDate ? '-' + moment(dueDate).format('MM-DD-YYYY') : ''}
                            </b> */}
                          </Paragraph>
                        </div>
                      </Space>
                    </Card>
                  </Col>
                )}
                <Col span={24}>
                  <Text className="smallFont12">Submission Cost</Text>
                  <Title className="mt-0 mb-0 font-manrope f-bold" level={3}>
                    <span style={{ color: '#424C5F' }}> {url == '48-hour-notice' ? '$9.99' : '$59.99'}</span>
                  </Title>
                </Col>
                <Col span={24}>
                  <Text className="smallFont12">
                    We charge a flat fee for each report or amendment submission. <br />
                    <a href="https://electafile.com/pricing" className="c-doger-blue" target="_blank">
                      Learn more about Pricing
                    </a>
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
      <Popup {...popup} />
    </Spin>
  );
};
