import React, { useState, useEffect } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Space, Card, Table, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from '../../atoms/Popup';
import ReportConfirmationPopup from './PopUp';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { reportVisible, setReportVisible, submitFileExternal, dateEffective, receiptId, type } = props;

  const confirmReportPopup = {
    closable: false,
    visibility: reportVisible,
    class: 'black-modal',
    content: (
      <ReportConfirmationPopup
        title="Confirm Report Amendments"
        onClose={() => setReportVisible(false)}
        submitFileExternal={submitFileExternal}
        onDelete={() => onDelete()}
        dateEffective={dateEffective}
        receiptId={!dateEffective ? receiptId : null}
        type={type}
      />
    ),
    width: 542,
    onCancel: () => setReportVisible(false),
  };

  return <Popup {...confirmReportPopup} />;
};
