import axios from '../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../configs/constants';

export const getDashboardData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getDashboardData`);
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && data && data && data.isSetupComplete) {
      user.is_setup_complete = data.isSetupComplete;
    } else if (data && data && data.isSetupComplete) {
      user = { is_setup_complete: data.isSetupComplete };
    }
    if (data && data.committee_type) {
      user.committee_type = data.committee_type;
    }
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({
      type: action_types.DASHBOARD_DATA,
      data: data,
    });
  };
};


export const getAvailableReportsData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getAvailableReports`);
    dispatch({
      type: action_types.AVAILABLE_REPORTS,
      data: data,
    });
  };
};

export const getCurrentElectionData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getCurrentCycle`);
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && data && data && data.is_setup_complete) {
      user.is_setup_complete = data.is_setup_complete;
    } else if (data && data && data.is_setup_complete) {
      user = { is_setup_complete: data.is_setup_complete };
    }
    if (data && data.committee_type) {
      user.committee_type = data.committee_type;
    }
    localStorage.setItem('user', JSON.stringify(user));

    dispatch({
      type: action_types.CURRENT_CYCLE,
      data: data,
    });
  };
};