import React, { useState } from 'react';
import { Typography, Row, Col, Image, Descriptions, Button } from 'antd';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import AddCommittee from '../../../templates/AddCommittee';
import completed from '../../../../assets/svg/completed.svg';
import rejected from '../../../../assets/svg/rejected.svg';

const { Title, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname.split('/')[2];

  return (
    <AddCommittee
      load={loading}
      heading={url == 'success' ? 'You Are All Set!' : 'Import Was Not Complete'}
      className={url == 'rejected' && 'c-red'}
    >
      {url == 'success' && (
        <Row gutter={[35, 35]} className="text-center">
          <Col span={24}>
            <Image src={completed} preview={false} width={242} className="mb-1" />
            <Paragraph className="smallFont12">
              Your committee data was imported successfully. From now on, you
              <br /> can continue recording transactions and file reports on ElectaFile&reg;.
            </Paragraph>
          </Col>
          <Col span={24}>
            <Title level={4} className="text-center mb-1">
              Committee Data Imported
            </Title>
            <Descriptions column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} className="success-description">
              <Descriptions.Item label="Committee Name">Superman Committee</Descriptions.Item>
              <Descriptions.Item label="Board of Elections ID">STA-123456-C-001</Descriptions.Item>
              <Descriptions.Item label="Bank Accounts">2</Descriptions.Item>
              <Descriptions.Item label="Officers">1</Descriptions.Item>
              <Descriptions.Item label="Contacts">248</Descriptions.Item>
              <Descriptions.Item label="Receipts">108</Descriptions.Item>
              <Descriptions.Item label="Expenditures">23</Descriptions.Item>
              {/* <Descriptions.Item label="Current Election Cycle">
                Statewide General Elections, 2021-2022
              </Descriptions.Item> */}
              <Descriptions.Item label="Cash on Hand at Start">$0.00</Descriptions.Item>
              <Descriptions.Item label="Reports Filed">Organizational, First Quarter Plus</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              className="font-gilroy mt-1"
              size="large"
              onClick={() => history.push('/dashboard')}
            >
              Get Started
            </Button>
          </Col>
        </Row>
      )}
      {url == 'rejected' && (
        <Row gutter={[35, 35]} className="text-center">
          <Col span={24}>
            <Image src={rejected} preview={false} width={136} className="mb-1" />
            <Paragraph className="smallFont12">
              The importing process was interrupted.
              <br /> Please try again.
            </Paragraph>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              className="font-gilroy"
              size="large"
              onClick={() => history.push('/import-data')}
            >
              Try again
            </Button>
          </Col>
        </Row>
      )}
    </AddCommittee>
  );
};
