import React, { useState, useEffect } from 'react';
import Dashboard from '../../../pages/Dashboard';
import {
  Card,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Input,
  Image,
  message,
  Tooltip,
  Slider,
  InputNumber,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../assets/svg/plus-icon-white.svg';
import exportCSV from '../../../../assets/svg/export-csv.svg';
import arrowIcon from '../../../../assets/svg/select-icon.svg';
import clearIcon from '../../../../assets/svg/clear-filters.svg';
import infoBlue from '../../../../assets/svg/info-blue.svg';
import dashboardArrow from '../../../../assets/svg/dashboard-Arrow.svg';
import {
  clearContactFilters,
  getContactListingData,
  setFiltersOptionsContact,
  checkUnimportedContact,
} from './ducks/actions';
import { deleteObject, downloadFile, saveFile } from '../../../../services/crudApis';
import { DELETE_CONTACT, DOWNLOAD_CONTACT_CSV } from '../../../../configs/endpoints';
import ConfirmDelete from '../../../molecules/ConfirmDelete';
import { Popup } from '../../../atoms/Popup';
import ReportAmendment from '../../../molecules/ReportAmendment';
import { baseUrl } from '../../../../configs/constants';
import ExportPeriod from '../../../molecules/ExportPeriod';
import ImportList from '../../../molecules/ExportContacts';
import AmountDropdown from '../../../molecules/AmountDropdown';

const { Text, Title, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableRecord, setTableRecord] = useState();
  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [receiptReportID, setReceiptReportID] = useState('');
  const [notDeleted, setNotDeleted] = useState(false);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [importCSV, setImportCSV] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [contributedValue, setContributedValue] = useState([]);
  const [paidSum, setpaidSum] = useState([]);
  const [highestAmount, setHighestAmount] = useState(0);
  const [rangeBox, setRangeBox] = useState(false);
  const [inputValue2, setInputValue2] = useState([]);
  const [highestAmount2, setHighestAmount2] = useState(0);
  const [rangeBox2, setRangeBox2] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const selectedFilters = useSelector((state) => state.contact.filter);

  const contactListData = useSelector((state) => state.contact.contactListData);
  const checkUnimportedData = useSelector((state) => state.contact.checkUnimportedContact);

  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const storeStateInLocalStorage = (sort, newPagination) => {
    localStorage.setItem('antdTableState', JSON.stringify({ sorting: sort, pagination: newPagination }));
  };

  useEffect(() => {
    const storedState = localStorage.getItem('antdTableState');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      setSorting({
        column: parsedState.sorting?.field,
        order: parsedState.sorting?.order,
      });
      setPagination({
        current: parsedState.pagination?.current,
        pageSize: parsedState.pagination?.pageSize,
      });
    }
  }, []);

  useEffect(() => {
    const contactData = [...contactListData];
    const currValue = selectedFilters?.searchContact?.toLowerCase();
    let filteredData = [];
    setValue(currValue);
    filteredData = contactData.filter((entry) => {
      const paidAmount = +entry?.total_received.replace('$', '').split(',').join('');
      const contributedAmount = +entry?.total_contributed.replace('$', '').split(',').join('');
      const typedData = currValue
        ? entry?.name.toLowerCase().includes(currValue) ||
          entry?.type.toLowerCase().includes(currValue) ||
          entry?.total_contributed.includes(currValue) ||
          entry?.total_received.toLowerCase().includes(currValue)
        : true;

      const paidData = paidAmount >= inputValue2[0] && paidAmount <= inputValue2[1];
      const contributedData = contributedAmount >= inputValue[0] && contributedAmount <= inputValue[1];

      return typedData && paidData && contributedData;
    });
    setDataSource(filteredData);
  }, [selectedFilters]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    dispatch(clearContactFilters());
    dispatch(getContactListingData());
    dispatch(checkUnimportedContact());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const listColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sorting?.column === 'name' ? sorting.order : null,
    },
    {
      title: 'Contact Type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sortOrder: sorting?.column === 'type' ? sorting.order : null,
    },
    {
      title: 'Contributed, Total',
      dataIndex: 'total_contributed',
      sorter: (a, b) =>
        +a.total_contributed.replace('$', '').split(',').join('') -
        +b.total_contributed.replace('$', '').split(',').join(''),
      sortOrder: sorting?.column === 'total_contributed' ? sorting.order : null,
    },
    {
      title: 'Got Paid, Total',
      dataIndex: 'total_received',
      sorter: (a, b) =>
        +a.total_received.replace('$', '').split(',').join('') - +b.total_received.replace('$', '').split(',').join(''),
      sortOrder: sorting?.column === 'total_received' ? sorting.order : null,
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    let str = record?.type;
    str = str.replace(/_/g, '-').toLowerCase();

    if (record?.type === 'assistant_treasurer' || record?.type === 'custodian' || record?.type === 'treasurer') {
      history.push({
        pathname: `/settings`,
        state: {
          settingTab: '3',
        },
      });
    } else {
      history.push({
        pathname: `/edit-contact/${str}`,
        state: {
          contactId: record?.id,
        },
      });
    }
  };

  const deleteRecord = (record) => {
    setLoading(true);
    setDeleteRecordID(record?.id);
    // if (record?.report_ids && record?.report_ids?.length > 0) {
    //   setReportVisible(true);
    //   setConfirmDelete(false);
    // } else {
    //   setReportVisible(false);
    //   setConfirmDelete(true);
    // }
    setReportVisible(false);
    setConfirmDelete(true);
    setLoading(false);
  };

  const submitFileExternal = (date) => {
    const value = getValues();
    value.effectiveDate = date;
    // onFinish(value);
  };

  const deleteMultiple = () => {
    setLoading(true);
    let contactsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      contactsToBeDeleted.push(contactListData.find((x) => x.key === keys).id);
    }
    deleteObject(DELETE_CONTACT, contactsToBeDeleted)
      .then((response) => {
        if (response?.data?.notDeleted?.length > 0) {
          setConfirmDelete(false);
          setNotDeleted(true);
          setLoading(false);
          dispatch(getContactListingData());
        } else {
          message.success('Contacts deleted.');
          dispatch(getContactListingData());
          setSelectedRowKeys([]);
          setTimeout(() => {
            setLoading(false);
          }, 800);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Contact"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => updateApi()}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const popNotDeleted = {
    closable: false,
    visibility: notDeleted,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Couldn't Delete the Contact"
        paragraph="This contact is used in one or more transactions. Please delete these transactions first in order to delete this contact."
        onClose={() => setNotDeleted(false)}
        PpopUpNotDeleted={true}
      />
    ),
    width: 450,
    onCancel: () => setNotDeleted(false),
  };

  const updateApi = () => {
    setLoading(true);
    deleteObject(DELETE_CONTACT, [deleteRecordID])
      .then((response) => {
        if (response?.data?.notDeleted?.length > 0) {
          setConfirmDelete(false);
          setNotDeleted(true);
        } else {
          setConfirmDelete(false);
          setNotDeleted(false);
          setSelectedRowKeys([]);
          message.success('Contact deleted.');
          dispatch(getContactListingData());
        }

        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  // const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
  //   setLoading(true);
  //   fetch(``).then(({ results }) => {
  //     setLoading(false);
  //     setPagination({
  //       ...params.pagination,
  //     });
  //   });
  // };

  // useEffect(() => {
  //   fetchData({
  //     pagination,
  //   });
  // }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setSorting({
      column: sorter?.field,
      order: sorter?.order,
    });
    setPagination(pagination);
    storeStateInLocalStorage(sorter, pagination);
    // fetchData({
    //   pagination: newPagination,
    //   ...filters,
    // });
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        let str = record?.type;
        str = str.replace(/_/g, '-').toLowerCase();
        if (record?.type === 'assistant_treasurer' || record?.type === 'custodian' || record?.type === 'treasurer') {
          history.push({
            pathname: `/settings`,
            state: {
              settingTab: '3',
            },
          });
        } else {
          history.push({
            pathname: `/edit-contact/${str}`,
            state: {
              contactId: record?.id,
            },
          });
        }
      },
    };
  };

  const handleCSVDownloadButtonClick = () => {
    try {
      setLoading(true);
      downloadFile(`${baseUrl}/${DOWNLOAD_CONTACT_CSV}`)
        .then((response) => {
          saveFile(response, `Electafile - Contact - ${new Date().toISOString()}.csv`);
          setLoading(false);
        })
        .catch((error) => {
          message.error('Something went wrong');
          setLoading(false);
        });
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  useEffect(() => {
    setDataSource(contactListData);

    if (contactListData && contactListData?.length > 0) {
      const numericalValues = contactListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.total_contributed.replace('$', '').split(',').join(''));
      });
      const numericalValues2 = contactListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.total_received.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      const highestValue2 = Math.max(...numericalValues2);
      setInputValue([0, highestValue]);
      setInputValue2([0, highestValue2]);
      setHighestAmount(highestValue);
      setHighestAmount2(highestValue2);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
  }, [contactListData]);

  const applyRange = () => {
    dispatch(setFiltersOptionsContact('contributedAmount', inputValue2));
    setRangeBox(false);
  };

  const applyRange2 = () => {
    dispatch(setFiltersOptionsContact('paidAmount', inputValue2));
    setRangeBox2(false);
  };

  const clearFilters = () => {
    if (contactListData && contactListData?.length > 0) {
      const numericalValues = contactListData.map((res) => {
        return parseInt(res?.total_contributed.replace('$', '').split(',').join(''));
      });
      const numericalValues2 = contactListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.total_received.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      const highestValue2 = Math.max(...numericalValues2);
      setInputValue([0, highestValue]);
      setInputValue2([0, highestValue2]);
      setHighestAmount(highestValue);
      setHighestAmount2(highestValue2);
      setValue('');
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }

    setContributedValue([]);
    setpaidSum([]);
    setDataSource(contactListData);
  };

  const rangeValue = (value) => {
    setInputValue(value);
    setContributedValue(value);
  };

  const rangeValue2 = (value) => {
    setInputValue2(value);
    setpaidSum(value);
  };

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Transactions for the selected period will be exported."
        csvEndPoint={DOWNLOAD_CONTACT_CSV}
        type="Contact"
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const importCSVList = {
    closable: false,
    visibility: importCSV,
    class: 'black-modal new_modal',
    content: (
      <ImportList
        title="Add Contacts"
        paragraph="You can add contacts manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setImportCSV(false)}
        manualLink="/new-contact"
        afterUploadLink="/import-contacts"
      />
    ),
    width: 542,
    onCancel: () => setImportCSV(false),
  };

  const contributedStart = (value) => {
    setInputValue([value, inputValue[1]]);
    setContributedValue([value, inputValue[1]]);
  };

  const contributedEnd = (value) => {
    setInputValue([inputValue[0], value]);
    setContributedValue([inputValue[0], value]);
  };

  const paidStart = (value) => {
    setInputValue2([value, inputValue2[1]]);
    setpaidSum([value, inputValue2[1]]);
  };

  const paidEnd = (value) => {
    setInputValue2([inputValue2[0], value]);
    setpaidSum([inputValue2[0], value]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const clearAmount = () => {
    if (contactListData && contactListData?.length > 0) {
      const numericalValues = contactListData.map((res) => {
        return parseInt(res?.total_contributed.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);

      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
      setContributedValue([]);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
      setContributedValue([]);
    }
  };

  const clearAmount2 = () => {
    if (contactListData && contactListData?.length > 0) {
      const numericalValues2 = contactListData.map((res) => {
        return parseInt(res?.total_received.replace('$', '').split(',').join(''));
      });
      const highestValue2 = Math.max(...numericalValues2);
      setInputValue2([0, highestValue2]);
      setHighestAmount2(highestValue2);
      setpaidSum([]);
    } else {
      setHighestAmount2(0);
      setpaidSum([]);
      setInputValue2([0, 0]);
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    setRangeBox((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setRangeBox(false);
  };

  const handleToggle2 = (e) => {
    e.stopPropagation();
    setRangeBox2((prevState) => !prevState);
  };

  const closeDropdown2 = () => {
    setRangeBox2(false);
  };
  return (
    <>
      <Dashboard load={loading} heading="Contact List">
        {checkUnimportedData && (
          <Col span={24} style={{ marginBottom: 24 }}>
            <Card className="finish_import" onClick={() => history.push('/import-contacts')}>
              <Row gutter={0} align="top">
                <Col flex="0 0 30px" style={{ display: 'flex' }}>
                  <Image src={infoBlue} preview={false} width={30} />
                </Col>
                <Col>
                  <Title level={4} style={{ marginBottom: '8px' }}>
                    Finish Contacts Import
                  </Title>
                  <Paragraph className="mb-0">
                    There's unfinished contact import process. Click on this card to continue.
                  </Paragraph>
                </Col>
                <Col className="btn_import_dashboard">
                  <Button
                    htmlType="button"
                    type="link"
                    style={{ padding: 14 }}
                    onClick={() => history.push('/import-contacts')}
                  >
                    <Image src={dashboardArrow} preview={false} width={24} />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        <Card className="white-card w-100 pcard-0">
          <Row justify="space-between" align="middle" className="reports_head" style={{ height: '50px' }}>
            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  {hasSelected ? (
                    <Button
                      onClick={() => deleteMultiple()}
                      type="text"
                      htmlType="button"
                      className="font-gilroy c-blue"
                    >
                      Delete Selected
                    </Button>
                  ) : (
                    <Button
                      // onClick={() => history.push('/new-contact')}
                      onClick={() => setImportCSV(true)}
                      type="primary"
                      htmlType="submit"
                      className="w-100 font-gilroy"
                      size="large"
                    >
                      <Image src={plusIcon} preview={false} width={22} />
                      Add Contacts
                    </Button>
                  )}
                </Col>
                <Col>
                  {hasSelected ? (
                    <Col>
                      <Text className="font-14">{selectedRowKeys.length} Selected </Text>
                    </Col>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Col className="search_box">
                        <Input
                          placeholder="Search..."
                          value={value}
                          onChange={(e) => dispatch(setFiltersOptionsContact('searchContact', e.target.value))}
                        />
                      </Col>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>

            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  <Button className={rangeBox ? 'sum_select box_open' : 'sum_select'} onClick={handleToggle}>
                    {contributedValue && contributedValue?.length > 0 ? (
                      <Tooltip title="Filter: Contributed, Total">
                        <div className="r_heading_amount">{`$ ${Number(
                          contributedValue[0],
                        ).toLocaleString()} - ${Number(contributedValue[1]).toLocaleString()}`}</div>
                      </Tooltip>
                    ) : (
                      <div className="sum_heading">Contributed, Total</div>
                    )}

                    <div className="sum_icon">
                      <img src={arrowIcon} alt="arrowIcon" />
                    </div>
                  </Button>
                  {rangeBox && (
                    <AmountDropdown
                      clearAmount={clearAmount}
                      rangeValue={rangeValue}
                      amountStart={contributedStart}
                      amountEnd={contributedEnd}
                      setRangeBox={setRangeBox}
                      setAmountRange={setContributedValue}
                      setInputValue={setInputValue}
                      applyRange={applyRange}
                      inputValue={inputValue}
                      closeDropdown={() => closeDropdown()}
                      highestAmount={highestAmount}
                    />
                  )}
                </Col>
                <Col>
                  <Button className={rangeBox2 ? 'sum_select box_open' : 'sum_select'} onClick={handleToggle2}>
                    {paidSum && paidSum?.length > 0 ? (
                      <Tooltip title="Filter: Got Paid, Total">
                        <div className="r_heading_amount">{`$ ${Number(paidSum[0]).toLocaleString()} - ${Number(
                          paidSum[1],
                        ).toLocaleString()}`}</div>
                      </Tooltip>
                    ) : (
                      <div className="sum_heading">Got Paid, Total</div>
                    )}
                    <div className="sum_icon">
                      <img src={arrowIcon} alt="arrowIcon" />
                    </div>
                  </Button>

                  {rangeBox2 && (
                    <AmountDropdown
                      clearAmount={clearAmount2}
                      rangeValue={rangeValue2}
                      amountStart={paidStart}
                      amountEnd={paidEnd}
                      setRangeBox={setRangeBox2}
                      setAmountRange={setpaidSum}
                      setInputValue={setInputValue2}
                      applyRange={applyRange2}
                      inputValue={inputValue2}
                      closeDropdown={() => closeDropdown2()}
                      highestAmount={highestAmount2}
                    />
                  )}
                </Col>
                <Col>
                  <Button type="ghost" htmlType="button" className="p-0" onClick={clearFilters}>
                    <img src={clearIcon} alt="clearIcon" />
                  </Button>
                </Col>
                <Col>
                  <Tooltip placement="topLeft" title="Download data in a CSV file.">
                    <Button
                      type="ghost"
                      htmlType="button"
                      className="p-0"
                      style={{ marginLeft: 16 }}
                      onClick={handleCSVDownloadButtonClick}
                    >
                      <img src={exportCSV} alt="export CSV" />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: dataSource?.length > 10 ? true : false,
              ...pagination,
            }}
            onChange={handleTableChange}
            onRow={onClickRow}
            className="cursor_table"
          />
        </Card>
      </Dashboard>
      <Popup {...deletePopup} />
      <Popup {...popNotDeleted} />
      <Popup {...exportPeriod} />
      <Popup {...importCSVList} />
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
    </>
  );
};
