import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Image, Button } from 'antd';
import Welcome from '../../pages/Welcome';
import welcomeImage from '../../../assets/svg/welcome.svg';
import { CircleArrowRed } from '../../atoms/CustomIcons';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  return (
    <Welcome load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24} className="text-center">
          <Image width={320} src={welcomeImage} preview={false} />
        </Col>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            Welcome!
          </Title>
          <Paragraph className="text-center mt-1">
            Take a look at our straightforward process of <br />
            filing campaign finance reports
          </Paragraph>
        </Col>
        <Col span={24} className="circle-icon">
          <Button type="link" onClick={() => history.push('/on-board')}>
            <CircleArrowRed className="redIcon" />
          </Button>
        </Col>

        <Col span={24} className="text-center mb-0">
          <Link to="/import-data" className="text-uppercase">
            Skip and get started
          </Link>
        </Col>
      </Row>
    </Welcome>
  );
};
